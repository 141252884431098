import React from "react";
import DashboardCard from "@components/layout/admin-layout/components/dashboardCard/DashboardCard";
import styles from "./dashboard.module.scss";
import { useInviteModal } from "@components/ModalContext/InviteModalContext";
import InviteModal from "@components/layout/admin-layout/components/inviteModal/InviteModal";

const Dashboard = () => {
  const { isModalOpen } = useInviteModal();

  return (
    <div className={styles.dashboard}>
      {isModalOpen && <InviteModal />}
      <div className={styles.dashboardCards}>
        <DashboardCard type="All users" count={2} lastUpdated={30} />
        <DashboardCard type="Concept notes" count={1} lastUpdated={10} />
        <DashboardCard type="Proposals" count={2} lastUpdated={30} isBlur />
      </div>
    </div>
  );
};

export default Dashboard;
