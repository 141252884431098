import { useInviteModal } from "@components/ModalContext/InviteModalContext";
import style from "@components/layout/main-layout/private-layout/header/style.module.scss";
import { useStore } from "@stores/root-store";
import { FC, useState } from "react";
import { AddTokensModal } from "../../addTokensModal/AddTokensModal";
import styles from "./user-details.module.scss";

type Props = {
  userDetails: any;
  updateUserDetails: any;
};

const UserDetails: FC<Props> = ({ userDetails, updateUserDetails }) => {
  const { openModal } = useInviteModal();
  const email = userDetails?.email;
  const [addTokensModal, setAddTokensModal] = useState(false);
  const [isOpenOrderModal, setOpenOrderModal] = useState(false);
  const tokensUsed = (
    Number(userDetails.tokens_used.slice(1)) * 10_000
  ).toLocaleString();
  const tokensPurchased = (
    Number(userDetails.tokens_purchased.slice(1)) * 10_000
  ).toLocaleString();

  const {
    user: {
      getAllUsers: allUsers,
      onUpdateUserPlan,
      setAllUsers,
      onDowngradeUserPlan,
      addPurchasedTokens,
    },
  } = useStore(null);

  const handleChangeModalVisible = async () => {
    if (isOpenOrderModal) {
      setOpenOrderModal(false);
      const updatedUsers = allUsers["account holders"].map(el => {
        if (el.user_email === email) {
          return { ...el, plan: "Premium" };
        } else {
          return el;
        }
      });

      await onUpdateUserPlan({ email, new_plan: "Premium" })
        .then(() => {
          updateUserDetails({ ...userDetails, plan: "Premium" });
        })
        .then(() => {
          setAllUsers({ ...allUsers, ["account holders"]: updatedUsers });
        });
    }
    setAddTokensModal(prev => (isOpenOrderModal ? false : !prev));
  };

  return (
    <div className={styles.userDetails}>
      <div className={styles.userDetailsContent}>
        <div className={styles.userDetailsCard}>
          <h2 className={styles.userDetailsCardTitle}>Profile details</h2>
          <div className={styles.userDetailsCardItem}>
            <div>Full name:</div>
            <div>{userDetails?.name}</div>
          </div>
          <div className={styles.userDetailsCardItem}>
            <div>Email Address:</div>
            <div>{userDetails?.email}</div>
          </div>
          <div className={styles.userDetailsCardItem}>
            <div>Country:</div>
            <div>{userDetails?.country}</div>
          </div>
          <div className={styles.userDetailsCardItem}>
            <div>Entity Type:</div>
            <div>{userDetails?.["entity type"]}</div>
          </div>
        </div>
        <div className={styles.userDetailsCard}>
          <h2 className={styles.userDetailsCardTitle}>Plan details</h2>
          <p className={styles.userDetailsCardSubtitle}>
            “{userDetails?.plan}” workspace
          </p>
          <p className={styles.userDetailsCardText}>
            Plan renews on {userDetails?.renew_date}
          </p>
          <div className={styles.userDetailsCardBorder} />
        </div>
        {userDetails.plan === "Enterprise" && (
          <div className={styles.userDetailsCard}>
            <h2 className={styles.userDetailsCardTitle}>Users (seats)</h2>
            <p className={styles.userDetailsCardSubtitle}>
              {userDetails?.remaining_subaccounts} users remaining
            </p>
            <p className={styles.userDetailsCardText}>
              {userDetails?.subaccounts || 0} users in your plan
            </p>
            <div className={styles.userDetailsCardBorder} />
            <p
              className={styles.userDetailsCardButton}
              onClick={() => openModal()}
            >
              Add users
            </p>
          </div>
        )}
        <div className={styles.userDetailsCard}>
          <h2 className={styles.userDetailsCardTitle}>Tokens</h2>
          <div className={styles.userDetailsCardRow}>
            <p className={styles.userDetailsCardSubtitle}>
              Tokens used: {userDetails.tokens_used}
            </p>
          </div>

          <div className={styles.userDetailsCardRow}>
            <p className={styles.userDetailsCardSubtitle}>
              Tokens purchased: {userDetails.tokens_purchased}
            </p>
          </div>
          <div className={styles.userDetailsCardBorder} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "14px 0 -15px 0",
              gap: "12px",
            }}
          >
            <p
              className={styles.userDetailsCardButton}
              onClick={() => {
                if (userDetails?.plan !== "Basic") {
                  handleChangeModalVisible();
                }
              }}
              style={{
                margin: "0",
                color: userDetails?.plan === "Basic" ? "#80db9f" : "",
              }}
            >
              Increase Limit
            </p>
            {userDetails?.plan === "Basic" && (
              <div
                className={style.upgradeButton}
                onClick={() => setOpenOrderModal(true)}
                style={{
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0 16px",
                }}
              >
                Upgrade
              </div>
            )}
          </div>
        </div>
      </div>
      <AddTokensModal
        isOpen={addTokensModal || isOpenOrderModal}
        closeModal={handleChangeModalVisible}
        user={userDetails}
        upStep={isOpenOrderModal ? 2 : null}
      />
    </div>
  );
};

export default UserDetails;
