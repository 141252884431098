import React from "react";

const StopGenerationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
    >
      <circle
        cx="17.5"
        cy="17.5"
        r="16"
        fill="white"
        stroke="#00B840"
        strokeWidth="3"
      />
      <rect x="10" y="11" width="14" height="14" fill="#00B840" />
    </svg>
  );
};

export default StopGenerationIcon;
