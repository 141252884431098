import React, { useState } from "react";
import styles from "./invite-modal.module.scss";
import { useInviteModal } from "@components/ModalContext/InviteModalContext";
import TextArea from "antd/es/input/TextArea";
import { useStore } from "@stores/root-store";
import { useLocation } from "react-router-dom";

const InviteModal = () => {
  const [emails, setEmails] = useState("");
  const [sendStatus, setSendStatus] = useState(false);

  const { closeModal } = useInviteModal();
  const {
    user: { addSubAccounts },
  } = useStore(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const emailCurrentUser = searchParams.get("email");

  const onInviteSubAccounts = async () => {
    const email = emailCurrentUser;
    await addSubAccounts({ email, subaccount_email: emails }).then(() => {
      setSendStatus(true);
      setTimeout(() => {
        closeModal();
        setSendStatus(false);
      }, 2000);
    });
  };

  return (
    <div className={styles.modalBg}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <p>Invite people to the team</p>
          <div className={styles.modalCloseIcon} onClick={() => closeModal()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.4546 0.252083C10.1405 -0.0729166 9.63307 -0.0729166 9.31894 0.252083L5.38027 4.31875L1.4416 0.24375C1.12747 -0.08125 0.620035 -0.08125 0.305908 0.24375C-0.00821932 0.56875 -0.00821932 1.09375 0.305908 1.41875L4.24458 5.49375L0.305908 9.56875C-0.00821932 9.89375 -0.00821932 10.4187 0.305908 10.7437C0.620035 11.0687 1.12747 11.0687 1.4416 10.7437L5.38027 6.66875L9.31894 10.7437C9.63307 11.0687 10.1405 11.0687 10.4546 10.7437C10.7688 10.4187 10.7688 9.89375 10.4546 9.56875L6.51596 5.49375L10.4546 1.41875C10.7607 1.10208 10.7607 0.56875 10.4546 0.252083Z"
                fill="#2E2E2E"
              />
            </svg>
          </div>
        </div>
        <div className={styles.modalContent}>
          <p className={styles.modalInputLabel}>Email Addresses</p>
          <TextArea
            className={styles.modalTextarea}
            onChange={(e) => setEmails(e.target.value)}
            style={{
              width: "100%",
              height: "186px",
              resize: "none",
            }}
          />
          <p className={styles.modalDesc}>
            Your colleagues will get an email that gives them access to your
            team.
          </p>
          <div
            className={styles.modalButton}
            onClick={() => onInviteSubAccounts()}
          >
            Send
          </div>
          {sendStatus && (
            <p className={styles.modalSuccesText}>Invitations sent!</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default InviteModal;
