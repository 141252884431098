import React, { FC } from "react";

type Props = {
  color?: string;
};

const UserAdminIcon: FC<Props> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <g clipPath="url(#clip0_3931_4443)">
        <path
          d="M17.9736 17.5935C17.61 16.8812 17.1302 16.2129 16.5341 15.6169C13.4777 12.5604 8.52214 12.5604 5.46567 15.6169C4.8696 16.2129 4.38978 16.8812 4.0262 17.5935M0.916504 10.9993C0.916504 5.43048 5.43097 0.916015 10.9998 0.916016C16.5687 0.916016 21.0832 5.43048 21.0832 10.9993C21.0832 16.5682 16.5687 21.0827 10.9998 21.0827C5.43097 21.0827 0.916503 16.5682 0.916504 10.9993ZM14.6665 9.16601C14.6665 11.1911 13.0249 12.8327 10.9998 12.8327C8.97479 12.8327 7.33317 11.1911 7.33317 9.16601C7.33317 7.14097 8.97479 5.49935 10.9998 5.49935C13.0249 5.49935 14.6665 7.14097 14.6665 9.16601Z"
          stroke={color || "#000"}
          strokeWidth="1.67"
        />
      </g>
      <defs>
        <clipPath id="clip0_3931_4443">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserAdminIcon;
