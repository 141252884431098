import React from "react";

const DropdownIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 8.33203L10.5893 12.7428C10.2638 13.0682 9.73618 13.0682 9.41074 12.7428L5 8.33203"
        stroke="#2E2E2E"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default DropdownIcon;
