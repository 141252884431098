import { constRoute } from "@utils/route";
import { memo } from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "@components/layout/admin-layout/pages/dashboard/Dashboard";
import Users from "@components/layout/admin-layout/pages/users/Users";
import Billing from "@components/layout/admin-layout/pages/billing/Billing";

const AdminRouting = () => {
  return (
    <>
      <Routes>
        <Route path={constRoute?.adminDashboard} element={<Dashboard />} />
        <Route path={constRoute?.adminUsers} element={<Users />} />
        <Route path={constRoute?.adminBilling} element={<Billing />} />
      </Routes>
    </>
  );
};
export default memo(AdminRouting);
