import down from "@assets/images/down.svg";
import { CommonInput } from "@components/common-components/input";
import QusestionSubmitionModal from "@components/pages/project/common-components/qusestion-submition-modal";
import { wordLimits } from "@components/pages/project/select-function/which-to-do/select-one/project-name/important-project-info/utils/wordLimits";
import { validateMessages } from "@utils/json-data";
import { Form, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { FC, useEffect, useState } from "react";
import style from "./style.module.scss";

interface Props {
  setFirstStepAnswers?: (data: any) => void;
  isLoading: boolean;
  initialAnswers: {
    q1a: string | null;
    q1b: string | null;
    q1c: string | null;
    input: string | null;
  } | null;
}

export const DraftFirstStep: FC<Props> = ({
  isLoading,
  setFirstStepAnswers,
  initialAnswers
}) => {
  const [form] = useForm();
  const [submitModalShow, setSubmitModalShow] = useState(false);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (initialAnswers && Object.keys(initialAnswers).length) {
      setFirstStepAnswers({
        q1a: initialAnswers?.q1a || "",
        q1b: initialAnswers?.q1b || "",
        q1c: initialAnswers?.q1c || "",
        input: initialAnswers?.input || initialAnswers?.["B_1_input"] || ""
      });
    }
  }, [initialAnswers]);

  return (
    <div className={style.mainContainer}>
      <div className={style.barContentContainer}>
        <div
          className={style.contentContainer}
          style={{ cursor: isLoading ? "wait" : "" }}
        >
          <div className={style.innerContentContainer}>
            <div>
              <Form
                className={style.formData}
                form={form}
                autoComplete="false"
                onFinish={() => {}}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <div className={style.dataContentBox}>
                  <div className={style.questionOne}>
                    <Form.Item
                      label={
                        <span>
                          {"Project region or country name? "}
                          <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      name={"q1a"}
                      rules={[
                        { required: true, message: "This field is required" }
                      ]}
                    >
                      <CommonInput
                        inputType="textarea"
                        defaultValue={initialAnswers?.q1a}
                        disabled={isLoading}
                        onChange={e =>
                          setFirstStepAnswers(prev => {
                            return { ...prev, q1a: e.target.value };
                          })
                        }
                        autoSizeCheck={{ minRows: 7, maxRows: 7 }}
                        className={style.emailInput}
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <span style={{ paddingBottom: "8px" }}>
                          {"What kind of project? "}
                          <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      name={"q1b"}
                      rules={[
                        { required: true, message: "This field is required" }
                      ]}
                    >
                      <Select
                        key={initialAnswers?.q1b}
                        defaultValue={initialAnswers?.q1b}
                        placeholder="Select project"
                        className="height-50 ant-select-selector ant-select-selection-placeholder"
                        style={{
                          width: "318px",
                          height: "50px !important",
                          textAlign: "start",
                          padding: "0"
                        }}
                        disabled={isLoading}
                        onChange={val =>
                          setFirstStepAnswers(prev => {
                            return { ...prev, q1b: val };
                          })
                        }
                        suffixIcon={
                          <img
                            style={{ marginRight: "0", marginBottom: "6px" }}
                            src={down}
                            alt={"down icon"}
                          />
                        }
                        options={[
                          {
                            value: "Adaptation",
                            label: "Adaptation"
                          },
                          {
                            value: "Mitigation",
                            label: "Mitigation"
                          }
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <span style={{ marginBottom: "8px" }}>
                          {"Sector to be addressed"}
                          <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      name={"q1c"}
                    >
                      <Select
                        key={initialAnswers?.q1c}
                        defaultValue={initialAnswers?.q1c}
                        placeholder="Select sector"
                        className="height-50 ant-select-selector ant-select-selection-placeholder"
                        disabled={isLoading}
                        style={{
                          width: "318px",
                          height: "50px !important",
                          textAlign: "start",
                          padding: "0"
                        }}
                        onChange={val =>
                          setFirstStepAnswers(prev => {
                            return { ...prev, q1c: val };
                          })
                        }
                        suffixIcon={
                          <img
                            style={{ marginBottom: "6px" }}
                            src={down}
                            alt={"down icon"}
                          />
                        }
                        options={[
                          {
                            value:
                              "Buildings, cities, industries, and appliances",
                            label:
                              "Buildings, cities, industries, and appliances"
                          },
                          {
                            value: "Ecosystems and ecosystem services",
                            label: "Ecosystems and ecosystem services"
                          },
                          {
                            value: "Energy generation and access",
                            label: "Energy generation and access"
                          },
                          {
                            value: "Forests and land use",
                            label: "Forests and land use"
                          },
                          {
                            value: "Health, food, and water security",
                            label: "Health, food, and water security"
                          },
                          {
                            value: "Infrastructure and built environment",
                            label: "Infrastructure and built environment"
                          },
                          {
                            value: "Livelihoods of people and communities",
                            label: "Livelihoods of people and communities"
                          },
                          {
                            value: "Transport",
                            label: "Transport"
                          }
                        ]}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <h1>Enter the Project’s context and baseline below:</h1>

                  <div className={style.dataContentBox}>
                    <Form.Item
                      // label={
                      //   <span>
                      //     {"Enter the Project’s context and baseline below:"}
                      //     <span style={{ color: "red" }}>*</span>
                      //   </span>
                      // }
                      name={"input"}
                      rules={[
                        {
                          required: true,
                          message: "This field is required"
                        }
                      ]}
                    >
                      <CommonInput
                        inputType="textarea"
                        disabled={isLoading}
                        defaultValue={initialAnswers?.["B_1_input"]}
                        onChange={e =>
                          setFirstStepAnswers(prev => {
                            return { ...prev, input: e.target.value };
                          })
                        }
                        placeholder="Describe the climate vulnerabilities and impacts, GHG emissions profile, and mitigation and adaptation needs that the prospective intervention is envisaged to address."
                        className={style.fontSizeInput}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form>

              <div
                style={{
                  paddingTop: "5px",
                  position: "relative",
                  top: !isValid ? "40px" : "30px"
                }}
              >
                The max word limit for this page is {wordLimits[0]} words
              </div>
            </div>
            {/* <div className={style.footerButtonsDiv}>
              <Form form={form} onFinish={onFormSubmit}>
                <Button
                  loading={getLoadingConceptNote}
                  disabled={getLoadingConceptNote}
                  htmlType="submit"
                  className={style.nextButton}
                >
                  Submit
                </Button>
              </Form>
              <div className={style.btnDiv}>
                <div className={style.twoBtnDiv}>
                  <button
                    className={style.goBtn}
                    onClick={() => navigate(constRoute?.projectName)}
                  >
                    <img src={LeftArrow} alt="left-arrow" /> Go Back
                  </button>
                  <button
                    onClick={() => {
                      notification.success("Save and Quit");
                      navigate(constRoute?.home);
                    }}
                    className={style.saveBtn}
                  >
                    Save & Quit
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <QusestionSubmitionModal open={submitModalShow} />
      </div>
    </div>
  );
};
