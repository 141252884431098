import { observer } from "mobx-react";
import style from "./style.module.scss";
import React, { memo, useEffect, useState } from "react";
import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import { CommonInput } from "@components/common-components/input";
import { validateMessages } from "@utils/json-data";
import { useNavigate } from "react-router-dom";
import { constRoute } from "@utils/route";
import { useStore } from "@stores/root-store";
import CommonImportantSideBar from "../importantSideBar/common-important-sidebar";
import CommonFooterButton from "../commonfooterbutton";
import { wordLimits } from "@components/pages/project/select-function/which-to-do/select-one/project-name/important-project-info/utils/wordLimits";
import { useTokensContext } from "@components/layout/main-layout/private-layout";

const ProjectGCFForm = observer(() => {
  const [form] = useForm();
  const [initialQuestions, setInitialQuestions] = useState(null);

  const [show, setShow] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const {
    user: {
      getProjectNameData,
      setConceptNoteLoading,
      getLoadingConceptNote,
      conceptNote,
      getSingleProjectData,
      loadingSingleProjectData,
      handleCheckInputTokensValid,
    },
  } = useStore(null);
  const { setTokens } = useTokensContext();
  const [projectName] = useState(JSON.parse(getProjectNameData)?.project_name);
  const [allInputValues, setAllInputValues] = useState({
    q1: "",
    q2: "",
    q3: "",
  });
  const [isValid, setIsValid] = useState(true);
  const getProjectName = localStorage.getItem("projectName");

  const checkLengthOfInput = async () => {
    const data = {
      functionality: "concept note",
      section: "B_3_48",
      questions: { ...allInputValues },
      project_name: projectName || getProjectName,
    };

    await handleCheckInputTokensValid(data).then((res) => {
      const result = JSON.parse(res.isValid);
      setIsValid(result);
    });
  };

  useEffect(() => {
    checkLengthOfInput();
  }, [allInputValues]);

  useEffect(() => {
    if (localStorage.getItem("AllAnswers") === null) {
      localStorage.setItem(
        "AllAnswers",
        JSON.stringify([{ q10a: "", q10b: "", q10c: "" }]),
      );
    }
  }, []);

  const onFormSubmit = async (values) => {
    localStorage.setItem("isSocketOn", "1");
    const getAnswers = JSON.parse(localStorage.getItem("AllAnswers"));
    const addMoreAnswers = getAnswers?.map((item) => {
      return {
        ...item,
        q10a: values?.q1,
        q10b: values?.q2,
        q10c: values?.q3,
      };
    });
    // const question ={
    //   q10a: values?.q1||'',
    //   q10b: values?.q2||'',
    //   q10c: values?.q3||''
    // }
    localStorage.setItem("AllAnswers", JSON.stringify(addMoreAnswers));
    const getReultsfromls = JSON.parse(localStorage.getItem("totalResults"));
    const addResults =
      getReultsfromls &&
      getReultsfromls?.map((item) => {
        return {
          ...item,
          result7: "",
        };
      });
    localStorage.setItem("totalResults", JSON.stringify(addResults));
    // const payload = {
    //   section: `B_3_48`,
    //   questions: question,
    //   project_name: projectName || getProjectName|| ''
    // }
    // localStorage.setItem('conceptPayload', JSON.stringify(payload))
    // const response = await conceptNote(payload, navigate)
    // if(response?.response){
    navigate(
      constRoute?.projectResultsGcfResults56,
      // ,  { state: { response: response?.response} }
    );
    // const getReultsfromls = JSON.parse(localStorage.getItem('allResults'));
    // const addResults =  getReultsfromls && getReultsfromls?.map((item) => {
    //   return {
    //     ...item,
    //     result7: response?.response || ""
    //   }
    // })
    // localStorage.setItem('allResults', JSON.stringify(addResults))
    // }
  };

  const handleSave = () => {
    setConceptNoteLoading(false);
    // notification.success("Save and Quit");
    navigate(constRoute?.home);
  };
  const handleback = () => {
    localStorage.setItem("isSocketOn", "0");
    setConceptNoteLoading(false);
    navigate(constRoute?.projectResultsGcfResults48);
  };
  const getAnswers = JSON.parse(localStorage.getItem("AllAnswers"));

  useEffect(() => {
    const data = {
      section: "B_3_48",
      project_name: projectName ?? localStorage.getItem("projectName"),
      functionality: "concept note",
    };
    localStorage.setItem("data-for-tokens", JSON.stringify(data));
    getSingleProjectData(data).then((el) => {
      setTokens(el["tokens_remaining/tokens_purchased"]);

      setInitialQuestions(el);
      setIsLoading(false);
      form.setFieldsValue(el.questions);
    });
  }, []);

  return (
    <div className={style.mainContainer}>
      <div className={style.barContentContainer}>
        <CommonImportantSideBar
          title={"Quick Tips"}
          fristPara={` 1. For question 10 (Accredited Entity), discuss how long (in years) the AE been working in the target country (or in a similar country context) and for how long has the AE been working with the National Designated Authority (NDA) of the country.`}
          secondParagraph={` 2. For question 10 (Executing Entity) discuss ​how ​long  the EE has been working in the target country, or in a similar country context. Also, discuss how long has the EE been working with the country’s (or key target countries) National Designated Authority. `}
          thirdParagraph={`3. Highlight stakeholder engagement with national and local stakeholders, e.g. national ministries, the Accredited Entity, the National Designated Authority, as well as civil society, academia and other stakeholders for the project.`}
        />
        {/* {show && (
        <div className={style.layoutDiv}>
          <div className={style.siderStyle}>
            <div className={style.sideInnerDiv}>
              <div className={style.importantDiv}>
                <p className={style.pTagFour}>Quick Tips</p>
                <button className={style.btnClass} onClick={() => setShow(!show)}>
                  <img
                    src={CloseIcon}
                    className={style.closeIconImg}
                    alt="fd"
                  />
                </button>
              </div>
              <div className={style.pTageGroup}>
                <p className={style.pTagFive}>
                  Demonstrate how the intervention aligns with national
                  policies, strategies and/or frameworks, e.g. alignment of
                  project activities with achievement of the NDC. 
                </p>
                <p className={style.pTagSix}>
                  Highlight stakeholder engagement with national and local
                  stakeholders, e.g. national ministries, the Accredited Entity,
                  the National Designated Authority, as well as civil society,
                  academia and other stakeholders for the particular project.
                </p>
              </div>
              <Divider />
            </div>
          </div>
        </div>
      )} */}
        <div
          className={style.contentContainer}
          style={{ cursor: isLoading ? "wait" : "" }}
        >
          <div className={style.innerContentContainer}>
            <h1>Please Fill In the Blanks Below:</h1>

            <div className={style.dataContentBox}>
              <Form
                className={style.formData}
                form={form}
                autoComplete="false"
                onFinish={onFormSubmit}
                validateMessages={validateMessages}
                layout="vertical"
                initialValues={{
                  q1:
                    initialQuestions?.questions?.q10a ||
                    (getAnswers && getAnswers[0]?.q10a) ||
                    "",
                  q2:
                    initialQuestions?.questions?.q10b ||
                    (getAnswers && getAnswers[0]?.q10b) ||
                    "",
                  q3:
                    initialQuestions?.questions?.q10c ||
                    (getAnswers && getAnswers[0]?.q10c) ||
                    "",
                }}
              >
                <Form.Item
                  label={
                    <span>
                      {`10a) Briefly Describe Country Ownership of the Project.`}
                      <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name={"q1"}
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <CommonInput
                    inputType="textarea"
                    onChange={(e) =>
                      setAllInputValues((prev) => {
                        return { ...prev, q1: e.target.value };
                      })
                    }
                    disabled={isLoading}
                    autoSizeCheck={{ minRows: 7, maxRows: 7 }}
                    className={style.fontSizeInput}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      {`10b) What is the AE’s experience working in the project country of implementation or a similar context.`}
                      <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name={"q2"}
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <CommonInput
                    inputType="textarea"
                    onChange={(e) =>
                      setAllInputValues((prev) => {
                        return { ...prev, q2: e.target.value };
                      })
                    }
                    disabled={isLoading}
                    autoSizeCheck={{ minRows: 7, maxRows: 7 }}
                    // placeholder="Leave blank if you want Climate Finance Co-pilot to supply suggested narratives to give you a head start on completing this section."
                    className={style.fontSizeInput}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      {
                        "     10c) What is the AE/EE experience in implementing similar projects/ intervention.  "
                      }
                      <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name={"q3"}
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <CommonInput
                    inputType="textarea"
                    onChange={(e) =>
                      setAllInputValues((prev) => {
                        return { ...prev, q3: e.target.value };
                      })
                    }
                    disabled={isLoading}
                    autoSizeCheck={{ minRows: 7, maxRows: 7 }}
                    // placeholder="Leave blank if you want Climate Finance Co-pilot to supply suggested narratives to give you a head start on completing this section."
                    className={style.fontSizeInput}
                  />
                </Form.Item>
              </Form>
              {!isValid && (
                <div
                  style={{
                    color: "red",
                    fontSize: "14px",
                    position: "relative",
                    top: "45px",
                  }}
                >
                  You have exceeded the maximum length for this field
                </div>
              )}

              <div
                style={{
                  paddingTop: "5px",
                  position: "relative",
                  top: !isValid ? "40px" : "30px",
                }}
              >
                The max word limit for this page is {wordLimits[2]} words
              </div>
            </div>
            {/* <div className={style.footerButtonsDiv}>
              <Form form={form} onFinish={onFormSubmit}>
                <Button loading={getLoadingConceptNote} disabled={getLoadingConceptNote} htmlType="submit" className={style.nextButton}>
                  Submit
                </Button>
              </Form>
              <div className={style.btnDiv}>
                <div className={style.twoBtnDiv}>
                  <button
                    className={style.goBtn}
                    onClick={() =>
                      navigate(constRoute?.projectResultsGcfResults48)
                    }
                  >
                    {" "}
                    <img src={LeftArrow} alt="left-arrow" /> Go Back
                  </button>
                  <button
                    onClick={() => {
                      notification.success("Save and Quit");
                      navigate(constRoute?.home);
                    }}
                    className={style.saveBtn}
                  >
                    Save & Quit
                  </button>
                </div>
              </div>
            </div> */}
          </div>
          <CommonFooterButton
            isLoadingSubmit={getLoadingConceptNote}
            handleSubmit={onFormSubmit}
            handlegoback={handleback}
            handleSaveAndQuit={handleSave}
            // handleQuickNext={constRoute?.projectResultsGcfResults56}
            form={form}
            disableSubmit={!isValid || isLoading}
          />
        </div>
      </div>
    </div>
  );
});

export default memo(ProjectGCFForm);
