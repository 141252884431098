import React from "react";
import styles from "./style.module.scss";

const SoftwareAttribution = () => {
  return (
    <div className={styles.software}>
      <div className={styles.softwareContent}>
        <h1 className={styles.softwareContentTitle}>Software Attribution</h1>

        <div className={styles.softwareContentDesc}>
          <p>
            Climate Finance Co-pilot integrates technology powered by OpenAI's
            Chat GPT. The original work by OpenAI is licensed under the Creative
            Commons Attribution-NonCommercial-ShareAlike 4.0 International
            License. Adaptations and modifications to the model for "Climate
            Finance Co-pilot" have been executed by Janus Advisory Services Inc.
            All associated trademarks and copyright notices of OpenAI are hereby
            acknowledged and remain intact. OpenAI does not endorse and is not
            affiliated with "Climate Finance Co-pilot."
          </p>
          <p>
            In addition to the aforementioned components, "Climate Finance
            Co-pilot" also encompasses distinct proprietary features, codes, and
            processes proprietary to Janus Advisory Services Inc. By accessing
            and utilizing this application, users are bound by the application's
            terms of service and acknowledge the integration and presence of
            both licensed and proprietary components within the software's
            framework.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SoftwareAttribution;
