import { observer } from "mobx-react";
import style from "./style.module.scss";
import React, { memo, useEffect, useState } from "react";
import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import { CommonInput } from "@components/common-components/input";
import { validateMessages } from "@utils/json-data";
import { useNavigate } from "react-router-dom";
import { constRoute } from "@utils/route";
import { useStore } from "@stores/root-store";
import CommonImportantSideBar from "../importantSideBar/common-important-sidebar";
import CommonFooterButton from "../commonfooterbutton";
import { wordLimits } from "@components/pages/project/select-function/which-to-do/select-one/project-name/important-project-info/utils/wordLimits";
import { useTokensContext } from "@components/layout/main-layout/private-layout";

const SustainabilityReplicabilityForm = observer(() => {
  const [form] = useForm();
  const [show, setShow] = useState(true);
  const [initialQuestions, setInitialQuestions] = useState(null);

  const navigate = useNavigate();

  const {
    user: {
      getProjectNameData,
      setConceptNoteLoading,
      getLoadingConceptNote,
      conceptNote,
      getSingleProjectData,
      loadingSingleProjectData,
      handleCheckInputTokensValid,
    },
  } = useStore(null);
  const { setTokens } = useTokensContext();
  const [projectName] = useState(JSON.parse(getProjectNameData)?.project_name);
  const [allInputValues, setAllInputValues] = useState({
    q14a: "",
    q14b: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const getProjectName = localStorage.getItem("projectName");
  const dummyArrayOfImportantSIdeBarList = [
    `Identify key sustainability objectives and indicators.`,
    `Develop a sustainability strategy.`,
    `Establish partnerships.`,
    `Ensure community ownership.`,
    ` Develop a robust monitoring and evaluation framework`,
  ];

  const checkLengthOfInput = async () => {
    const data = {
      functionality: "concept note",
      section: "C_3_90",
      questions: { ...allInputValues },
      project_name: projectName || getProjectName,
    };

    await handleCheckInputTokensValid(data).then((res) => {
      const result = JSON.parse(res.isValid);
      setIsValid(result);
    });
  };

  useEffect(() => {
    const value = Object.values(allInputValues).join(" ");
    checkLengthOfInput();
  }, [allInputValues]);

  useEffect(() => {
    if (localStorage.getItem("AllAnswers") === null) {
      localStorage.setItem(
        "AllAnswers",
        JSON.stringify([{ q14a: "", q14b: "" }]),
      );
    }
  }, []);
  const onFormSubmit = async (values) => {
    localStorage.setItem("isSocketOn", "1");
    const getAnswers = JSON.parse(localStorage.getItem("AllAnswers"));
    const addMoreAnswers = getAnswers
      ? getAnswers?.map((item) => {
          return {
            ...item,
            q14a: values?.q14a,
            q14b: values?.q14b,
          };
        })
      : [];
    localStorage.setItem("AllAnswers", JSON.stringify(addMoreAnswers));
    const question = {
      q14a: values?.q14a || "",
      q14b: values?.q14b || "",
    };
    // const payload = {
    //   section: `C_3_90`,
    //   questions: question,
    //   project_name: projectName || getProjectName|| ''
    // }
    // localStorage.setItem('conceptPayload', JSON.stringify(payload))
    // const response = await conceptNote(payload, navigate)
    // if(response?.response){
    navigate(
      constRoute?.sustainabilityReplicabilityResults100,

      // ,  { state: { response: response?.response} }
    );
    // const getReultsfromls = JSON.parse(localStorage.getItem('allResults'));
    // const addResults =  getReultsfromls && getReultsfromls?.map((item) => {
    //   return {
    //     ...item,
    //     result11: response?.response || ""
    //   }
    // })
    // localStorage.setItem('allResults', JSON.stringify(addResults))
    // }
    const getReultsfromls = JSON.parse(localStorage.getItem("totalResults"));
    const addResults =
      getReultsfromls &&
      getReultsfromls?.map((item) => {
        return {
          ...item,
          result11: "",
        };
      });
    localStorage.setItem("totalResults", JSON.stringify(addResults));
  };
  const handleback = () => {
    localStorage.setItem("isSocketOn", "0");
    setConceptNoteLoading(false);
    navigate(constRoute?.gcfJustificationResults90);
  };
  const handleSave = () => {
    setConceptNoteLoading(false);
    // notification.success("Save and Quit");
    navigate(constRoute?.home);
  };
  const getAnswers = JSON.parse(localStorage.getItem("AllAnswers"));

  useEffect(() => {
    const data = {
      section: "C_3_90",
      project_name: projectName ?? localStorage.getItem("projectName"),
      functionality: "concept note",
    };
    localStorage.setItem("data-for-tokens", JSON.stringify(data));
    getSingleProjectData(data).then((el) => {
      setTokens(el["tokens_remaining/tokens_purchased"]);

      setInitialQuestions(el);
      setIsLoading(false);
      form.setFieldsValue(el.questions);
    });
  }, []);

  return (
    <div className={style.mainContainer}>
      <div className={style.barContentContainer}>
        <CommonImportantSideBar
          title={"Quick Tips"}
          fristPara={`A climate project/programme sustainability plan is critical for ensuring the long-term success and impact of a project/programme. Here are some topics to consider as you prepare to write this plan:  `}
          isList={true}
          listArray={dummyArrayOfImportantSIdeBarList}
        />
        {/* {show && (
        <div className={style.layoutDiv}>
          <div className={style.siderStyle}>
            <div className={style.sideInnerDiv}>
              <div className={style.importantDiv}>
                <p className={style.pTagFour}>Quick Tips</p>
                <button className={style.btnClass} onClick={() => setShow(!show)}>
                  <img
                    src={CloseIcon}
                    className={style.closeIconImg}
                    alt="fd"
                  />
                </button>
              </div>
              <div className={style.pTageGroup}>
                <p className={style.pTagFive}>
                  A climate project/programme sustainability plan is critical
                  for ensuring the long-term success and impact of a
                  project/programme. Here are some topics to consider as you
                  prepare to write this plan:  
                </p>
                <p className={style.pTagSix}>
                  <ul>
                    <li>
                      Identify key sustainability objectives and indicators.
                    </li>
                    <li>Develop a sustainability strategy.</li>
                    <li>Establish partnerships.</li>
                    <li>Ensure community ownership.</li>
                    <li>
                      Develop a robust monitoring and evaluation framework.
                    </li>
                  </ul>
                   
                </p>
              </div>
              <Divider />
            </div>
          </div>
        </div>
      )} */}
        <div
          className={style.contentContainer}
          style={{ cursor: isLoading ? "wait" : "" }}
        >
          <div className={style.innerContentContainer}>
            <h1>Please Fill In the Blanks Below:</h1>

            <div className={style.dataContentBox}>
              <Form
                className={style.formData}
                form={form}
                autoComplete="false"
                onFinish={onFormSubmit}
                validateMessages={validateMessages}
                layout="vertical"
                initialValues={{
                  q14a:
                    initialQuestions?.questions?.q14a ||
                    (getAnswers && getAnswers[0]?.q14a) ||
                    "",
                  q14b:
                    initialQuestions?.questions?.q14b ||
                    (getAnswers && getAnswers[0]?.q14b) ||
                    "",
                }}
              >
                <Form.Item
                  label={
                    <span>
                      {
                        "14a) Please explain how the project/programme sustainability will be ensured in the long run and how this will be monitored after the project/programme is implemented with support from the GCF and other sources."
                      }
                      <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name={"q14a"}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <CommonInput
                    inputType="textarea"
                    onChange={(e) =>
                      setAllInputValues((prev) => {
                        return { ...prev, q14a: e.target.value };
                      })
                    }
                    disabled={isLoading}
                    autoSizeCheck={{ minRows: 10, maxRows: 10 }}
                    className={style.fontSizeInput}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      {
                        "14b) For non-grant instruments, explain how the capital invested will be repaid and over what duration of time."
                      }
                      <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name={"q14b"}
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <CommonInput
                    inputType="textarea"
                    onChange={(e) =>
                      setAllInputValues((prev) => {
                        return { ...prev, q14b: e.target.value };
                      })
                    }
                    disabled={isLoading}
                    autoSizeCheck={{ minRows: 10, maxRows: 10 }}
                    // placeholder="Leave blank if you want Climate Finance Co-pilot to supply suggested narratives to give you a head start on completing this section."
                    className={style.fontSizeInput}
                  />
                </Form.Item>
              </Form>
              {!isValid && (
                <div
                  style={{
                    color: "red",
                    fontSize: "14px",
                    position: "relative",
                    top: "45px",
                  }}
                >
                  You have exceeded the maximum length for this field
                </div>
              )}

              <div
                style={{
                  paddingTop: "5px",
                  position: "relative",
                  top: !isValid ? "40px" : "30px",
                }}
              >
                The max word limit for this page is {wordLimits[5]} words
              </div>
            </div>

            {/* <div className={style.footerButtonsDiv}>
              <Form form={form} onFinish={onFormSubmit}>
                <Button loading={getLoadingConceptNote} disabled={getLoadingConceptNote} htmlType="submit" className={style.nextButton}>
                  Submit
                </Button>
              </Form>
              <div className={style.btnDiv}>
                <div className={style.twoBtnDiv}>
                  <button
                    className={style.goBtn}
                    onClick={() => navigate(constRoute?.gcfJustificationResults90)}
                  >
                    <img src={LeftArrow} alt="left-arrow" /> Go Back
                  </button>
                  <button
                    onClick={() => {
                      notification.success("Save and Quit");
                      navigate(constRoute?.home);
                    }}
                    className={style.saveBtn}
                  >
                    Save & Quit
                  </button>
                </div>
              </div>
            </div> */}
          </div>
          <CommonFooterButton
            isLoadingSubmit={getLoadingConceptNote}
            handleSubmit={onFormSubmit}
            handlegoback={handleback}
            handleSaveAndQuit={handleSave}
            disableSubmit={!isValid || isLoading}
            // handleQuickNext={constRoute?.sustainabilityReplicabilityResults100}
            form={form}
          />
        </div>
      </div>
    </div>
  );
});

export default memo(SustainabilityReplicabilityForm);
