import { observer } from "mobx-react";
import style from "./style.module.scss";
import React, { memo, useEffect, useState } from "react";
import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import { CommonInput } from "@components/common-components/input";
import { validateMessages } from "@utils/json-data";
import { useNavigate } from "react-router-dom";
import { constRoute } from "@utils/route";
import { useStore } from "@stores/root-store";
import CommonImportantSideBar from "../importantSideBar/common-important-sidebar";
import CommonFooterButton from "../commonfooterbutton";
import { wordCountValidation } from "@utils/helpers/wordCountValidator";
import { wordLimits } from "@components/pages/project/select-function/which-to-do/select-one/project-name/important-project-info/utils/wordLimits";
import { useTokensContext } from "@components/layout/main-layout/private-layout";

const ProjectDescriptionForm = observer(() => {
  const [form] = useForm();
  const [initialQuestions, setInitialQuestions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const {
    user: {
      getProjectNameData,
      setConceptNoteLoading,
      getLoadingConceptNote,
      conceptNote,
      getSingleProjectData,
      loadingSingleProjectData,
      handleCheckInputTokensValid,
    },
  } = useStore(null);
  const { setTokens } = useTokensContext();
  const [projectName] = useState(JSON.parse(getProjectNameData)?.project_name);
  const [allInputValues, setAllInputValues] = useState({
    q4: "",
    q5: "",
  });
  const [isValid, setIsValid] = useState(true);
  const getProjectName = localStorage.getItem("projectName");

  const checkLengthOfInput = async () => {
    const data = {
      functionality: "concept note",
      section: "B_2_8",
      questions: { ...allInputValues },
      project_name: projectName || getProjectName,
    };

    await handleCheckInputTokensValid(data).then((res) => {
      const result = JSON.parse(res.isValid);
      setIsValid(result);
    });
  };

  useEffect(() => {
    checkLengthOfInput();
  }, [allInputValues]);

  useEffect(() => {
    if (localStorage.getItem("AllAnswers") === null) {
      localStorage.setItem("AllAnswers", JSON.stringify([{ q4: "", q5: "" }]));
    }
  }, []);

  const onFormSubmit = async (values) => {
    localStorage.setItem("isSocketOn", "1");
    const getAnswers = JSON.parse(localStorage.getItem("AllAnswers"));
    const addMoreAnswers = getAnswers?.map((item) => {
      return {
        ...item,
        q4: values?.q4,
        q5: values?.q5,
      };
    });
    const question = {
      q4: values?.q4 || "",
      q5: values?.q5 || "",
    };
    localStorage.setItem("AllAnswers", JSON.stringify(addMoreAnswers));
    const getReultsfromls = JSON.parse(localStorage.getItem("totalResults"));
    const addResults =
      getReultsfromls &&
      getReultsfromls?.map((item) => {
        return {
          ...item,
          result2: "",
        };
      });
    localStorage.setItem("totalResults", JSON.stringify(addResults));
    // const payload = {
    //   section: `B_2_8`,
    //   questions: question,
    //   project_name: projectName || getProjectName || "",
    // };
    // localStorage.setItem("conceptPayload", JSON.stringify(payload));
    // const response = await conceptNote(payload, navigate);
    // if (response?.response) {
    navigate(
      constRoute?.projectDescriptionResults,
      //   , {
      //   state: { response: response?.response },
      // }
    );

    // const getReultsfromls = JSON.parse(localStorage.getItem('allResults'));
    //  const addResults =  getReultsfromls && getReultsfromls?.map((item) => {
    //     return {
    //       ...item,
    //       result2: response?.response || ""
    //     }
    //   })
    // localStorage.setItem('allResults', JSON.stringify(addResults))

    // }
  };
  const handleSave = () => {
    setConceptNoteLoading(false);
    // notification.success("Save and Quit");
    navigate(constRoute?.home);
  };
  const handleback = () => {
    localStorage.setItem("isSocketOn", "0");
    setConceptNoteLoading(false);
    navigate(constRoute?.contextAndBaselineResults);
  };
  useEffect(() => {
    const data = {
      section: "B_2_8",
      project_name: projectName ?? localStorage.getItem("projectName"),
      functionality: "concept note",
    };
    localStorage.setItem("data-for-tokens", JSON.stringify(data));
    getSingleProjectData(data).then((el) => {
      setTokens(el["tokens_remaining/tokens_purchased"]);
      setInitialQuestions(el);
      setIsLoading(false);
      form.setFieldsValue(el.questions);
    });
  }, []);
  const getAnswers = JSON.parse(localStorage.getItem("AllAnswers"));

  return (
    <div className={style.mainContainer}>
      <div className={style.barContentContainer}>
        <CommonImportantSideBar
          title={"Please Note:"}
          fristPara={`1. If not provided in your input, CFC  will formulate the theory of change and discuss how it serves to shift the development pathway toward a more low-emissions and/or climate resilient direction, in line with the Fund’s goals and objectives. `}
          secondParagraph={` 2. For the Accredited Entity(ies) questions, discuss the implementation plan/ arrangements for project governance, coordination and management and which organizations were involved in the process. `}
        />
        <div
          className={style.contentContainer}
          style={{ cursor: isLoading ? "wait" : "" }}
        >
          <div className={style.innerContentContainer}>
            <h1>Please Fill In the Blanks Below:</h1>

            <div className={style.dataContentBox}>
              <Form
                className={style.formData}
                form={form}
                autoComplete="false"
                onFinish={onFormSubmit}
                validateMessages={validateMessages}
                layout="vertical"
                initialValues={{
                  q4:
                    initialQuestions?.questions?.q4 ||
                    (getAnswers && getAnswers[0]?.q4) ||
                    "",
                  q5:
                    initialQuestions?.questions?.q5 ||
                    (getAnswers && getAnswers[0]?.q5) ||
                    "",
                }}
              >
                <Form.Item
                  label={
                    <span>
                      {
                        "4. Describe the project components  and subcomponents that are intended to address the previously discussed barriers."
                      }
                      <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name={"q4"}
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <CommonInput
                    inputType="textarea"
                    onChange={(e) =>
                      setAllInputValues((prev) => {
                        return { ...prev, q4: e.target.value };
                      })
                    }
                    disabled={isLoading}
                    autoSizeCheck={{ minRows: 7, maxRows: 7 }}
                    className={style.fontSizeInput}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      {
                        "5. What is the name of the Accredited Entity(ies) and describe the implementation arrangements with the executing entity(ies) and implementing partners.      "
                      }
                      <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name={"q5"}
                  rules={[
                    { required: true, message: "This field is required" },
                    {
                      validator: wordCountValidation(500, 25000),
                    },
                  ]}
                >
                  <CommonInput
                    inputType="textarea"
                    onChange={(e) =>
                      setAllInputValues((prev) => {
                        return { ...prev, q5: e.target.value };
                      })
                    }
                    disabled={isLoading}
                    autoSizeCheck={{ minRows: 7, maxRows: 7 }}
                    // placeholder="Leave blank if you want Climate Finance Co-pilot to supply suggested components/outputs and subcomponents"
                    className={style.fontSizeInput}
                  />
                </Form.Item>
              </Form>
              {!isValid && (
                <div
                  style={{
                    color: "red",
                    fontSize: "14px",
                    position: "relative",
                    top: "45px",
                  }}
                >
                  You have exceeded the maximum length for this field
                </div>
              )}

              <div
                style={{
                  paddingTop: "5px",
                  position: "relative",
                  top: !isValid ? "40px" : "30px",
                }}
              >
                The max word limit for this page is {wordLimits[1]} words
              </div>
            </div>
          </div>
          <CommonFooterButton
            isLoadingSubmit={getLoadingConceptNote}
            handleSubmit={onFormSubmit}
            handlegoback={handleback}
            handleSaveAndQuit={handleSave}
            disableSubmit={!isValid || isLoading}
            form={form}
            // handleQuickNext={constRoute?.projectDescriptionResults}
          />
        </div>
      </div>
    </div>
  );
});

export default memo(ProjectDescriptionForm);
