import React, { FC } from "react";
import styled from "styled-components";
import { Modal, Select } from "antd";
import styles from "./change-user-plan-modal.module.scss";
import down from "@assets/images/down.svg";
import classNames from "classnames";

interface Props {
  setIsOpen?: (value) => void;
  isOpen?: boolean;
  onDeleteRecord?: any;
  typeToUpdate?: any;
  userToUpdate?: any;
  currentUserType?: any;
  loading?: boolean;
  isUpgrade?: boolean;
  users?: any[];
  closeModal?: () => void;
  setOpenConfirmationModal?: any;
  setUserToUpdate?: any;
  setTypeToUpdate?: any;
}

const ChangeUserPlanModal: FC<Props> = ({
  onDeleteRecord,
  isOpen,
  closeModal,
  users,
  loading,
  setUserToUpdate,
  setTypeToUpdate,
  typeToUpdate,
  userToUpdate,
  currentUserType,
  setOpenConfirmationModal,
  isUpgrade,
}) => {
  return (
    <Wrapper>
      <Modal
        onCancel={closeModal}
        open={isOpen}
        className={"customKeywordModal"}
        width={721}
        closable={false}
        footer={null}
      >
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <h3 className={styles.modalHeaderTitle}>Change plan details</h3>
            <div style={{ cursor: "pointer" }} onClick={() => closeModal()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.4546 0.252083C10.1405 -0.0729166 9.63307 -0.0729166 9.31894 0.252083L5.38027 4.31875L1.4416 0.24375C1.12747 -0.08125 0.620035 -0.08125 0.305908 0.24375C-0.00821932 0.56875 -0.00821932 1.09375 0.305908 1.41875L4.24458 5.49375L0.305908 9.56875C-0.00821932 9.89375 -0.00821932 10.4187 0.305908 10.7437C0.620035 11.0687 1.12747 11.0687 1.4416 10.7437L5.38027 6.66875L9.31894 10.7437C9.63307 11.0687 10.1405 11.0687 10.4546 10.7437C10.7688 10.4187 10.7688 9.89375 10.4546 9.56875L6.51596 5.49375L10.4546 1.41875C10.7607 1.10208 10.7607 0.56875 10.4546 0.252083Z"
                  fill="#2E2E2E"
                />
              </svg>
            </div>
          </div>
          <div>
            <label
              style={{
                display: "flex",
                fontSize: "16px",
                paddingTop: "28px",
                paddingBottom: "8px",
                paddingLeft: "2px",
              }}
            >
              Copilot Plan
            </label>
            <Select
              value={typeToUpdate}
              placeholder="Select plan"
              style={{
                width: "100%",
                textAlign: "start",
                marginBottom: "12px",
              }}
              onChange={(val) => setTypeToUpdate(val)}
              suffixIcon={<img src={down} alt={"down icon"} />}
              options={[
                {
                  value: "Basic",
                  label: "Basic",
                },
                {
                  value: "Premium",
                  label: "Premium",
                },
                {
                  value: "Enterprise",
                  label: "Enterprise",
                },
              ]}
            />
          </div>
          {/*<div*/}
          {/*  className={styles.modalBanner}*/}
          {/*  style={{ flexDirection: isUpgrade ? "column" : "column-reverse" }}*/}
          {/*>*/}
          {/*  <p className={styles.modalBannerTypeToChange}>{typeToUpdate}</p>*/}
          {/*  <p className={styles.modalBannerCurrentType}>{currentUserType}</p>*/}
          {/*</div>*/}
          <div>
            <label
              style={{
                display: "flex",
                paddingBottom: "8px",
                paddingLeft: "2px",
              }}
            >
              User
            </label>
            <Select
              value={userToUpdate}
              placeholder="Select user"
              style={{ width: "100%", textAlign: "start" }}
              onChange={(val) => {
                setUserToUpdate(val);
              }}
              suffixIcon={<img src={down} alt={"down icon"} />}
              options={users}
            />
            <div className={styles.modalButtonWrap}>
              <div
                className={classNames(
                  styles.modalButtonDisabled,
                  styles.modalButton,
                )}
                onClick={() => {
                  closeModal();
                }}
              >
                Cancel
              </div>
              <div
                className={styles.modalButton}
                onClick={() => {
                  closeModal();
                  setOpenConfirmationModal(true);
                }}
              >
                Update Plan
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Wrapper>
  );
};

export default ChangeUserPlanModal;

const Wrapper = styled.div`
  .customKeywordModal {
    position: relative;

    .rc-dialog-content {
      .rc-dialog-close {
        color: #fff;
        font-size: 40px;
        right: -50px;
        top: -14px;
      }
    }
  }
`;
