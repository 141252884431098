import React from "react";
import { Layout } from "antd";
import style from "./admin-layout.module.scss";
import { Content } from "antd/es/layout/layout";
import AdminRouting from "../../../admin-roter-service";
import AdminHeader from "@components/layout/admin-layout/header/AdminHeader";
import SideBar from "@components/layout/admin-layout/sideBar/SideBar";
import { InviteModalProvider } from "@components/ModalContext/InviteModalContext";

const AdminLayout = () => {
  return (
    <Layout className={style.layout}>
      <InviteModalProvider>
        <div className={style.layoutHeader}>
          <AdminHeader />
        </div>

        <div className={style.layoutSidebar}>
          <SideBar />
        </div>

        <Content>
          <AdminRouting />
        </Content>
      </InviteModalProvider>
    </Layout>
  );
};

export default AdminLayout;
