import ArrowIconWhite from "@assets/icons/ArrowIconWhite";
import StopGenerationIcon from "@assets/icons/StopGenerationIcon";
import Copied from "@assets/icons/copied.svg";
import Copy from "@assets/icons/copy.svg";
import Close from "@assets/icons/cross.svg";
import down from "@assets/images/down.svg";
import UploadImage from "@assets/images/upload_image.svg";
import { ChatRole, IChat } from "@components/pages/project/Playground/types";
import { useStore } from "@stores/root-store";
import { validateMessages } from "@utils/json-data";
import { Form, Select, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import classNames from "classnames";
import React, { memo, useEffect, useState } from "react";
import pdfButtonIcon from "../../../../assets/icons/writing-tips-button.svg";
import style from "./style.module.scss";

interface AdvisorFormProps {
  Text: JSX.Element;
  disabled: boolean;
  maxLength: number;
  handleSubmit: (data: any) => void;
  onDownloadPdf?: () => void;
  setInput?: (val: string) => void;
  setChat?: any;
  chat: IChat[];
  form: any;
  initialState: any;
  selectedFile?: File | null;
  setSelectedFile?: (file: File) => void;
  placeholder?: string;
  isFormPage?: boolean;
  isSubmitting?: boolean;
  value?: string;
  input?: string;
  question?: string;
  stopGeneration?: () => void;
}

const PlaygroundForm: React.FC<AdvisorFormProps> = ({
  Text,
  disabled,
  maxLength,
  handleSubmit,
  form,
  initialState,
  placeholder,
  isFormPage,
  value,
  isSubmitting,
  question,
  onDownloadPdf,
  input,
  selectedFile,
  setSelectedFile,
  setInput,
  chat,
  setChat,
  stopGeneration
}) => {
  const {
    user: { getUserType },
    modelType: { changeModelType, getSelectedModelType }
  } = useStore(null);
  const [copiedIndex, setCopiedIndex] = React.useState<number>(-1);
  const [preview, setPreview] = useState("");

  const onClickEnter = event => {
    if (event.key === "Enter" && input.trim().length) {
      event.preventDefault();
      handleSubmit("");
    }
  };

  useEffect(() => {
    form.setFieldValue("q", input);
  }, [input]);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    const chat = document.getElementById("chat");
    chat.scrollTop = chat.scrollHeight;
  }, [chat]);

  useEffect(() => {
    if (getUserType === "enterprise") {
      changeModelType("Orion");
    }
  }, [getUserType]);

  useEffect(() => {
    if (!copiedIndex) return;

    setTimeout(() => {
      setCopiedIndex(null);
    }, 2000);
  }, [copiedIndex]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return (
    <>
      <div className={style.formWrapper}>
        <Form
          className={style.form}
          form={form}
          autoComplete="false"
          onFinish={handleSubmit}
          validateMessages={validateMessages}
          layout="vertical"
          initialValues={{
            q: input
          }}
        >
          <div className={style.pdfButton} onClick={() => onDownloadPdf()}>
            <img src={pdfButtonIcon} alt="pdfButtonIcon" />
          </div>
          <span className={style.label}>{Text}</span>
          <div
            className={style.wrapper}
            style={{
              maxHeight: selectedFile ? "50vh" : "60vh"
            }}
          >
            <div className={style.chat} id="chat">
              {!!chat.length ? (
                chat.map((el: IChat, index: number) => {
                  return (
                    <div key={index} className={style.chatMessage}>
                      <p className={style.chatMessageRole}>{el.role}</p>
                      <p className={style.chatMessageText}>
                        <span
                          style={{
                            whiteSpace: "pre-line"
                          }}
                        >
                          {el.message}
                        </span>
                      </p>
                      {el.image && (
                        <img src={URL.createObjectURL(el.image)} alt="" />
                      )}
                      <div>
                        <div
                          className={style.copyIcon}
                          onClick={() => {
                            navigator.clipboard.writeText(el.message);
                            setCopiedIndex(index);
                          }}
                        >
                          {el.role === ChatRole.CHAT &&
                            el.message &&
                            (copiedIndex === index ? (
                              <img
                                style={{ width: "24px", height: "24px" }}
                                src={Copied}
                                alt="copied"
                              />
                            ) : (
                              <img
                                style={{ cursor: "pointer" }}
                                src={Copy}
                                alt="copy"
                              />
                            ))}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className={style.chatPlaceholder}>
                  <p>Here are a few things to know when utilizing this tool:</p>
                  <p>
                    • Enter any query or task into the box located at the bottom
                    of this page.
                  </p>
                  <p>
                    • The Playground will remember your queries/tasks, enabling
                    you to ask follow-up questions as needed.
                  </p>
                  <p>
                    • Be aware that once you navigate from this page, your
                    session will be cleared from memory, so make sure to save
                    any information outputs.
                  </p>
                  <p>
                    • For assistance on crafting effective prompts, refer to the
                    prompt writing tips guide situated at the top right of this
                    page.
                  </p>
                </div>
              )}
            </div>
            <div className={style.bottomSection}>
              {getUserType === "enterprise" && (
                <Select
                  defaultValue={"Orion"}
                  style={{ width: "150px", textAlign: "start" }}
                  suffixIcon={<img src={down} alt={"down icon"} />}
                  onChange={value => changeModelType(value)}
                  placement="topRight"
                  options={[
                    {
                      label: "Gemini",
                      value: "Gemini"
                    },
                    {
                      label: "Mentor",
                      value: "Mentor"
                    },
                    {
                      label: "Orion",
                      value: "Orion"
                    },
                    {
                      label: "Tucano",
                      value: "Tucano"
                    }
                  ]}
                />
              )}
            </div>
          </div>
          <Form.Item
            name={"q"}
            rules={[{ required: !disabled, message: "This field is required" }]}
          >
            <div style={{ border: "2px solid #ffb02f", background: "#fff" }}>
              <TextArea
                style={{ border: "0px", resize: "none" }}
                onKeyDown={e => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    onClickEnter(e);
                  }
                }}
                placeholder="Enter your query here. If you want to upload an image, click on the image button on the right"
                // disabled={disabled}
                value={input}
                rows={3}
                onChange={e => {
                  setInput(e.target.value);
                }}
                className={classNames(
                  style.textarea,
                  style.textareaFont,
                  isSubmitting && style.textareaWithoutItalicStyle
                )}
              />
              <div className={style.textareaIcons}>
                <div>
                  <Upload
                    accept="image/*"
                    onChange={info => {
                      setSelectedFile(info.file.originFileObj);
                    }}
                    itemRender={() => null}
                  >
                    <img src={UploadImage} alt="upload" />
                  </Upload>
                </div>

                <div
                  className={style.textareaIcon}
                  onClick={handleSubmit}
                  style={{ backgroundColor: isSubmitting ? "transparent" : "" }}
                >
                  {isSubmitting ? (
                    <div onClick={() => stopGeneration()}>
                      <StopGenerationIcon />
                    </div>
                  ) : (
                    <ArrowIconWhite />
                  )}
                </div>
              </div>
              {preview && (
                <div className={style.previewWrapper}>
                  <div>
                    <img src={preview} alt="preview" />
                    <div
                      className={style.clearFile}
                      onClick={() => {
                        setSelectedFile(null);
                        setPreview("");
                      }}
                    >
                      <img src={Close} alt="Clear file" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Form.Item>

          {/*{isFormPage && (*/}
          {/*  <p style={{ marginTop: "15px", fontStyle: "italic" }}>*/}
          {/*    Maximum {maxLength} words*/}
          {/*  </p>*/}
          {/*)}*/}
          <div className={style.bottomText}>
            Climate Finance Co-pilot can make mistakes. Consider double checking
            important information.
          </div>
        </Form>
      </div>
    </>
  );
};

export default memo(PlaygroundForm);
