import React, { useEffect, useState } from "react";

import styles from "./resource-center.module.scss";
import {
  AppstoreOutlined,
  EyeOutlined,
  MenuOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useStore } from "@stores/root-store";
import ThreeDotsIcon from "@assets/componentIcons/ThreeDotsIcon";
import FolderIcon from "@assets/componentIcons/FolderIcon";
import { Button, Modal, Popover, Select, Upload } from "antd";
import DeleteIcon from "@assets/componentIcons/DeleteIcon";
import FileIcon from "@assets/componentIcons/FileIcon";
import { allCountries } from "@utils/helpers/allCountries";
import FilesTable from "@components/layout/superadmin-layout/pages/resource-center/components/files-table/FilesTable";
import DeleteModal from "@components/layout/superadmin-layout/pages/resource-center/components/delete-modal/DeleteModal";
import DropdownIcon from "@assets/icons/DropdownIcon";

const ResourceCenter = () => {
  const [files, setFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [isOpenFilesPage, setIsOpenFilesPage] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<string | null>("");
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [sector, setSector] = useState(null);
  const [fileContent, setFileContent] = useState<{
    name: string;
    content: string;
  }>({
    name: "",
    content: "",
  });
  const [country, setCountry] = useState(null);
  const [filesGrid, setFilesGrid] = useState<"table" | "grid">("grid");
  const [uploadKey, setUploadKey] = useState(Date.now());
  const [folders, setFolders] = useState<
    { name: string; isView: boolean; label: string }[]
  >([
    { name: "Climate Science", label: "Climate Science", isView: false },
    {
      name: "National Climate Plan",
      label: "National Climate Plans",
      isView: false,
    },
  ]);

  const [activeFolder, setActiveFolder] = useState<{
    name: string;
    isView: boolean;
    label: string;
  } | null>(null);

  const {
    user: {
      handleCreateFile,
      handleGetFiles,
      handleGetFileInfo,
      handleDeleteFile,
    },
  } = useStore(null);

  const currentFolder = folders.find((el) => el.isView);

  const createFile = async () => {
    const latestFile = newFiles;

    const formData: FormData = new FormData();
    latestFile.forEach((el) => {
      formData.append("file", el);
    });
    formData.append("folder", activeFolder?.name);
    formData.append("country", country);
    sector && formData.append("section", sector);

    await handleCreateFile(formData).then(async () => {
      await getFiles(activeFolder.name);
    });
  };

  const getFiles = async (name: string) => {
    if (currentFolder && country) {
      if (currentFolder.name === "National Climate Plan") {
        await handleGetFiles({
          country: country || "",
          section: "",
          folder: name,
        }).then((res) => {
          setFiles(res);
        });
      } else {
        if (sector) {
          await handleGetFiles({
            country: country || "",
            section: sector || "",
            folder: name,
          }).then((res) => {
            setFiles(res);
          });
        }
      }
    }
  };

  useEffect(() => {
    if (activeFolder) {
      getFiles(activeFolder.name);
    }
  }, [country, sector, activeFolder]);

  const removeFile = async () => {
    const data = {
      country: country,
      folder: activeFolder.name,
      filename: getFileName(openDeleteModal),
      section: sector || "",
    };

    await handleDeleteFile(data).then((res) => {
      setOpenDeleteModal("");

      let decodedFileName = res.message
        .replace(/^'(.*)'$/, "$1")
        .replace(/\\u([\d\w]{4})/gi, (_, match) =>
          String.fromCharCode(parseInt(match, 16)),
        );

      let fileName = decodedFileName.match(/'(.+)'/)[1];

      setFiles((prev) =>
        prev.filter((el) => {
          return getFileName(el) !== fileName;
        }),
      );
    });
  };

  const getFileInfo = async (file) => {
    const data = {
      folder: activeFolder.name,
      filename: getFileName(file),
    };

    await handleGetFileInfo(data).then((res) => {
      setFileContent({ name: getFileName(file), content: res["file content"] });
      setOpenModal(true);
    });
  };

  const navigateToFiles = (name) => {
    setActiveFolder({ name, isView: true, label: name });
    setIsOpenFilesPage(true);

    setFolders((prev) => {
      return prev.map((el) => {
        if (el.name === name) {
          return {
            name: el.name,
            label: el.label,
            isView: true,
          };
        } else {
          return el;
        }
      });
    });
  };

  const contentFolder = (name: string) => (
    <div
      className={styles.popup}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {/*<div*/}
      {/*  className={styles.popupItem}*/}
      {/*  onClick={(event) => {*/}
      {/*    event.stopPropagation();*/}
      {/*    navigateToFiles(name);*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <EyeOutlined /> <p>View</p>*/}
      {/*</div>*/}
      <div className={styles.popupItem}>
        <DeleteIcon />
        <p>Delete</p>
      </div>
    </div>
  );

  const contentFile = (file: string) => (
    <div className={styles.popup}>
      <div
        id="propover"
        className={styles.popupItem}
        onClick={() => {
          getFileInfo(file);
        }}
      >
        <EyeOutlined /> <p>View</p>
      </div>
      <div
        id="propover"
        className={styles.popupItem}
        onClick={() => {
          setOpenDeleteModal(file);
        }}
      >
        <DeleteIcon id="propover" />
        <p id="propover">Delete</p>
      </div>
    </div>
  );

  const getFileName = (str: string) => {
    const lastIndex = str?.lastIndexOf("/");

    return str?.substring(lastIndex + 1);
  };

  useEffect(() => {
    if (!openCreateModal) {
      setUploadKey(Date.now());
      setNewFiles([]);
    }
  }, [openCreateModal]);

  const handleCloseFilePage = () => {
    setIsOpenFilesPage(false);
    setFiles([]);
    setActiveFolder(null);
    setSector(null);
    setCountry(null);
    setFolders((prev) =>
      prev.map((el) => {
        return { ...el, isView: false };
      }),
    );
  };

  useEffect(() => {
    const resourceTab: HTMLElement = document.getElementById("2");

    resourceTab.addEventListener("click", () => {
      handleCloseFilePage();
    });
  }, []);

  useEffect(() => {
    const currentFolder = folders.find((el) => el.isView);

    setActiveFolder(currentFolder);
  }, [folders]);

  return (
    <div className={styles.resource}>
      <div className={styles.resourceHeader}>
        <div
          className={styles.resourceHeaderNav}
          style={{ color: isOpenFilesPage ? "rgba(0,0,0,0.5)" : "" }}
        >
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleCloseFilePage()}
          >
            Folders
          </span>{" "}
          {activeFolder ? (
            <span style={{ color: "#000" }}>{"> " + activeFolder.label}</span>
          ) : (
            ""
          )}
        </div>
        {!isOpenFilesPage && (
          <div className={styles.resourceHeaderButton}>
            <PlusOutlined color="#FFF" width={16} height={16} />
            Add folder
          </div>
        )}
      </div>
      {!isOpenFilesPage ? (
        <div style={{ display: "flex", alignItems: "center", gap: "24px" }}>
          {!!folders.length &&
            folders.map((el, i) => {
              return (
                <div
                  className={styles.resourceTabContainer}
                  key={i}
                  onClick={(event: any) => {
                    if (event.target?.id === "propover") return;
                    navigateToFiles(el.name);
                  }}
                >
                  <div className={styles.resourceTab}>
                    <div className={styles.resourceTabIcon}>
                      <FolderIcon />
                    </div>
                    <span className={styles.resourceTabText}>{el.label}</span>
                    <Popover
                      content={contentFolder(el.name)}
                      trigger="click"
                      placement="bottomLeft"
                    >
                      <span className={styles.resourceTabDots} id="propover">
                        <ThreeDotsIcon />
                      </span>
                    </Popover>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <div className={styles.resourceCountry}>
          <div>
            <div style={{ marginBottom: "8px" }}>Country</div>
            <Select
              showSearch
              placeholder="Select country"
              value={country}
              onChange={(val) => {
                setCountry(val);
              }}
              className={styles.resourceCountrySelect}
              style={{ width: "290px" }}
              suffixIcon={<DropdownIcon />}
              options={allCountries}
            />
          </div>
          {activeFolder.name !== "National Climate Plan" && (
            <div>
              <div style={{ marginBottom: "8px" }}>Sector</div>
              <Select
                showSearch
                defaultValue="Select sector"
                placeholder="Select sector"
                className={styles.resourceCountrySelect}
                style={{ width: "290px" }}
                value={sector}
                onChange={(val) => {
                  setSector(val);
                }}
                suffixIcon={<DropdownIcon />}
                options={[
                  {
                    value: "Buildings, cities, industries, and appliances",
                    label: "Buildings, cities, industries, and appliances",
                  },
                  {
                    value: "Ecosystems and ecosystem services",
                    label: "Ecosystems and ecosystem services",
                  },
                  {
                    value: "Energy generation and access",
                    label: "Energy generation and access",
                  },
                  {
                    value: "Forests and land use",
                    label: "Forests and land use",
                  },
                  {
                    value: "Health, food, and water security",
                    label: "Health, food, and water security",
                  },
                  {
                    value: "Infrastructure and built environment",
                    label: "Infrastructure and built environment",
                  },
                  {
                    value: "Livelihoods of people and communities",
                    label: "Livelihoods of people and communities",
                  },
                  {
                    value: "Transport",
                    label: "Transport",
                  },
                ]}
              />
            </div>
          )}
        </div>
      )}
      {!!isOpenFilesPage && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "40px",
          }}
        >
          <div
            style={{
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            Files
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <div
              className={styles.resourceHeaderButton}
              style={{ opacity: 1 }}
              onClick={() => setOpenCreateModal(true)}
            >
              <PlusOutlined color="#FFF" width={16} height={16} />
              Add file
            </div>
            <div className={styles.resourceSwitcher}>
              <div
                style={{
                  backgroundColor:
                    filesGrid === "table" ? "rgba(0,184,64,0.5)" : "",
                }}
                onClick={() => {
                  setFilesGrid("table");
                }}
              >
                <MenuOutlined />
              </div>
              <div
                style={{
                  backgroundColor:
                    filesGrid === "grid" ? "rgba(0,184,64,0.5)" : "",
                  borderRadius: "0 8px 8px 0",
                }}
                onClick={() => {
                  setFilesGrid("grid");
                }}
              >
                <AppstoreOutlined />
              </div>
            </div>
          </div>
        </div>
      )}
      {filesGrid === "grid" && activeFolder && (
        <div className={styles.resourceFiles}>
          {!!files?.length &&
            files.map((el) => {
              const isZip = el.endsWith(".zip");

              const fileName = getFileName(el);

              return (
                <div
                  key={el}
                  className={styles.resourceFile}
                  onClick={(event: any) => {
                    if (event.target?.id === "propover") return;
                    getFileInfo(el);
                  }}
                >
                  <Popover
                    content={contentFile(el)}
                    trigger="click"
                    placement="bottomLeft"
                    zIndex={1}
                    id="propover"
                  >
                    <div className={styles.resourceFileDots} id="propover">
                      <ThreeDotsIcon />
                    </div>
                  </Popover>
                  <div className={styles.resourceFileIcon}>
                    <FileIcon />
                  </div>
                  {isZip && <span className={styles.resourceFileZip}>ZIP</span>}
                  <div className={styles.resourceFileText}>{fileName}</div>
                </div>
              );
            })}
        </div>
      )}
      {activeFolder && filesGrid !== "grid" && (
        <FilesTable
          data={files}
          getFileName={getFileName}
          getFileInfo={getFileInfo}
          setOpenDeleteModal={setOpenDeleteModal}
        />
      )}
      <Modal
        title={fileContent.name}
        open={openModal}
        onCancel={() => setOpenModal(false)}
        width="60%"
        style={{ zIndex: 99999999999999 }}
        footer={[
          <Button
            key="submit"
            type="primary"
            style={{ backgroundColor: "#00b840" }}
            onClick={() => setOpenModal(false)}
          >
            OK
          </Button>,
        ]}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: convertLineBreaksToHTML(fileContent.content),
          }}
        />
      </Modal>
      <Modal
        title="Add file"
        open={openCreateModal}
        onCancel={() => setOpenCreateModal(false)}
        style={{ zIndex: 99999999999999 }}
        footer={[
          <Button
            key="submit"
            type="primary"
            style={{ backgroundColor: "#00b840" }}
            onClick={() => {
              setOpenCreateModal(false);
              createFile();
            }}
          >
            Add to Library
          </Button>,
        ]}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div>
            <div>Choose file</div>
            <Upload
              beforeUpload={(info) => {
                if (newFiles.length > 0) {
                  setNewFiles((prev) => [...prev, info]);
                } else {
                  setNewFiles([info]);
                }
                return false;
              }}
              multiple
              key={uploadKey}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </div>
        </div>
      </Modal>
      <DeleteModal
        closeModal={() => setOpenDeleteModal(null)}
        isOpen={!!openDeleteModal}
        username={getFileName(openDeleteModal)}
        onDeleteRecord={removeFile}
      />
    </div>
  );
};

export default ResourceCenter;

function convertLineBreaksToHTML(str: string) {
  return str.replace(/\\r\\n/g, "<br>");
}
