import React from "react";

const ArrowIconWhite = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
    >
      <path
        d="M7 14.9998L7 1.99985M1 6.99985L6.29289 1.70696C6.68342 1.31643 7.31658 1.31643 7.70711 1.70696L13 6.99985"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ArrowIconWhite;
