import { Modal } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { AddTokens } from "./AddTokens";
import { OrderComplete } from "./OrderComplete";
import { OrderSummary } from "./OrderSummary";
import { useStore } from "@stores/root-store";
import { useUserContext } from "@components/layout/superadmin-layout/SuperAdminLayout";

interface Props {
  isOpen?: boolean;
  closeModal?: () => void;
  user: any;
  upStep?: number;
}

export const AddTokensModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  user,
  upStep,
}) => {
  const { updateUserDetails } = useUserContext();
  const [step, setStep] = React.useState(upStep || 1);

  const [newPrice, setNewValue] = useState(50);

  const {
    user: { addPurchasedTokens },
  } = useStore(null);

  const tokens = newPrice * 10000;

  const handlePurchaseTokens = async () => {
    await addPurchasedTokens({
      email: user.email,
      new_purchased_tokens: upStep ? 5000000 : `${newPrice * 10000}`,
    }).then((res) => {
      if (res) {
        setStep(3);
        updateUserDetails();
      }
    });
  };

  const decreaseValue = () => {
    setNewValue((prev) => {
      if (prev - 50 < 50) return prev;

      return prev - 50;
    });
  };

  const increaseValue = () => {
    setNewValue((prev) => prev + 50);
  };

  return (
    <Wrapper>
      <Modal
        onCancel={closeModal}
        open={isOpen}
        className={"customKeywordModal"}
        width={721}
        closable={false}
        footer={null}
      >
        {!upStep && step === 1 && (
          <AddTokens
            closeModal={closeModal}
            user={user}
            tokens={tokens}
            setNewValue={setNewValue}
            newPrice={newPrice}
            decreaseValue={decreaseValue}
            increaseValue={increaseValue}
            setStep={setStep}
          />
        )}
        {((step === 3 ? false : upStep) || step === 2) && (
          <OrderSummary
            closeModal={closeModal}
            tokens={upStep ? 5000000 : tokens}
            price={upStep ? 200 : newPrice}
            setStep={setStep}
            upStep={upStep}
            user={user}
            handlePurchaseTokens={handlePurchaseTokens}
          />
        )}
        {step === 3 && (
          <OrderComplete
            closeModal={closeModal}
            user={user}
            tokens={upStep ? 5000000 : tokens}
            setStep={setStep}
          />
        )}
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .customKeywordModal {
    position: relative;

    .rc-dialog-content {
      .rc-dialog-close {
        color: #fff;
        font-size: 40px;
        right: -50px;
        top: -14px;
      }
    }
  }
`;
