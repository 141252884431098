import React from "react";

import styles from "./admin-header.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserContext } from "@components/layout/superadmin-layout/SuperAdminLayout";
import { resetStore } from "@stores/root-store";
import { constRoute } from "@utils/route";

const AdminHeader = () => {
  const location = useLocation();

  const userData = JSON.parse(localStorage.getItem("userData")) || "";
  const link =
    location.pathname.split("/")[location.pathname.split("/").length - 1];
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");

  const navigate = useNavigate();
  const userDetails = useUserContext();

  const onLogOut = () => {
    navigate(constRoute.masteradminLogin);
    resetStore();
    localStorage.removeItem("AllAnswers");
    localStorage.removeItem("token");
    localStorage.removeItem("theme");
    localStorage.removeItem("email");
    localStorage.removeItem("userData");
    localStorage.removeItem("superadmin");
  };



  return (
    <div className={styles.header}>
      <h1 className={styles.headerTitle}>
        {email && userDetails ? userDetails?.name : link.replace("-", " ") === "dashboard" ? "Monitoring" : link.replace("-", " ")}
      </h1>
      <div className={styles.headerWrap}>
        {/*<div className={styles.headerButton} onClick={() => openModal()}>*/}
        {/*  Invite Users*/}
        {/*</div>*/}
        <div onClick={() => onLogOut()} style={{ cursor: "pointer" }}>
          Log out
        </div>
        <div className={styles.headerUserAvatar}>
          {userData?.name &&
            `${userData?.name.split(" ")[0][0].toUpperCase() || ""}${
              (userData?.name.split(" ").length > 1 &&
                userData?.name.split(" ")[1][0].toUpperCase()) ||
              ""
            }`}
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
