import React, { FC, Fragment, useEffect, useState } from "react";
import styles from "./project-table.module.scss";
import done from "../../../../../assets/icons/done-icon.svg";
import inProgress from "../../../../../assets/icons/in-progress-icon.svg";
import FileIcon from "@assets/icons/FileIcon";
import classNames from "classnames";
import ArrowIcon from "@assets/icons/ArrowIcon";
import BaseLoader from "@assets/loaders/base-loader/BaseLoader";

type Props = {
  data: any[];
  title: string;
  fileIconColor?: string;
  actions: (data: any) => JSX.Element;
  loadingProjectData: boolean;
  setEditLoading: (data: any) => void;
  handleGetData: (data: any) => void;
  userType: string;
};

const ProjectsTable: FC<Props> = ({
  data,
  title,
  actions,
  setEditLoading,
  handleGetData,
  loadingProjectData,
  fileIconColor,
  userType,
}) => {
  const [pages, setPages] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const [hoveredElementId, setHoveredElementId] = useState(null);

  useEffect(() => {
    const pagesAmount = data.length;

    if (pagesAmount > 7) {
      setPages(data.slice(activePage * 7, activePage * 7 + 7));
    }
  }, [data, activePage]);

  const type = data[0]?.type;

  return (
    <div
      className={styles.table}
      style={{
        gridTemplateRows: `55px 45px repeat(${
          loadingProjectData
            ? 1
            : pages.length
            ? pages.length > 7
              ? 7
              : pages.length
            : data.length > 7
            ? 7
            : data.length
        }, 65px) ${pages.length > 0 ? "65px" : 0}`,
        backgroundColor:
          userType !== "enterprise" &&
          (type === "climate rationale" || type === "grading")
            ? "#d8d8d8"
            : "",
      }}
    >
      <p className={styles.tableTitle}>{title}</p>

      <p className={styles.tableHead}>Project Name</p>
      <p className={styles.tableHead}>Last Updated</p>
      <p className={styles.tableHead}>Status</p>
      <p className={styles.tableHead}>Actions</p>

      {loadingProjectData ? (
        <div className={styles.tableLoader}>
          <BaseLoader color="#00B840" width="35px" height="35px" />
        </div>
      ) : (
        (data.length > 7 ? pages : data).map((project, i) => {
          return (
            <Fragment key={project.id}>
              <div
                style={{
                  backgroundColor:
                    hoveredElementId === i
                      ? userType !== "enterprise" &&
                        (type === "climate rationale" || type === "grading")
                        ? ""
                        : "#f3f3f3"
                      : "",
                }}
                className={classNames(styles.tableItem, styles.tableItemName)}
                onClick={() => {
                  if (
                    userType !== "enterprise" &&
                    (project.type === "climate rationale" ||
                      project.type === "grading")
                  )
                    return;
                  setEditLoading(project?.projectName);
                  handleGetData(project);
                }}
                onMouseEnter={() => setHoveredElementId(i)}
                onMouseLeave={() => setHoveredElementId(null)}
              >
                <div>
                  <FileIcon color={fileIconColor} />
                </div>
                <p>{project.projectName}</p>
              </div>
              <p
                className={styles.tableItem}
                onMouseEnter={() => setHoveredElementId(i)}
                onMouseLeave={() => setHoveredElementId(null)}
                style={{
                  backgroundColor:
                    hoveredElementId === i
                      ? userType !== "enterprise" &&
                        (type === "climate rationale" || type === "grading")
                        ? ""
                        : "#f3f3f3"
                      : "",
                }}
              >
                {project.status[1] || "----"}
              </p>
              <div
                className={styles.tableItem}
                onMouseEnter={() => setHoveredElementId(i)}
                onMouseLeave={() => setHoveredElementId(null)}
                style={{
                  backgroundColor:
                    hoveredElementId === i
                      ? userType !== "enterprise" &&
                        (type === "climate rationale" || type === "grading")
                        ? ""
                        : "#f3f3f3"
                      : "",
                }}
              >
                <img
                  src={
                    +project?.status[0]?.replaceAll("%", "") === 100
                      ? done
                      : inProgress
                  }
                  alt="status"
                />
              </div>
              <p
                className={styles.tableItem}
                onMouseEnter={() => setHoveredElementId(i)}
                onMouseLeave={() => setHoveredElementId(null)}
                style={{
                  backgroundColor:
                    hoveredElementId === i
                      ? userType !== "enterprise" &&
                        (type === "climate rationale" || type === "grading")
                        ? ""
                        : "#f3f3f3"
                      : "",
                }}
              >
                {actions(project)}
              </p>
            </Fragment>
          );
        })
      )}
      {!loadingProjectData && pages.length > 0 && (
        <div className={styles.tablePagination}>
          <div
            className={styles.tablePaginationControlls}
            onClick={() =>
              setActivePage((prev) => {
                if (prev === 0) return prev;
                return prev - 1;
              })
            }
          >
            <div style={{ transform: "rotate(180deg)", height: "16px" }}>
              <ArrowIcon />
            </div>
            <p>Prev</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            {!!pages.length &&
              new Array(
                data.length % 7 !== 0
                  ? Math.floor(+data.length / 7) + 1
                  : data.length / 7,
              )
                .fill(0)
                .map((_, i) => {
                  return (
                    <div
                      style={{
                        color: activePage === i ? "#00B840" : "",
                        padding: "10px",
                      }}
                      onClick={() => setActivePage(i)}
                    >
                      {i + 1}
                    </div>
                  );
                })}
          </div>
          <div
            className={styles.tablePaginationControlls}
            onClick={() =>
              setActivePage((prev) => {
                if (prev >= pages.length) return prev;
                return prev + 1;
              })
            }
          >
            <p>Next</p>

            <ArrowIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectsTable;
