import React from "react";

const FileIcon = () => {
  return (
    <svg
      width="55"
      height="64"
      viewBox="0 0 55 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0717773 3.42857C0.0717773 1.53502 1.6068 0 3.50035 0H34.3575L54.9289 20.5714V60.5714C54.9289 62.465 53.3939 64 51.5004 64H3.50035C1.6068 64 0.0717773 62.465 0.0717773 60.5714V3.42857Z"
        fill="url(#paint0_linear_6301_7221)"
      />
      <path
        d="M34.3575 17.1429V0L54.9289 20.5714H37.7861C35.8925 20.5714 34.3575 19.0364 34.3575 17.1429Z"
        fill="#21835A"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6301_7221"
          x1="27.5004"
          y1="0"
          x2="27.5004"
          y2="64"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#33B27C" />
          <stop offset="1" stopColor="#289B69" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FileIcon;
