import React from "react";
import DashboardCard from "@components/layout/admin-layout/components/dashboardCard/DashboardCard";
import styles from "./users.module.scss";
import UserTable from "@components/layout/admin-layout/components/userTable/UserTable";
import InviteModal from "@components/layout/admin-layout/components/inviteModal/InviteModal";
import { useInviteModal } from "@components/ModalContext/InviteModalContext";
import { useStore } from "@stores/root-store";
import { useLocation } from "react-router-dom";
import UserDetails from "@components/layout/superadmin-layout/pages/userDetails/UserDetails";
import {
  useCartDataContext,
  useUserContext,
} from "@components/layout/superadmin-layout/SuperAdminLayout";

const Users = () => {
  const { isModalOpen } = useInviteModal();
  const cardData = useCartDataContext();

  const {
    user: { isLoadingMasterAdmin },
  } = useStore(null);
  const { userDetails, updateUserDetails } = useUserContext();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");

  if (isLoadingMasterAdmin) {
    return <></>;
  }

  if (email && userDetails) {
    return (
      <>
        <UserDetails
          userDetails={userDetails}
          updateUserDetails={updateUserDetails}
        />
        {isModalOpen && <InviteModal />}
      </>
    );
  }

  return (
    <div className={styles.dashboard}>
      {isModalOpen && <InviteModal />}
      <div className={styles.dashboardCards}>
        <DashboardCard
          type="Total Registered Users"
          count={cardData && cardData["total users"]}
          size="small"
        />
        <DashboardCard
          type="Active Users"
          count={cardData && cardData["active users"]}
          size="small"
        />
        <DashboardCard
          type="Total Tokens Consumed"
          count={cardData && cardData["total tokens"]}
          size="small"
        />
      </div>
      <UserTable />
    </div>
  );
};

export default Users;
