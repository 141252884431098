import React from "react";

const EmailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2.00065 3.33317L7.18206 7.36316C7.66354 7.73764 8.33776 7.73764 8.81924 7.36316L14.0007 3.33317M3.46732 13.3332H12.534C13.2807 13.3332 13.6541 13.3332 13.9393 13.1878C14.1902 13.06 14.3942 12.856 14.522 12.6052C14.6673 12.3199 14.6673 11.9466 14.6673 11.1998V4.79984C14.6673 4.0531 14.6673 3.67973 14.522 3.39452C14.3942 3.14363 14.1902 2.93966 13.9393 2.81183C13.6541 2.6665 13.2807 2.6665 12.534 2.6665H3.46732C2.72058 2.6665 2.34721 2.6665 2.062 2.81183C1.81111 2.93966 1.60714 3.14363 1.47931 3.39452C1.33398 3.67973 1.33398 4.0531 1.33398 4.79984V11.1998C1.33398 11.9466 1.33398 12.3199 1.47931 12.6052C1.60714 12.856 1.81111 13.06 2.062 13.1878C2.34721 13.3332 2.72058 13.3332 3.46732 13.3332Z"
        stroke="#2E2E2E"
        stroke-width="2"
      />
    </svg>
  );
};

export default EmailIcon;
