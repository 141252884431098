export const countWords = function (inputString: string): boolean {
  return true;
};

export const wordCountValidation =
  (maxWords = 1000, maxChars = 5000) =>
  (rule, value, callback) => {
    const wordCount = countWords(value);

    // if (value.length > 24000) {
    //   callback("You have exceeded the maximum length for this field");
    // } else {
    //   callback();
    // }

    callback();
  };
