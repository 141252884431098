import React, { FC } from "react";

type Props = {
  color?: string;
  width?: string;
  height?: string;
};

const BaseLoader: FC<Props> = ({ color, height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        margin: "auto",
        display: "block",
        shapeRendering: "auto",
        background: "transparent",
      }}
      width={width || "22px"}
      height={height || "22px"}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke={color || "#FFFFFF"}
        strokeWidth="13"
        r="38"
        strokeDasharray="179.0707812546182 61.690260418206066"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  );
};

export default BaseLoader;
