import GoBackImg from "@assets/images/GoBack.svg";
import { Button } from "antd";
import { observer } from "mobx-react";
import { memo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from "./style.module.scss";

interface Props {
  onClick?: any;
  className?: any;
  onClickForFooterGo?: any;
  isTooltip?: any;
  isSubmitting?: boolean;
}
const GoBack: React.FC<Props> = observer(
  ({ onClick, isSubmitting, ...props }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const tooltipShow = localStorage.getItem("tooltipShow");
    const [isTooltipShow, setIsTooltipShow] = useState(false);

    const isEvaluateProject = location?.pathname?.includes("evaluate");
    const isAdvisorPage = location?.pathname?.includes("/advisor");
    const isTheoryOfChangePage =
      location?.pathname.includes("/theory-of-change/");
    const isRationalAdvisor = location?.pathname.includes("/rational-advisor/");

    const goBackHandler = () => {
      // notification.success("Save and Quit");
      if (props?.onClickForFooterGo) {
        props?.onClickForFooterGo();
      } else {
        navigate(`${onClick}`);
      }
    };

    const handleTooltipClose = () => {
      setIsTooltipShow(false);
      localStorage.setItem("tooltipShow", "true");
    };

    const saveQuitTooltipData = () => {
      return (
        <div style={{ paddingLeft: 11, paddingRight: 11, color: "#000000" }}>
          <p
            style={{
              margin: 0,
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "30px",
              color: "#000000"
            }}
          >
            Important!
          </p>
          <p
            style={{
              marginTop: 4,
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: 14
            }}
          >
            This button will return you to the previous input screen. Pressing
            the ‘Submit’ button will generate a new output and delete the
            current one.
          </p>
          <div
            style={{
              marginTop: 12,
              padding: "12px 20px",
              marginLeft: -19,
              marginRight: -19,
              marginBottom: -6,
              border: "1px solid #E0E0E0"
            }}
          >
            <Button
              onClick={handleTooltipClose}
              style={{
                backgroundColor: "#00B840",
                width: 66,
                padding: " 6px 20px 10px 15px",
                height: 34,
                color: "#FFFFFF"
              }}
            >
              Got it
            </Button>
          </div>
        </div>
      );
    };
    const tooltipHandler = visible => {
      if (tooltipShow) return;
      setIsTooltipShow(visible);
    };

    return (
      // <div className={style.mainWrraper}>
      //   {isAdvisorPage ||
      //   isTheoryOfChangePage ||
      //   isRationalAdvisor ||
      //   isEvaluateProject ? (
      //     <Tooltip
      //       open={isTooltipShow && location.pathname.includes("result")}
      //       onOpenChange={tooltipHandler}
      //       overlayInnerStyle={{ minWidth: 294 }}
      //       color="#FFFF"
      //       title={saveQuitTooltipData}
      //     >
      //       <img
      //         style={{
      //           opacity: isSubmitting ? "10%" : "",
      //           cursor: isSubmitting ? "not-allowed" : ""
      //         }}
      //         onClick={() => {
      //           goBackHandler();
      //         }}
      //         src={GoBackImg}
      //         alt="ws"
      //         className={
      //           props?.className ? props?.className : style.saveAndQuitImg
      //         }
      //       />
      //     </Tooltip>
      //   ) : (
      <img
        onClick={goBackHandler}
        src={GoBackImg}
        alt="ws"
        style={{
          opacity: isSubmitting ? "50%" : "",
          cursor: isSubmitting ? "not-allowed" : "pointer"
        }}
        className={props?.className ? props?.className : style.saveAndQuitImg}
      />
      //   )}
      // </div>
    );
  }
);

export default memo(GoBack);
