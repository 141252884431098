import React, {FC, useEffect} from "react";
import {Modal} from "antd";
import {createPortal} from "react-dom";
import './style.scss'
interface ModalSaveProps {
    isOpen: boolean
    handleClose: () => void
    handleSubmit: () => void
}
export const ModalSave:FC<ModalSaveProps> = ({isOpen, handleClose, handleSubmit}) => {

    return <>
        {createPortal(
            <Modal
                title="Are you sure you want to save & quit?"
                centered
                open={isOpen}
                onOk={handleSubmit}
                onCancel={handleClose}
                cancelText={'No, cancel'}
                okText={'Yes, save & quit'}
                destroyOnClose={true}
                className={'saveQuitModal'}
            >
                <p>The progress you have made so far will be saved, and you can come back to it by going into the <b>My Projects</b> page.</p>

            </Modal>,
            document.getElementById('root')
        )

        }
    </>
}