import { observer } from "mobx-react"; 
import style from "./style.module.scss";
import LeftArrow from "@assets/icons/left-arrow.png";
import React, { memo, useState } from "react";
import { Button, Checkbox } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { constRoute } from "@utils/route";
import { useStore } from "@stores/root-store";
import { notification } from "@utils/notifications";
import SaveQuit from "@components/common-components/save-quit";
import GoBack from "@components/common-components/go-back";

const ImportantProjectInfo = observer(() => {
  const navigate = useNavigate()
  const {
    user: { projectSave, isLoadingProjectSave, resetProjectData },
  } = useStore(null);
  const [error, setError] = useState(false);
  const {state} = useLocation();
  const nextSubmitHandler = async () => {
      resetProjectData();
      const payload = {
        project_name: state?.projectName,
        functionality: "concept note",
      };
      const response = await projectSave(payload, navigate);
      if (response?.message?.includes("project saved successfully")) {
        localStorage.removeItem('AllAnswers')
        localStorage.removeItem('allResults')
        navigate(constRoute?.contextAndBaselineForm);
      }
  };
  return (
    <div>
    <div className={style.mainContainer}>
      <div className={style.containerWrapper}>
        <div className={style.container}>
          <h2 className={style.hTwo}>Important</h2>
          <div className={style.mainPdiv}> 
            <p className={style.pTagOne}>
              The principal benefit of utilizing Climate Finance Copilot for this task is its ability to present examples of narratives that fully conform to the GCF scoring manual and are more likely to resonate with the GCF evaluators.

            </p>

            <p className={style.pTagTwo}>
              There may be <b><em>references that are partially or totally inaccurate.</em></b> It is highly recommended that you verify the veracity of each literature source generated. ​We are working diligently to eliminate the generation of inaccurate references, however, this is a know AI language model challenge. For now, ​please check all cited information.

            </p>
            <p className={style.pTagThree}>
              The quality of the narratives generated ​by this web application is proportional to the quality of your input. If you omit information that the GCF requires, this tool will provide notional answers to ensure that the narratives generated are comprehensive from the perspective of the GCF.
            </p>
          </div>
          {error && <p style={{color:'red', marginLeft:40, paddingBottom:10, marginTop: '4px'}}>Please Check the checkbox </p>}
        </div>
        <div className={style.nextButtonDiv}>
          <Button loading={isLoadingProjectSave} disabled={isLoadingProjectSave} onClick={nextSubmitHandler}  className={style.nextButton}>
            Continue
          </Button>
        </div>
      </div>
    </div>
    <div className={style.btnDiv}>
        <div className={style.twoBtnDiv} >
        <div className={style.goBackBtnDiv}>
          <GoBack onClick={constRoute?.projectName + '/draft'} className={style.goBtn} />
          </div>
        </div>
      </div>
    </div>
  );
});

export default memo(ImportantProjectInfo);
