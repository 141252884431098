import React, { FC } from "react";
import styles from "./dashboard-card.module.scss";

type Props = {
  size?: "big" | "small";
  count: string | number;
  color?: string;
  type: string;
  lastUpdated?: number;
  isBlur?: boolean;
};

const DashboardCard: FC<Props> = ({
  size = "big",
  count = 0,
  type,
  lastUpdated,
  isBlur,
  color,
}) => {
  return (
    <div
      className={styles.card}
      style={{
        height: size === "big" ? "230px" : "180px",
        opacity: isBlur ? "50%" : "100%",
      }}
    >
      <p className={styles.cardCount} style={{ color: color || "" }}>
        {count}
      </p>
      <p className={styles.cardType}>{type}</p>
      {lastUpdated && (
        <p className={styles.cardLastUpdated}>
          No changes over the last {lastUpdated} days
        </p>
      )}
    </div>
  );
};

export default DashboardCard;
