import { observer } from "mobx-react";
import style from "./style.module.scss";
import React, { memo, useEffect, useState } from "react";
import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import { CommonInput } from "@components/common-components/input";
import { validateMessages } from "@utils/json-data";
import { useNavigate } from "react-router-dom";
import { constRoute } from "@utils/route";
import { useStore } from "@stores/root-store";
import CommonImportantSideBar from "../importantSideBar/common-important-sidebar";
import CommonFooterButton from "../commonfooterbutton";
import useWindowSize from "@utils/hooks/useWindowSize";
import { wordCountValidation } from "@utils/helpers/wordCountValidator";
import { wordLimits } from "@components/pages/project/select-function/which-to-do/select-one/project-name/important-project-info/utils/wordLimits";
import { useTokensContext } from "@components/layout/main-layout/private-layout";

const ProjectDescriptionForm = observer(() => {
  const [form] = useForm();
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const getProjectName = localStorage.getItem("projectName");
  const [initialQuestions, setInitialQuestions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const screenWidth = useWindowSize().width;
  const {
    user: {
      getProjectNameData,
      setConceptNoteLoading,
      getLoadingConceptNote,
      conceptNote,
      getSingleProjectData,
      loadingSingleProjectData,
      handleCheckInputTokensValid,
    },
  } = useStore(null);
  const { setTokens } = useTokensContext();
  const [projectName] = useState(JSON.parse(getProjectNameData)?.project_name);
  const [allInputValues, setAllInputValues] = useState({
    q6: "",
  });
  const [isValid, setIsValid] = useState(true);

  const checkLengthOfInput = async () => {
    const data = {
      functionality: "concept note",
      section: "B_3_16",
      questions: { ...allInputValues },
      project_name: projectName || getProjectName,
    };

    await handleCheckInputTokensValid(data).then((res) => {
      const result = JSON.parse(res.isValid);
      setIsValid(result);
    });
  };

  useEffect(() => {
    checkLengthOfInput();
  }, [allInputValues]);

  useEffect(() => {
    if (localStorage.getItem("AllAnswers") === null) {
      localStorage.setItem("AllAnswers", JSON.stringify([{ q6: "" }]));
    }
  }, []);

  const onFormSubmit = async (values) => {
    localStorage.setItem("isSocketOn", "1");
    const getAnswers = JSON.parse(localStorage.getItem("AllAnswers"));
    const addMoreAnswers = getAnswers?.map((item) => {
      return {
        ...item,
        q6: values?.q6,
      };
    });
    const question = {
      q6: values?.q6 || "",
    };
    localStorage.setItem("AllAnswers", JSON.stringify(addMoreAnswers));
    const getReultsfromls = JSON.parse(localStorage.getItem("totalResults"));
    const addResults =
      getReultsfromls &&
      getReultsfromls?.map((item) => {
        return {
          ...item,
          result3: "",
        };
      });
    localStorage.setItem("totalResults", JSON.stringify(addResults));

    // const payload = {
    //   section: `B_3_16`,
    //   questions: question,
    //   project_name: projectName ||getProjectName || ''
    // }
    // localStorage.setItem('conceptPayload', JSON.stringify(payload))
    // const response = await conceptNote(payload, navigate)
    navigate(
      constRoute?.projectResultsGcfResults,
      // ,  { state: { response: response?.response} }
    );
    // navigate(constRoute?.projectResultsGcfResults);
    // const getReultsfromls = JSON.parse(localStorage.getItem('allResults'));
    //  const addResults =  getReultsfromls && getReultsfromls?.map((item) => {
    //     return {
    //       ...item,
    //       result3: response?.response || ""
    //     }
    //   })
    // localStorage.setItem('allResults', JSON.stringify(addResults))
  };
  const handleSave = () => {
    setConceptNoteLoading(false);
    // notification.success("Save and Quit");
    navigate(constRoute?.home);
  };
  const handleback = () => {
    localStorage.setItem("isSocketOn", "0");
    setConceptNoteLoading(false);
    navigate(constRoute?.projectDescriptionResults);
  };
  const getAnswers = JSON.parse(localStorage.getItem("AllAnswers"));

  useEffect(() => {
    const data = {
      section: "B_3_16",
      project_name: projectName ?? localStorage.getItem("projectName"),
      functionality: "concept note",
    };
    localStorage.setItem("data-for-tokens", JSON.stringify(data));
    getSingleProjectData(data).then((el) => {
      setTokens(el["tokens_remaining/tokens_purchased"]);
      setInitialQuestions(el);
      setIsLoading(false);
      form.setFieldsValue(el.questions);
    });
  }, []);

  return (
    <div className={style.mainContainer}>
      <div className={style.barContentContainer}>
        <CommonImportantSideBar
          title={"Please Note: "}
          fristPara={` 1. Provide quantitative and qualitative information on the potential impact of project/programme, e.g. tonnes of CO2e avoided or reduced (mitigation projects), number of beneficiaries, number of people affected by climate impacts (adaptation projects). `}
          secondParagraph={`2. If an adaptation project, be as specific as possible about who the beneficiaries are and how they will benefit. `}
          thirdParagraph={`3. Describe hot the “the project is expected to have a transformative impact on the sector’s adaptation to climate change” and then offer reason/s for this assessment. `}
        />
        {/* {show && (
        <div className={style.layoutDiv}>
          <div className={style.siderStyle}>
            <div className={style.sideInnerDiv}>
              <div className={style.importantDiv}>
                <p className={style.pTagFour}>Quick Tips</p>
                <button className={style.btnClass}  onClick={() => setShow(!show)}>
                  <img
                    src={CloseIcon}
                    className={style.closeIconImg}
                    alt="fd"
                  />
                </button>
              </div>
              <div className={style.pTageGroup}>
                <p className={style.pTagFive}>
                  Provide quantitative and qualitative information on the
                  potential impact of project/programme, e.g. tonnes of CO2e
                  avoided or reduced, number of beneficiaries, number of people
                  affected by climate impacts. (Be as specific as possible about
                  who the beneficiaries are.)
                </p>
              </div>
              <Divider />
            </div>
          </div>
        </div>
)} */}
        <div
          className={style.contentContainer}
          style={{ cursor: isLoading ? "wait" : "" }}
        >
          <div className={style.innerContentContainer}>
            <h1>Please Fill In the Blanks Below:</h1>

            <div className={style.dataContentBox}>
              <Form
                className={style.formData}
                form={form}
                autoComplete="false"
                onFinish={onFormSubmit}
                validateMessages={validateMessages}
                layout="vertical"
                initialValues={{
                  q6:
                    initialQuestions?.questions?.q6 ||
                    (getAnswers && getAnswers[0]?.q6) ||
                    "",
                }}
              >
                <Form.Item
                  label={
                    <span>
                      6. Briefly discuss the project’s impact potential.
                      <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name={"q6"}
                  rules={[
                    { required: true, message: "This field is required" },
                    {
                      validator: wordCountValidation(),
                    },
                  ]}
                >
                  <CommonInput
                    inputType="textarea"
                    placeholder="In this box, provide an estimate of the expected impacts aligned with the GCF investment criteria: Impact Potential, Paradigm Shift, Sustainable Development, Needs of Recipients, Country Ownership, and Efficiency and Effectiveness."
                    onChange={(e) =>
                      setAllInputValues((prev) => {
                        return { ...prev, q6: e.target.value };
                      })
                    }
                    disabled={isLoading}
                    autoSizeCheck={{ minRows: 18, maxRows: 20 }}
                    className={style.fontSizeInput}
                  />
                </Form.Item>
                {/* <Form.Item
                  label="5. What is the name of the Accredited Entity(ies) and describe the implementation arrangements with the executing entity(ies) and implementing partners."
                  name={"ies"}
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <CommonInput
                    inputType="textarea"
                    autoSizeCheck={{ minRows: 7, maxRows: 7 }}

                    className={style.emailInput}
                  />
                </Form.Item> */}
              </Form>
              {!isValid && (
                <div
                  style={{
                    color: "red",
                    fontSize: "14px",
                    position: "relative",
                    top: "45px",
                  }}
                >
                  You have exceeded the maximum length for this field
                </div>
              )}

              <div
                style={{
                  paddingTop: "5px",
                  position: "relative",
                  top: !isValid ? "40px" : "30px",
                }}
              >
                The max word limit for this page is {wordLimits[2]} words
              </div>
            </div>
            {/* <div className={style.footerButtonsDiv}>
              <Form form={form} onFinish={onFormSubmit}>
                <Button loading={getLoadingConceptNote} disabled={getLoadingConceptNote} htmlType="submit" className={style.nextButton}>
                  Submit
                </Button>
              </Form>
              <div className={style.btnDiv}>
                <div className={style.twoBtnDiv}>
                  <button
                    className={style.goBtn}
                    onClick={() => navigate(constRoute?.projectDescriptionResults)}
                  >
                    {" "}
                    <img src={LeftArrow} alt="left-arrow" /> Go Back
                  </button>
                  <button
                    onClick={() => {
                      notification.success("Save and Quit");
                      navigate(constRoute?.home);
                    }}
                    className={style.saveBtn}
                  >
                    Save & Quit
                  </button>
                </div>
              </div>
            </div> */}
          </div>
          <CommonFooterButton
            isLoadingSubmit={getLoadingConceptNote}
            handleSubmit={onFormSubmit}
            handlegoback={handleback}
            handleSaveAndQuit={handleSave}
            disableSubmit={!isValid || isLoading}
            form={form}
            // handleQuickNext={constRoute?.projectResultsGcfResults}
            // customStyle={{position:(screenWidth > 770) ? 'absolute' : 'inherit'}}
          />
        </div>
      </div>
    </div>
  );
});

export default memo(ProjectDescriptionForm);
