import { observer } from "mobx-react";
import style from "./style.module.scss";
import React, { memo, useEffect, useState } from "react";
import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import { CommonInput } from "@components/common-components/input";
import { validateMessages } from "@utils/json-data";
import { useNavigate } from "react-router-dom";
import { constRoute } from "@utils/route";
import { useStore } from "@stores/root-store";
import CommonImportantSideBar from "../importantSideBar/common-important-sidebar";
import CommonFooterButton from "../commonfooterbutton";
import useWindowSize from "@utils/hooks/useWindowSize";
import { wordLimits } from "@components/pages/project/select-function/which-to-do/select-one/project-name/important-project-info/utils/wordLimits";
import { useTokensContext } from "@components/layout/main-layout/private-layout";

const ProjectDescriptionForm = observer(() => {
  const [form] = useForm();
  const [initialQuestions, setInitialQuestions] = useState(null);

  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const screenWidth = useWindowSize().width;
  const {
    user: {
      getProjectNameData,
      setConceptNoteLoading,
      getLoadingConceptNote,
      conceptNote,
      getSingleProjectData,
      loadingSingleProjectData,
      handleCheckInputTokensValid,
    },
  } = useStore(null);
  const { setTokens } = useTokensContext();
  const [projectName] = useState(JSON.parse(getProjectNameData)?.project_name);
  const [isLoading, setIsLoading] = useState(true);
  const [allInputValues, setAllInputValues] = useState({
    q12: "",
  });
  const [isValid, setIsValid] = useState(true);
  const getProjectName = localStorage.getItem("projectName");
  const dummyArrayOfImportantSIdeBarList = [
    `Purpose and objectives of the engagement plan.`,
    `Guiding principles of the engagement plan.`,
    `Commitments made to stakeholders and success indicators of the engagement plan.`,
    `Organizational roles within the engagement plan and responsibilities related to stakeholder engagement activity `,
  ];
  const dummyArrayOfImportantSIdeBarListTwo = [
    `Political, policy, and social risks; Administrative risks (e.g. permitting delays, denial or repeal; forced relocation); `,
    `Policy / regulatory risks (e.g. change of support to tariffs or level of subsidization)  and risk of social opposition and risk of violence (e.g. social resistance, protests from local citizen, reputational risk).`,
  ];

  const checkLengthOfInput = async (value: string) => {
    const data = {
      functionality: "concept note",
      section: "B_4_64",
      questions: { ...allInputValues },
      project_name: projectName || getProjectName,
    };

    await handleCheckInputTokensValid(data).then((res) => {
      const result = JSON.parse(res.isValid);
      setIsValid(result);
    });
  };

  useEffect(() => {
    const value = Object.values(allInputValues).join(" ");
    checkLengthOfInput(value);
  }, [allInputValues]);

  useEffect(() => {
    if (localStorage.getItem("AllAnswers") === null) {
      localStorage.setItem("AllAnswers", JSON.stringify([{ q12: "" }]));
    }
  }, []);
  const onFormSubmit = async (values) => {
    localStorage.setItem("isSocketOn", "1");
    const getAnswers = JSON.parse(localStorage.getItem("AllAnswers"));
    const addMoreAnswers = getAnswers?.map((item) => {
      return {
        ...item,
        q12: values?.q12,
      };
    });
    const question = {
      q12: values?.q12 || "",
    };
    localStorage.setItem("AllAnswers", JSON.stringify(addMoreAnswers));
    const getReultsfromls = JSON.parse(localStorage.getItem("totalResults"));
    const addResults =
      getReultsfromls &&
      getReultsfromls?.map((item) => {
        return {
          ...item,
          result9: "",
        };
      });
    localStorage.setItem("totalResults", JSON.stringify(addResults));
    // const payload = {
    //   section: `B_4_64`,
    //   questions: question,
    //   project_name: projectName ||getProjectName|| ''
    // }
    // localStorage.setItem('conceptPayload', JSON.stringify(payload))
    // const response = await conceptNote(payload, navigate)
    // if(response?.response){
    navigate(
      constRoute?.ndaAeResults72,
      // ,  { state: { response: response?.response} }
    );
    // const getReultsfromls = JSON.parse(localStorage.getItem('allResults'));
    // const addResults =  getReultsfromls && getReultsfromls?.map((item) => {
    //   return {
    //     ...item,
    //     result9: response?.response || ""
    //   }
    // })
    // localStorage.setItem('allResults', JSON.stringify(addResults))
    // }
  };
  const handleSave = () => {
    setConceptNoteLoading(false);
    // notification.success("Save and Quit");
    navigate(constRoute?.home);
  };
  const handleback = () => {
    localStorage.setItem("isSocketOn", "0");
    setConceptNoteLoading(false);
    navigate(constRoute?.projectResultsGcfResults64);
  };
  const getAnswers = JSON.parse(localStorage.getItem("AllAnswers"));

  useEffect(() => {
    const data = {
      section: "B_4_64",
      project_name: projectName ?? localStorage.getItem("projectName"),
      functionality: "concept note",
    };

    getSingleProjectData(data).then((el) => {
      setTokens(el["tokens_remaining/tokens_purchased"]);
      setInitialQuestions(el);
      setIsLoading(false);
      form.setFieldsValue(el.questions);
    });
  }, []);

  return (
    <div className={style.mainContainer}>
      <div className={style.barContentContainer}>
        <CommonImportantSideBar
          title={"Quick Tips"}
          fristPara={` 1. With this questions, the GCF is seeking to know “to what extent does the Stakeholder Engagement Plan articulate a well-defined stakeholder engagement strategy.” `}
          isList={true}
          // customStyle={{height:'80vh'}}
          listArray={dummyArrayOfImportantSIdeBarList}
          thirdParagraph={`2. The plan should include:`}
          // listArrayTwo={dummyArrayOfImportantSIdeBarListTwo}
        />

        {/* {show && (
        <div className={style.layoutDiv}>
          <div className={style.siderStyle}>
            <div className={style.sideInnerDiv}>
              <div className={style.importantDiv}>
                <p className={style.pTagFour}>Quick Tips</p>
                <button className={style.btnClass} onClick={() => setShow(!show)}>
                  <img
                    src={CloseIcon}
                    className={style.closeIconImg}
                    alt="fd"
                  />
                </button>
              </div>
              <div className={style.pTageGroup}>
                <p className={style.pTagFive}>
                  If you want for Climate Finance Copilot to offer a notional
                  engagement mode for this project/programme, then leave the
                  input box blank. 
                </p>
                // this text is not showing in this page
                <p className={style.pTagSix}>
                  Highlight stakeholder engagement with national and local
                  stakeholders, e.g. national ministries, the Accredited Entity,
                  the National Designated Authority, as well as civil society,
                  academia and other stakeholders for the particular project.
                </p>
                <p className={style.pTagSeven}>
                  Be sure to include the number of beneficiaries the project is
                  expected to help.
                </p>
                // uper code is comment that not show on this page  these two paragreaph
              </div>
              <Divider />
            </div>
          </div>
        </div>
      )} */}
        <div
          className={style.contentContainer}
          style={{ cursor: isLoading ? "wait" : "" }}
        >
          <div className={style.innerContentContainer}>
            <h1>Please Fill In the Blanks Below:</h1>

            <div className={style.dataContentBox}>
              <Form
                className={style.formData}
                form={form}
                autoComplete="false"
                onFinish={onFormSubmit}
                validateMessages={validateMessages}
                layout="vertical"
                initialValues={{
                  q12:
                    initialQuestions?.questions?.q12 ||
                    (getAnswers && getAnswers[0]?.q12) ||
                    "",
                }}
              >
                <Form.Item
                  label={
                    <span>
                      {
                        " 12. Please describe how engagement among the NDA, AE and/or other relevant stakeholders in the country has taken place.  "
                      }
                      <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name={"q12"}
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <CommonInput
                    inputType="textarea"
                    onChange={(e) =>
                      setAllInputValues((prev) => {
                        return { ...prev, q12: e.target.value };
                      })
                    }
                    disabled={isLoading}
                    autoSizeCheck={{ minRows: 18, maxRows: 20 }}
                    // placeholder="Leave blank if you want Climate Finance Co-pilot to supply suggested narratives to give you a head start on completing this section."
                    className={style.fontSizeInput}
                  />
                </Form.Item>
              </Form>
              {!isValid && (
                <div
                  style={{
                    color: "red",
                    fontSize: "14px",
                    position: "relative",
                    top: "45px",
                  }}
                >
                  You have exceeded the maximum length for this field
                </div>
              )}

              <div
                style={{
                  paddingTop: "5px",
                  position: "relative",
                  top: !isValid ? "40px" : "30px",
                }}
              >
                The max word limit for this page is {wordLimits[3]} words
              </div>
            </div>
            {/* <div className={style.footerButtonsDiv}>
              <Form form={form} onFinish={onFormSubmit}>
                <Button loading={getLoadingConceptNote} disabled={getLoadingConceptNote} htmlType="submit" className={style.nextButton}>
                  Submit
                </Button>
              </Form>
              <div className={style.btnDiv}>
                <div className={style.twoBtnDiv}>
                  <button
                    className={style.goBtn}
                    onClick={() =>
                      navigate(constRoute?.projectResultsGcfResults64)
                    }
                  > 
                    <img src={LeftArrow} alt="left-arrow" /> Go Back
                  </button>
                  <button
                    onClick={() => {
                      notification.success("Save and Quit");
                      navigate(constRoute?.home);
                    }}
                    className={style.saveBtn}
                  >
                    Save & Quit
                  </button>
                </div>
              </div>
            </div> */}
          </div>
          <CommonFooterButton
            isLoadingSubmit={getLoadingConceptNote}
            handleSubmit={onFormSubmit}
            handlegoback={handleback}
            handleSaveAndQuit={handleSave}
            // handleQuickNext={constRoute?.ndaAeResults72}
            form={form}
            disableSubmit={!isValid || isLoading}
            // customStyle={{position:(screenWidth > 770) ? 'absolute' : 'inherit'}}
          />
        </div>
      </div>
    </div>
  );
});

export default memo(ProjectDescriptionForm);
