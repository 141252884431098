import React from "react";
import styles from "./super-admin-login.module.scss";
import { Form, Input } from "antd";
import { validateMessages } from "@utils/json-data";
import Eye from "@assets/icons/Eye.png";
import EyeOff from "@assets/icons/EyeOff.png";
import { useNavigate } from "react-router-dom";
import { useStore } from "@stores/root-store";

const SuperAdminLogin = () => {
  const [loginForm] = Form.useForm();
  const navigate = useNavigate();
  const {
    user: { onSuperAdminLogin },
  } = useStore(null);

  const onLogin = async (value: any) => {
    await onSuperAdminLogin(
      {
        ...value,
        email: value.email.toLocaleLowerCase(),
        password: value.password,
      },
      navigate,
      () => {
        loginForm.resetFields(["password"]);
      },
    );
  };

  return (
    <div className={styles.loginWrap}>
      <h1 className={styles.loginTitle}>Master Admin Panel</h1>
      <div className={styles.loginContent}>
        <h2 className={styles.loginContentTitle}>Sign in</h2>
        <Form
          form={loginForm}
          name={"basic"}
          onFinish={onLogin}
          autoComplete={"off"}
          validateMessages={validateMessages}
          className={styles.loginForm}
          layout="vertical"
        >
          <div>
            <label
              style={{
                display: "flex",
                fontSize: "16px",
                paddingBottom: "8px",
              }}
            >
              Email Address
            </label>
            <Form.Item
              name={"email"}
              rules={[
                {
                  required: true,
                  type: "email",
                  message: `Please provide a valid email address`,
                },
              ]}
            >
              <Input placeholder="Enter email address" />
            </Form.Item>
          </div>
          <div>
            <label
              style={{
                display: "flex",
                fontSize: "16px",
                paddingBottom: "8px",
              }}
            >
              Password
            </label>
            <Form.Item
              name={"password"}
              rules={[
                {
                  required: true,
                  message: "Invalid password",
                },
              ]}
            >
              <Input.Password
                placeholder="Enter Password"
                className={styles.passwordInput}
                iconRender={(visible) =>
                  visible ? (
                    <img height={18} width={18} src={Eye} alt="" />
                  ) : (
                    <img height={18} width={18} src={EyeOff} alt="" />
                  )
                }
              />
            </Form.Item>
          </div>

          <button className={styles.loginContentButton} type="submit">
            Sign In
          </button>
        </Form>
      </div>
      <span>© 2023 Janus Advisory Services</span>
    </div>
  );
};

export default SuperAdminLogin;
