import React, { Fragment, useEffect, useState } from "react";
import styles from "./user-table.module.scss";
import DeleteIcon from "@assets/componentIcons/DeleteIcon";
import DeleteUserModal from "@components/layout/admin-layout/components/deleteUserModal/DeleteUserModal";
import { useStore } from "@stores/root-store";
import ChangeUserPlanModal from "@components/layout/superadmin-layout/changeUserPlanModal/ChangeUserPlanModal";
import updateIcon from "../../../../../assets/icons/updateIcon.svg";
import updateIconUp from "../../../../../assets/icons/updateIconUp.svg";
import ConfirmationModal from "@components/layout/superadmin-layout/confirmationModal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { constRoute } from "@utils/route";

const UserTable = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [userEmailToDelete, setUserEmailToDelete] = useState(null);
  const [userToUpdate, setUserToUpdate] = useState(null);
  const [typeToUpdate, setTypeToUpdate] = useState("Basic");
  const [openUpdatePlanModal, setOpenUpdatePlanModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [currentUserType, setCurrentUserType] = useState("");
  const [isUpgrade, setIsUpgrade] = useState(null);

  const navigate = useNavigate();
  const {
    user: { getAllUsers: allUsers, onDeleteUser, setAllUsers },
  } = useStore(null);

  const usersForSelect =
    !!allUsers["account holders"]?.length &&
    allUsers["account holders"].map((el) => {
      return {
        label: `${el.account_holder} - ${el.user_email}`,
        value: `${el.account_holder} - ${el.user_email}`,
      };
    });

  const handleUserDelete = async (email: string) => {
    const updatedUsers =
      !!allUsers["account holders"]?.length &&
      allUsers["account holders"].filter((el) => el.user_email !== email);

    onDeleteUser(email);
    setAllUsers({ ...allUsers, ["account holders"]: updatedUsers });
  };

  useEffect(() => {
    const email = userToUpdate?.split("-")[1]?.trim();

    const user =
      !!allUsers["account holders"]?.length &&
      allUsers["account holders"].find((el) => el.user_email === email);

    user && setCurrentUserType(user?.plan);
  }, [userToUpdate]);

  return (
    <div className={styles.table}>
      <div className={styles.tableHeadItem} style={{ justifyContent: "start" }}>
        Account Name
      </div>
      <div className={styles.tableHeadItem}>Plan</div>
      <div className={styles.tableHeadItem}>Entity Type</div>
      <div className={styles.tableHeadItem}>Tokens used / Tokens purchased</div>
      <div className={styles.tableHeadItem}>Last Login</div>
      <div className={styles.tableHeadItem}>Actions</div>

      {!!allUsers["account holders"]?.length &&
        allUsers["account holders"].map((el, i) => {
          return (
            <Fragment key={i}>
              <div
                onClick={() => {
                  const encodedEmail = encodeURIComponent(el.user_email);
                  navigate(
                    `${constRoute.masteradminUsers}?email=${encodedEmail}`,
                  );
                }}
                className={styles.tableItem}
                style={{ justifyContent: "start" }}
              >
                <div className={styles.tableItemAvatar}>
                  {el?.account_holder.split(" ")[0][0] || ""}
                  {(el?.account_holder.split(" ")[1] &&
                    el?.account_holder.split(" ")[1][0]) ||
                    ""}
                </div>
                <div>
                  <div className={styles.tableItemName}>
                    {el.account_holder}
                  </div>
                  <p className={styles.tableItemEmail}>{el.user_email}</p>
                </div>
              </div>
              <p className={styles.tableItem}>{el.plan}</p>
              <p className={styles.tableItem}>{el?.entity_type}</p>
              <p className={styles.tableItem}>
                {el["Tokens used / Tokens purchased"]}
              </p>
              <p className={styles.tableItem}>{el?.last_logged_in || "-"}</p>
              <div className={styles.tableItem}>
                <img
                  src={updateIconUp}
                  alt="update plan"
                  onClick={() => {
                    setCurrentUserType(el.plan);
                    setIsUpgrade(true);
                    setUserToUpdate(`${el.account_holder} - ${el.user_email}`);
                    setOpenUpdatePlanModal(true);
                  }}
                />
                <img
                  src={updateIcon}
                  alt="update plan"
                  onClick={() => {
                    setCurrentUserType(el.plan);
                    setIsUpgrade(false);
                    setUserToUpdate(`${el.account_holder} - ${el.user_email}`);
                    setOpenUpdatePlanModal(true);
                  }}
                />
                <div
                  onClick={() => {
                    setUserEmailToDelete(el?.user_email);
                    setIsOpenModal(true);
                  }}
                >
                  <DeleteIcon />
                </div>
              </div>
            </Fragment>
          );
        })}
      <ChangeUserPlanModal
        isOpen={openUpdatePlanModal}
        users={usersForSelect}
        userToUpdate={userToUpdate}
        typeToUpdate={typeToUpdate}
        closeModal={() => setOpenUpdatePlanModal(false)}
        setUserToUpdate={setUserToUpdate}
        setTypeToUpdate={setTypeToUpdate}
        currentUserType={currentUserType}
        isUpgrade={isUpgrade}
        setOpenConfirmationModal={setOpenConfirmationModal}
      />
      <ConfirmationModal
        isOpen={openConfirmationModal}
        closeModal={() => setOpenConfirmationModal(false)}
        userToUpdate={userToUpdate}
        typeToUpdate={typeToUpdate}
        isUpgrade={isUpgrade}
      />
      <DeleteUserModal
        onDeleteRecord={() => {
          setIsOpenModal(false);
          handleUserDelete(userEmailToDelete);
          setUserEmailToDelete(null);
        }}
        isOpen={isOpenModal}
        username={
          allUsers["account holders"]?.find(
            (el) => el.user_email === userEmailToDelete,
          )?.account_holder || ""
        }
        closeModal={() => setIsOpenModal(false)}
      />
    </div>
  );
};

export default UserTable;
