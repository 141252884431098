import React, { FC } from "react";
import styles from "./billing-card.module.scss";

type Props = {
  heading: string;
  subheading?: string;
  content?: string;
  footerText?: string;
};

const BillingCard: FC<Props> = ({
  heading,
  subheading,
  content,
  footerText,
}) => {
  return (
    <div className={styles.card}>
      <p className={styles.cardHeading}>{heading}</p>
      {subheading && <p className={styles.cardSubHeading}>{subheading}</p>}
      {content && <p className={styles.cardContent}>{content}</p>}
      {footerText ? (
        <div className={styles.cardFooterText}>{footerText}</div>
      ) : (
        <div className={styles.cardFooterTextEmpty}></div>
      )}
    </div>
  );
};

export default BillingCard;
