import React, { useState } from "react";
import { Form, Input, Modal, Select } from "antd";
import styles from "./contactSalesModal.module.scss";
import closeIcon from "../../../assets/icons/closeIcon.png";
import { validateMessages } from "@utils/json-data";
import { Label } from "@commonComponents/label";
import classNames from "classnames";
import { IContactSalesSend } from "../../../types/admin-panel/interfaces";
import { AdminPanelApi } from "@api/admin-panel";

interface Props {
  setIsOpen?: (value) => void;
  isOpen?: boolean;
  loading?: boolean;
  closeModal?: (value) => void;
}
const ContactSalesModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  loading,
}) => {
  const [form] = Form.useForm();
  const [submitStatus, setSubmitStatus] = useState<string | null>(null);

  const onFormSubmit = async (values: IContactSalesSend) => {
    setSubmitStatus("Submitting...");
    await AdminPanelApi.sendContactSales(values)
      .then(() => {
        setSubmitStatus("Success !");
        setTimeout(() => {
          closeModal(false);
          setSubmitStatus(null);
        }, 2000);
      })
      .catch(() => {
        setSubmitStatus("Error");
        setTimeout(() => {
          setSubmitStatus(null);
        }, 2000);
      });
  };

  return (
    <Modal
      onCancel={closeModal}
      open={isOpen}
      className={"customKeywordModal"}
      width={730}
      closable={false}
      footer={null}
    >
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <h1 className={styles.modalTitle}>Contact Sales</h1>
          <img
            src={closeIcon}
            style={{ cursor: "pointer" }}
            alt="close"
            onClick={() => closeModal(false)}
          />
        </div>
        <Form
          form={form}
          onFinish={onFormSubmit}
          autoComplete={"off"}
          validateMessages={validateMessages}
          layout="vertical"
          className={styles.signUpForm}
        >
          <Label className={styles.inputLabel} htmlFor="fullname">
            Full Name
            <span>*</span>
          </Label>
          <Form.Item
            name={"full_name"}
            rules={[
              {
                required: true,
                message: "Please enter full name",
              },
            ]}
          >
            <Input
              id="fullname"
              maxLength={115}
              type="text"
              placeholder="Enter full name"
              className={styles.input}
            />
          </Form.Item>
          <Label className={styles.inputLabel} htmlFor="email">
            Entity Email
            <span>*</span>
          </Label>
          <Form.Item
            name={"email_address"}
            rules={[
              {
                required: true,
                message: "Please enter email address",
              },
            ]}
          >
            <Input
              id="email"
              maxLength={115}
              type="email"
              placeholder="Enter email address"
              className={styles.input}
            />
          </Form.Item>
          <Label className={styles.inputLabel} htmlFor="companyName">
            Entity name
            <span>*</span>
          </Label>
          <Form.Item
            name={"company_name"}
            rules={[
              {
                required: true,
                message: "Please enter company name",
              },
            ]}
          >
            <Input
              id="companyName"
              maxLength={115}
              type="text"
              placeholder="Enter entity name"
              className={styles.input}
            />
          </Form.Item>
          <Label className={styles.inputLabel} htmlFor="comments">
            Which of the products are you interested in?*
          </Label>
          <Form.Item name={""}>
            <Select
              defaultValue="Select a product"
              placeholder="Select a product"
              style={{ width: "100%", marginBottom: "15px" }}
              options={[
                { value: "basic", label: "Basic" },
                { value: "premium", label: "Premium" },
                { value: "enterprise", label: "Enterprise" },
              ]}
            />
          </Form.Item>
          <div className={styles.buttonWrap}>
            <div
              className={classNames(styles.button, styles.buttonGrey)}
              onClick={() => closeModal(false)}
            >
              Cancel
            </div>
            <button className={styles.button} type="submit">
              {submitStatus || "Submit"}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
export default ContactSalesModal;
