import StopIcon from "@assets/icons/StopIcon";
import Copied from "@assets/icons/copied.svg";
import Copy from "@assets/icons/copy.svg";
import { CommonInput } from "@commonComponents/input";
import { useStore } from "@stores/root-store";
import { validateMessages } from "@utils/json-data";
import { Form } from "antd";
import classNames from "classnames";
import React, { memo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DraftFirstStep } from "./first-step/DraftFirstStep";
import style from "./styles.module.scss";

interface DraftFormProps {
  Text: JSX.Element;
  disabled: boolean;
  maxLength: number;
  handleSubmit: (data: any) => void;
  form: any;
  initialState: any;
  placeholder?: string;
  isSubmitting?: boolean;
  value?: string;
  section?: string;
  isValid?: boolean;
  isFormPage?: boolean;
  setIsValid: (data: boolean) => void;
  step: string;
  handleStopGenerate: () => void;
  isLoading: boolean;
  firstStepAnswers: { q1a: string; q1b: string; q1c: string };
  setFirstStepAnswers?: (data: any) => void;
}

const DraftForm: React.FC<DraftFormProps> = ({
  Text,
  disabled,
  maxLength,
  handleSubmit,
  form,
  initialState,
  placeholder,
  value,
  isSubmitting,
  section,
  isValid,
  isFormPage,
  setIsValid,
  step,
  handleStopGenerate,
  isLoading,
  firstStepAnswers,
  setFirstStepAnswers
}) => {
  const { pathname } = useLocation();
  const [copied, setCopied] = useState(false);
  const type = pathname.split("/")[3];

  const [count, setCount] = useState({
    characters: +initialState?.input?.length || 0,
    words: +initialState?.input?.trim().split(" ").length || 0
  });

  const {
    user: { handleCheckInputTokensValid }
  } = useStore(null);

  const checkLengthOfInput = async (value: string) => {
    const data = {
      functionality: "concept note",
      section: section,
      inputs:
        +step === 1
          ? firstStepAnswers
          : {
              input: value
            }
    };

    setCount({
      characters: +value.length,
      words: value
        .trim()
        .split(" ")
        .filter(el => !!el).length
    });

    await handleCheckInputTokensValid(data).then(res => {
      const result = JSON.parse(res.isValid);
      setIsValid(result);
    });
  };

  useEffect(() => {
    const value = Object.values(firstStepAnswers).filter(Boolean).join("");
    if (value.length > 0) {
      checkLengthOfInput(value);
    }
  }, [firstStepAnswers]);

  useEffect(() => {
    if (initialState?.input && isFormPage)
      form.setFieldValue("q", initialState?.input);
  }, [initialState?.input]);

  useEffect(() => {
    if (!copied) return;

    setTimeout(() => {
      setCopied(null);
    }, 2000);
  }, [copied]);

  return type === "form" && step === "1" ? (
    <DraftFirstStep
      isLoading={isLoading}
      initialAnswers={initialState}
      setFirstStepAnswers={setFirstStepAnswers}
    />
  ) : (
    <>
      <div className={style.formWrapper}>
        <Form
          className={style.form}
          form={form}
          autoComplete="false"
          onFinish={handleSubmit}
          validateMessages={validateMessages}
          layout="vertical"
          initialValues={{
            q: initialState?.input
          }}
        >
          <span
            className={style.label}
            style={{
              margin: isFormPage
                ? isLoading
                  ? "2px 0 0 2px"
                  : "0 0 0 -2px"
                : "0 0 0 2px"
            }}
          >
            {Text}
          </span>
          <div className={style.textareaWrapper}>
            <Form.Item
              name={"q"}
              rules={[
                { required: !disabled, message: "This field is required" }
              ]}
            >
              <CommonInput
                placeholder={
                  isSubmitting
                    ? "If you don’t see any output below within 7 seconds, you may have exceeded the permitted word limit in your input. Please reduce your input - thank you!"
                    : placeholder || ""
                }
                value={isFormPage ? initialState?.input : ""}
                defaultValue={!isFormPage && !isSubmitting ? value : ""}
                disabled={disabled}
                maxlength={maxLength * 10}
                size={3}
                onChange={e => {
                  checkLengthOfInput(e.target.value);
                }}
                inputType="textarea"
                style={{ cursor: isLoading ? "wait" : "", minHeight: "55vh" }}
                autoSizeCheck={{ minRows: isFormPage ? 19 : 2, maxRows: 1111 }}
                className={classNames(style.textarea)}
              />
            </Form.Item>
            {!isFormPage &&
              form.getFieldValue("q") &&
              (copied ? (
                <img src={Copied} alt="copy" />
              ) : (
                <img
                  src={Copy}
                  alt="copy"
                  onClick={() => {
                    navigator.clipboard.writeText(form.getFieldValue("q"));
                    setCopied(true);
                  }}
                />
              ))}
          </div>
        </Form>
        {/*<div>Words: {count.words} / 1000</div>*/}
        {/*<div>Characters: {count.characters} / 1000</div>*/}
        {!isValid && (
          <div style={{ color: "red", fontSize: "14px" }}>
            You have exceeded the maximum length for this field
          </div>
        )}
        {pathname.includes("/form/") && (
          <div style={{ paddingTop: "5px" }}>
            The max word limit for this page is {maxLength} words
          </div>
        )}
        {!isFormPage && (
          <div
            style={{
              border: "1px solid #E0E0E0",
              position: "relative",
              top: "-16px",
              margin: "1px",
              padding: "10px 17px",
              display: "flex",
              justifyContent: "end",
              borderRadius: "0 0 5px 5px"
            }}
          >
            <div
              style={{
                height: "fit-content",
                cursor: !isSubmitting ? "not-allowed" : "pointer"
              }}
              onClick={() => {
                if (isSubmitting) {
                  handleStopGenerate();
                }
              }}
            >
              <StopIcon color={!isSubmitting ? "#dddddd" : ""} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(DraftForm);
