import React from "react";

const RegenerateIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M4.1459 12.5267C4.99515 13.6956 6.23767 14.5188 7.64519 14.8451C9.05271 15.1713 10.5307 14.9787 11.8076 14.3026C13.0845 13.6265 14.0745 12.5124 14.5958 11.1649C15.1171 9.81737 15.1347 8.327 14.6453 6.96759C14.1559 5.60817 13.1923 4.47101 11.9317 3.76504C10.6711 3.05906 9.19809 2.83168 7.78328 3.12467C6.36846 3.41767 5.10689 4.21135 4.23033 5.35992C3.35378 6.50848 2.92112 7.93477 3.01184 9.37675M3.01184 9.37675L1.88684 8.25175M3.01184 9.37675L4.13684 8.25175"
        stroke="#2E2E2E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RegenerateIcon;
