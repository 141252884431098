import classNames from "classnames";
import { FC, ReactNode } from "react";
import Checkmark from "../../../../../../../assets/icons/greenCheckmark.svg";
import Stars from "../../../../../../../assets/icons/stars.svg";
import styles from "./style.module.scss";

interface Props {
  title: string;
  subTitle: string;
  price: string;
  button: ReactNode;
  listName: string;
  listItems: string[];
  mostPopular?: boolean;
  listStyle?: string;
}

export const PlanCard: FC<Props> = ({
  title,
  subTitle,
  price,
  button,
  listName,
  listItems,
  mostPopular,
  listStyle,
}) => {
  const classes = [styles.wrapper];
  if (mostPopular) {
    classes.push(styles.mostPopularWrapper);
  }

  return (
    <div className={classNames(...classes)}>
      {mostPopular && (
        <div className={styles.mostPopular}>
          <p>Most Popular</p>
          <img src={Stars} alt="Stars" />
        </div>
      )}
      <div
        className={styles.header}
        style={{ marginBottom: price ? "0" : "40px" }}
      >
        <p className={styles.headerTitle}>{title}</p>
        <p className={styles.headerSubTitle}>{subTitle}</p>
        <p className={styles.headerPrice}>{price}</p>
      </div>
      {button}
      <div className={classNames(styles.list, listStyle)}>
        <p>{listName}</p>
        <ul>
          {listItems.map((item, index) => (
            <li key={index}>
              <img src={Checkmark} alt="Checkmark" />
              <p>{item}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
