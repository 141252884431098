import StopIcon from "@assets/icons/StopIcon";
import Copied from "@assets/icons/copied.svg";
import Copy from "@assets/icons/copy.svg";
import { CommonInput } from "@commonComponents/input";
import { useStore } from "@stores/root-store";
import { validateMessages } from "@utils/json-data";
import { Form } from "antd";
import classNames from "classnames";
import { FC, memo, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import style from "./style.module.scss";

interface AdvisorFormProps {
  Text: JSX.Element;
  disabled: boolean;
  maxLength: number;
  handleSubmit: (data: any) => void;
  form: any;
  initialState: any;
  placeholder?: string;
  isFormPage?: boolean;
  isSubmitting?: boolean;
  value?: string;
  isItalicFontStyle?: boolean;
  section?: string;
  isValid?: boolean;
  setIsValid?: (data: boolean) => void;
  handleStopGenerate: () => void;
  isLoading: boolean;
}

const AdvisorForm: FC<AdvisorFormProps> = ({
  Text,
  disabled,
  maxLength,
  handleSubmit,
  form,
  initialState,
  placeholder,
  isFormPage,
  value,
  isSubmitting,
  isItalicFontStyle,
  section,
  isValid,
  setIsValid,
  handleStopGenerate,
  isLoading
}) => {
  const {
    user: { handleCheckInputTokensValid }
  } = useStore(null);
  const [copied, setCopied] = useState(false);

  const { pathname } = useLocation();

  const checkLengthOfInput = async (value: string) => {
    const data = {
      functionality: pathname.includes("/theory-of-change/")
        ? "theory of change"
        : "project structuring",
      section: section,
      input: value
    };

    await handleCheckInputTokensValid(data).then(res => {
      const result = JSON.parse(res.isValid);
      setIsValid(result);
    });
  };

  useEffect(() => {
    if (!copied) return;

    setTimeout(() => {
      setCopied(null);
    }, 2000);
  }, [copied]);

  return (
    <>
      <div className={style.formWrapper}>
        <Form
          className={style.form}
          form={form}
          autoComplete="false"
          onFinish={handleSubmit}
          validateMessages={validateMessages}
          layout="vertical"
          initialValues={{
            q: initialState?.input
          }}
        >
          <span className={style.label}>{Text}</span>
          <div className={style.textareaWrapper}>
            <Form.Item
              name={"q"}
              rules={[
                { required: !disabled, message: "This field is required" }
              ]}
            >
              <CommonInput
                placeholder={
                  isSubmitting
                    ? "If you don’t see any output below within 7 seconds, you may have exceeded the permitted word limit in your input. Please reduce your input - thank you!"
                    : placeholder || ""
                }
                value={isFormPage ? initialState?.input : ""}
                defaultValue={!isFormPage && !isSubmitting ? value : ""}
                disabled={disabled}
                maxlength={maxLength * 10}
                size={3}
                onChange={e => {
                  checkLengthOfInput(e.target.value);
                }}
                inputType="textarea"
                style={{ cursor: isLoading ? "wait" : "", minHeight: "55vh" }}
                autoSizeCheck={{ minRows: isFormPage ? 19 : 2, maxRows: 1111 }}
                className={classNames(style.textarea)}
              />
            </Form.Item>
            {!isFormPage &&
              form.getFieldValue("q") &&
              (copied ? (
                <img src={Copied} alt="copy" />
              ) : (
                <img
                  src={Copy}
                  alt="copy"
                  onClick={() => {
                    navigator.clipboard.writeText(form.getFieldValue("q"));
                    setCopied(true);
                  }}
                />
              ))}
          </div>
        </Form>
        {!isValid && (
          <div style={{ color: "red", fontSize: "14px" }}>
            You have exceeded the maximum length for this field
          </div>
        )}
        {/*<div style={{ paddingTop: "5px" }}>*/}
        {/*  The max word limit for this page is 1000 words*/}
        {/*</div>*/}
        {!isFormPage && (
          <div
            style={{
              border: "1px solid #E0E0E0",
              position: "relative",
              top: "-16px",
              margin: "1px",
              padding: "10px 17px",
              display: "flex",
              justifyContent: "end",
              borderRadius: "0 0 5px 5px"
            }}
          >
            <div
              style={{
                height: "fit-content",
                cursor: !isSubmitting ? "not-allowed" : "pointer"
              }}
              onClick={() => {
                if (isSubmitting) {
                  handleStopGenerate();
                }
              }}
            >
              <StopIcon color={!isSubmitting ? "#dddddd" : ""} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(AdvisorForm);
