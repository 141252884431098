import React from "react";
import styles from "./billing.module.scss";
import BillingCard from "@components/layout/admin-layout/components/billingCard/BillingCard";
import { useInviteModal } from "@components/ModalContext/InviteModalContext";
import InviteModal from "@components/layout/admin-layout/components/inviteModal/InviteModal";

const Billing = () => {
  const { isModalOpen } = useInviteModal();

  return (
    <div className={styles.billing}>
      {isModalOpen && <InviteModal />}
      <div className={styles.billingCards}>
        <BillingCard
          heading="Plan details"
          subheading="Premium workspace"
          content="Plan renews on 09/30/2023"
        />
        <BillingCard
          heading="Users (seats)"
          subheading="3 users remaning"
          content="3 users in your plan"
          footerText="Add users"
        />
        <BillingCard
          heading="Data plan info"
          subheading="American Express ending in 1234"
          footerText="Edit payment info"
        />
        <BillingCard
          heading="Billing owner"
          content="johndoe@gmail.com"
          subheading="John Duo"
          footerText="Change billing owner"
        />
      </div>
    </div>
  );
};

export default Billing;
