import { constRoute } from "@utils/route";
import { memo } from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "@components/layout/superadmin-layout/pages/dashboard/Dashboard";
import Users from "@components/layout/superadmin-layout/pages/users/Users";
import Billing from "@components/layout/admin-layout/pages/billing/Billing";
import ResourceCenter from "@components/layout/superadmin-layout/pages/resource-center/ResourceCenter";

const SuperAdminRouting = () => {
  return (
    <>
      <Routes>
        <Route
          path={constRoute?.masteradminDashboard}
          element={<Dashboard />}
        />
        <Route path={constRoute?.masteradminUsers} element={<Users />} />
        <Route
          path={constRoute?.masteradminRecource}
          element={<ResourceCenter />}
        />
        <Route path={constRoute?.masteradminBilling} element={<Billing />} />
      </Routes>
    </>
  );
};
export default memo(SuperAdminRouting);
