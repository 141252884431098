import React, { FC } from "react";

type Props = {
  color?: string;
};

const StopIcon: FC<Props> = ({ color }) => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="13.5"
        cy="13.5"
        r="12"
        fill="white"
        stroke={color || "#00B840"}
        strokeWidth="3"
      />
      <rect
        x="7.71429"
        y="8.48535"
        width="10.8"
        height="10.8"
        fill={color || "#00B840"}
      />
    </svg>
  );
};

export default StopIcon;
