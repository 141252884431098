import { observer } from "mobx-react";
import style from "./style.module.scss";
import React, { memo, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { constRoute } from "@utils/route";
import { useStore } from "@stores/root-store";
import CommonFooterButton from "../../commonfooterbutton";
import { io } from "socket.io-client";
import { useNarrativesContext } from "@components/layout/main-layout/private-layout";
import StopIcon from "@assets/icons/StopIcon";

const ProjectDescriptionResults = observer(() => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [output, setOutput] = useState("");
  const [generateText, setGenerateText] = useState("");
  const [generateResult, setRegenrateResult] = useState("");
  const socketRef = useRef(null);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [totalCount, setTotalCount] = useState("");
  const isSocketOn = localStorage.getItem("isSocketOn");

  const {
    user: {
      getconceptNotedataList,
      conceptNote,
      setConceptNoteLoading,
      getLoadingConceptNote,
      getSingleProjectData,
      getProjectNameData,
    },
  } = useStore(null);
  const [responseData] = useState(generateResult || state?.response);

  const { draftNarratives, setDraftNarratives } = useNarrativesContext();
  const [projectName] = useState(JSON.parse(getProjectNameData)?.project_name);
  const getProjectName = localStorage.getItem("projectName");

  const handleGetResults = async () => {
    const data = {
      functionality: "concept note",
      section: "B_3_16",
      project_name: projectName || getProjectName,
    };
    await getSingleProjectData(data).then((res) => {
      setDraftNarratives((prev) =>
        prev.map((el) => {
          if (el.section === "B_3_16") {
            return {
              ...el,
              res: res.narratives.map((el) => el).join("") || "",
            };
          } else {
            return el;
          }
        }),
      );
    });
  };

  useEffect(() => {
    handleGetResults();
  }, []);

  const handleRegenratePayload = async () => {
    const payload = localStorage.getItem("conceptPayload");
    const res = await conceptNote(JSON.parse(payload), navigate);
    setRegenrateResult(res?.response);
    const getReultsfromls = JSON.parse(localStorage.getItem("allResults"));
    const addResults =
      getReultsfromls &&
      getReultsfromls?.map((item) => {
        return {
          ...item,
          result3: res?.response,
        };
      });
    localStorage.setItem("allResults", JSON.stringify(addResults));
  };
  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (localStorage.getItem("totalResults") === null) {
      localStorage.setItem("totalResults", JSON.stringify([{ result3: "" }]));
    }
  }, []);
  const handleGetData = () => {
    localStorage.setItem("isSocketOn", "1");
    if (output?.endsWith("narrative generation completed")) {
      let p = document.getElementById("paragraph");
      if (p) p.innerText += "\n\n NEW NARRATIVE BELOW\n\n";
    } else {
      setOutput("");
      let p = document.getElementById("paragraph");
      if (p) p.innerText = "";
    }
    if (!output?.endsWith("narrative generation completed")) setTotalCount("");

    if (socketRef.current) {
      socketRef.current.disconnect();
      setIsSocketConnected(false);
    }
    const getAnswers = JSON.parse(localStorage.getItem("AllAnswers"));
    const getEmail = localStorage.getItem("email");
    const getProject = localStorage.getItem("projectName");
    let data = {
      section: "B_3_16",
      questions: {
        q6: (getAnswers && getAnswers[0]?.q6) || "",
      },
      project_name: getProject || "",
      email: getEmail || "",
    };
    if (!isSocketConnected) {
      socketRef.current = io("https://janusprod.climatefinancecopilot.app", {
        extraHeaders: {
          Origin: "*",
        },
      });
      socketRef.current.emit("conceptNote", data);
      let concateString = output?.endsWith("narrative generation completed")
        ? totalCount
        : "";
      let checkLength = "";
      socketRef.current.on("answer", (answer) => {
        let p = document.getElementById("paragraph");
        if (p) {
          p.innerText += answer;
          concateString = concateString + answer;
          setOutput(concateString);
          const getReultsfromls = JSON.parse(
            localStorage.getItem("totalResults"),
          );
          const addResults =
            getReultsfromls &&
            getReultsfromls?.map((item) => {
              return {
                ...item,
                result3: concateString,
              };
            });
          localStorage.setItem("totalResults", JSON.stringify(addResults));
        }
        setTotalCount(concateString);
        // if(checkLength?.split(' ')?.length >150 ){
        //   concateString = concateString+answer
        //   checkLength= checkLength+answer
        //   if(checkLength?.endsWith('.')){
        //     concateString = concateString+'<br></br>'
        //     checkLength=''
        //   }}
        //   else{
        //     concateString = concateString+answer
        //     checkLength= checkLength+answer
        //   }
        // setOutput(concateString)
      });
    }
  };
  useEffect(() => {
    if (isSocketOn === "1") handleGetData();
  }, []);
  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        setIsSocketConnected(false);
      }
    };
  }, []);
  useEffect(() => {
    if (localStorage.getItem("allResults") === null) {
      localStorage.setItem(
        "allResults",
        JSON.stringify([{ result3: state?.response || "" }]),
      );
    }
  }, []);

  const handleSave = () => {
    setConceptNoteLoading(false);
    // notification.success("Save and Quit");
    navigate(constRoute?.home);
  };
  const handleback = () => {
    localStorage.setItem("isSocketOn", "0");
    setConceptNoteLoading(false);
    navigate(constRoute?.projectResultsGcfForm);
  };
  const handleNext = () => {
    localStorage.setItem("isSocketOn", "0");
    setConceptNoteLoading(false);
    navigate(constRoute?.projectResultsGcfForm24);
  };
  const divRef = useRef(null);
  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
      divRef.current.scrollTop += 1;
    }
  }, [totalCount]);
  const results = JSON.parse(localStorage.getItem("totalResults"));

  const handleStopGenerate = () => {
    if (socketRef) {
      socketRef.current.disconnect();
    }
  };

  return (
    <div>
      <div className={style.mainContainer}>
        <div className={style.barContentContainer}>
          {/* <div className={style.layoutDiv}>
          <div className={style.siderStyle}>
            <div className={style.sideInnerDiv}>
              <div className={style.importantDiv}>
                <p className={style.pTagFour}>
                  Below is a list of sources which informed the generated
                  narrative: 
                </p>
              </div>
              <Divider />
            </div>
          </div>
        </div> */}

          <div className={style.contentContainer}>
            <div className={style.innerContentContainer}>
              <h1>Your Draft Narrative Below</h1>

              <div ref={divRef} className={style.dataContentBox}>
                {/* <div
                  className={style.htmlContent}
                  // dangerouslySetInnerHTML={{__html:output||'Please Wait...'}}
                  //  responseData || results ? results[0]?.result3 : ''}}
                /> */}
                {isSocketOn === "1" ? (
                  <p id="paragraph"></p>
                ) : (
                  <p style={{ whiteSpace: "pre-wrap" }}>
                    {draftNarratives?.find((el) => el.section === "B_3_16").res}
                  </p>
                )}
                {isSocketOn === "1" ? (
                  generateText ? (
                    <p>
                      {output ? "" : "Find below your regenerated narrative:"}
                    </p>
                  ) : (
                    <p
                      style={{
                        fontStyle: "italic",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        lineHeight: "22px",
                        color: "rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      {output
                        ? ""
                        : "If you don’t see any output below within 7 seconds, you may have exceeded the permitted word limit in your input. Please reduce your input - thank you!"}
                    </p>
                  )
                ) : (
                  ""
                )}
                {/* <p>{state?.response || ''}</p> */}
              </div>

              {/*<div className={style.wordCountWrraper}>*/}
              {/*<p>Word Count: {totalCount ? totalCount?.split(' ')?.length : results?.length ?results[0]?.result3?.split(' ')?.length: '0'}</p>*/}
              {/* </div>*/}

              {/* <div className={style.footerButtonsDiv}>
              <div className={style.leftBtnContainer}>
                <Button
                  onClick={() => navigate(constRoute?.projectResultsGcfForm24)}
                  className={style.nextButton}
                >
                 Next
                </Button>
                <Button
                  onClick={() => handleRegenratePayload()}
                  className={style.reGenerate}
                  loading={getLoadingConceptNote}
                  disabled={getLoadingConceptNote}
                >
                  Regenerate
                </Button>
              </div>
              <div className={style.btnDiv}>
                <div className={style.twoBtnDiv}>
                  <button className={style.goBtn} onClick={() => navigate(constRoute?.projectResultsGcfForm)} >
        
                    <img src={LeftArrow} alt="left-arrow" /> Go Back
                  </button>
                  <button
                    onClick={() => {
                      notification.success("Save and Quit");
                      navigate(constRoute?.home);
                    }}
                    className={style.saveBtn}
                  >
                    Save & Quit
                  </button>
                </div>
              </div>
            </div> */}
              <div
                style={{
                  border: "1px solid #E0E0E0",
                  padding: "10px 17px",
                  display: "flex",
                  justifyContent: "end",
                  borderRadius: "0 0 5px 5px",
                }}
              >
                <div
                  style={{
                    height: "fit-content",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleStopGenerate();
                  }}
                >
                  <StopIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CommonFooterButton
        handleGoNext={handleNext}
        handleRegenrate={handleGetData}
        handlegoback={handleback}
        handleSaveAndQuit={handleSave}
        setGenerateText={setGenerateText}
        isResult={true}
        isLoadingRegenrate={getLoadingConceptNote}
      />
    </div>
  );
});

export default memo(ProjectDescriptionResults);
