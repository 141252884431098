import { FC, memo, useEffect, useRef, useState } from "react";

import { useTokensContext } from "@components/layout/main-layout/private-layout";
import PlaygroundForm from "@components/pages/project/Playground/PlaygroundForm";
import { ChatRole, IChat } from "@components/pages/project/Playground/types";
import { useStore } from "@stores/root-store";
import { constRoute } from "@utils/route";
import { useForm } from "antd/es/form/Form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";

interface AdvisorFormProps {}
const formQuestions = [
  {
    q: <span>How Can I Help You Today?</span>,
    result: <span>Your Results Below:</span>,
    limit: 1000,
    placeholder:
      "Here are a few things to know when utilizing this tool:\n" +
      "\n" +
      "• Enter any query or task into the box located at the bottom of this page.\n" +
      "• The Playground will remember your queries/tasks, enabling you to ask follow-up questions as needed.\n" +
      "• Be aware that once you navigate from this page, your session will be cleared from memory, so make sure to save any information outputs.\n" +
      "• For assistance on crafting effective prompts, refer to the prompt writing tips guide situated at the top right of this page."
  }
];

const Playground: FC<AdvisorFormProps> = () => {
  const nav = useNavigate();
  const socketRef = useRef(null);
  const { type, step } = useParams();
  const { pathname } = useLocation();
  const [form] = useForm();
  const [question, setQuestion] = useState("");
  const [chat, setChat] = useState<IChat[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { setTokens } = useTokensContext();

  const projectName = localStorage.getItem("projectName");
  const results = JSON.parse(
    localStorage.getItem("totalResultsAdvisor" + projectName)
  );

  const {
    user: { handleGetGuidePdf, getSingleProjectData },
    modelType: { getSelectedModelType }
  } = useStore(null);

  const [initialInput, setInitialInput] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [questions, setQuestions] = useState("");
  const [resultsData, setResultsData] = useState(results || "");
  const [input, setInput] = useState("");

  const getProjectData = async () => {
    const data = JSON.stringify({ functionality: "playground" });

    await getSingleProjectData(data, null).then(state => {
      setTokens(state["tokens_remaining/tokens_purchased"]);
    });
  };

  function resizeImage(file, maxWidth, maxHeight, callback) {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = function (event) {
      img.src = event.target.result as string;
    };

    img.onload = function () {
      let width = img.width;
      let height = img.height;

      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      }

      if (height > maxHeight) {
        width = Math.round((width * maxHeight) / height);
        height = maxHeight;
      }

      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      canvas.toBlob(function (blob) {
        callback(blob);
      }, "image/jpeg");
    };

    reader.readAsDataURL(file);
  }

  const isFormPage = type === "form";
  const handleSubmit = values => {
    setQuestion(form?.getFieldValue("q"));
    setResultsData("");
    setIsSubmitting(true);

    if (!input) return;

    setChat(prev =>
      prev.map(el => {
        return { ...el, isLastChatMessage: false };
      })
    );

    const message = {
      id: chat[chat.length - 1]?.id ? chat[chat.length - 1]?.id + 1 : 1,
      role: ChatRole.USER,
      message: input,
      image: selectedFile,
      isLastChatMessage: false
    };

    const answer = {
      id: chat[chat.length - 1]?.id ? chat[chat.length - 1]?.id + 2 : 2,
      role: ChatRole.CHAT,
      message: "",
      isLastChatMessage: true
    };

    const email = localStorage.getItem("email");

    setInitialInput(values?.q);

    const data = {
      conversation:
        chat.length === 0
          ? [
              {
                role: "user",
                content: [
                  {
                    type: "text",
                    text: input
                  }
                ]
              }
            ]
          : [
              ...chat.map(el => {
                const res = {
                  role: el.role === ChatRole.CHAT ? "system" : "user",
                  content: [
                    {
                      type: "text",
                      text: el.message
                    }
                  ]
                };

                if (el?.image_url) {
                  res.content.push({
                    type: "image_url",
                    // @ts-ignore
                    image_url: { url: el?.image_url }
                  });
                }
                return res;
              }),
              {
                role: "user",
                content: [
                  {
                    type: "text",
                    text: input
                  }
                ]
              }
            ],
      email,
      model: getSelectedModelType ?? "Mentor"
    };

    const socket = io("https://janusprod.climatefinancecopilot.app", {});

    if (selectedFile) {
      resizeImage(selectedFile, 1024, 768, blob => {
        const reader = new FileReader();
        reader.onload = function (e) {
          const base64Image = e.target.result;
          if (data.conversation.length === 1) {
            // @ts-ignore
            data.conversation[0].content.push({
              type: "image_url",
              // @ts-ignore
              image_url: { url: base64Image }
            });
          } else {
            data.conversation[data.conversation.length - 1].content.push({
              type: "image_url",
              // @ts-ignore
              image_url: { url: base64Image }
            });
          }

          socket.emit("playground", data);
          message["image_url"] = base64Image;
          setChat(prev => [...prev, message, answer]);
        };

        reader.readAsDataURL(blob);
      });
    }

    socketRef.current = socket;
    setInput("");
    setSelectedFile(null);

    setResultsData("");
    console.log("socket_data", data);
    if (!selectedFile) {
      setChat(prev => [...prev, message, answer]);
      socket.emit("playground", data);
    }
    socket.on("answer", answer => {
      const isStopGeneration = localStorage.getItem("isStopGeneration");
      if (!isSubmitting && answer) {
        setIsSubmitting(true);
      }

      if (isStopGeneration || answer === "#####") {
        socket.disconnect();
        localStorage.removeItem("isStopGeneration");
        setIsSubmitting(false);
        return;
      }

      setResultsData(answer);
      setChat(prev =>
        prev.map(el => {
          if (el.id === prev[prev.length - 1].id) {
            return {
              id: prev[prev.length - 1].id + 1,
              role: ChatRole.CHAT,
              message: prev[prev.length - 1].message + answer,
              isLastChatMessage: true
            };
          } else {
            return el;
          }
        })
      );
    });

    socket.on("disconnect", () => {
      setIsSubmitting(false);
      getProjectData();
    });
  };

  useEffect(() => {
    getProjectData();
  }, []);

  const stopGeneration = async () => {
    if (socketRef.current) {
      await socketRef.current.disconnect();
      await setIsSubmitting(false);
    }
  };

  const handleGoBack = () => {
    localStorage.setItem("isStopGeneration", String(true));
    setIsSubmitting(false);

    if (pathname.includes("playground/form/1")) {
      nav(constRoute.home);
    } else {
      nav(constRoute?.playground + "/form/1");
    }
  };

  const handleSaveQuit = () => {
    nav(constRoute?.existingProject);
  };

  const handleRegenerate = () => {
    form.setFieldValue("q", "");
    setQuestions(question);
    // nav(constRoute?.playground + "/form/1");
    const values = {
      q: question
    };
    handleSubmit(values);
  };

  const onDownloadPdf = async () => {
    await handleGetGuidePdf().then(blob => {
      const url = window.URL.createObjectURL(blob);

      const newTab = window.open();
      newTab.document.write(
        '<iframe width="100%" height="100%" src="' +
          url +
          '"frameborder="0"></iframe>'
      );
      newTab.document.body.style.margin = "0";

      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [type === "results"]);

  useEffect(() => {
    socketRef?.current?.disconnect();
    form.resetFields();
    setIsSubmitting(false);
    setResultsData((results && results[`result${step}`]) || "");
  }, [step]);

  useEffect(() => {
    return () => {
      socketRef?.current?.disconnect();
    };
  }, []);

  useEffect(() => {
    // localStorage.removeItem("isStopGeneration");
    //
    // return () => {
    //   localStorage.removeItem("isStopGeneration");
    // };
  }, [step, type]);

  if (isLoading) {
    return <></>;
  }

  return (
    <div style={{ padding: "30px 52px 30px", height: "calc(100vh - 160px)" }}>
      <PlaygroundForm
        form={form}
        value={resultsData}
        isSubmitting={isSubmitting}
        initialState={initialInput}
        handleSubmit={handleSubmit}
        isFormPage={isFormPage}
        question={resultsData}
        setInput={(val: string) => setInput(val)}
        input={input}
        setChat={setChat}
        chat={chat}
        Text={
          isFormPage
            ? formQuestions[+step - 1]?.q
            : formQuestions[+step - 1]?.result || results[`result${step}`] || ""
        }
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        placeholder={formQuestions[+step - 1]?.placeholder}
        maxLength={formQuestions[+step - 1]?.limit}
        disabled={type === "results"}
        onDownloadPdf={onDownloadPdf}
        stopGeneration={() => stopGeneration()}
      />
    </div>
  );
};

export default memo(Playground);
