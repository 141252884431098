import nextBtn from "@assets/images/Next.svg";
import RegenerateBtn from "@assets/images/Regenerate.svg";
import SubmitBtn from "@assets/images/Submit.svg";
import addBillingBtn from "@assets/images/add-billing-info.svg";
import dropDownIcon from "@assets/icons/down.svg";
import mailIcon from "@assets/icons/mail.svg";

export const constImages = {
  nextBtn: nextBtn,
  RegenerateBtn: RegenerateBtn,
  SubmitBtn: SubmitBtn,
  addBillingBtn: addBillingBtn,
  dropDownIcon: dropDownIcon,
  mailIcon: mailIcon,
};
