import { EyeOutlined } from "@ant-design/icons";
import styles from "./files-tableю.module.scss";
import DeleteIcon from "@assets/componentIcons/DeleteIcon";
import { Table } from "antd";
import { FC } from "react";
import FileIcon from "@assets/componentIcons/FileIcon";

const columns = [
  {
    title: "File Name",
    dataIndex: "fileName",
    key: "fileName",
    width: "90%",
    height: "45px",
    render: (record) => {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
            gap: "15px",
          }}
        >
          <div className={styles.tableFileIcon}>
            <FileIcon />
          </div>
          {record}
        </div>
      );
    },
  },
  {
    title: "Actions",
    key: "actions",
    width: "10%",
    render: (t, record) => {
      return (
        <div
          style={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}
        >
          {record.actions.map((action, index) => (
            <span key={index} style={{ marginRight: 8 }}>
              {action}
            </span>
          ))}
        </div>
      );
    },
  },
];

type Props = {
  data: string[];
  getFileName: (val: string) => string;
  getFileInfo: (file: any) => Promise<void>;
  setOpenDeleteModal: (val: string) => void;
};

const FileTable: FC<Props> = ({
  data,
  getFileName,
  getFileInfo,
  setOpenDeleteModal,
}) => {
  const renderData = () => {
    return (
      !!data?.length &&
      data.map((el, i) => {
        const name: string = getFileName(el);

        return {
          key: i,
          data: el,
          fileName: name,
          actions: [
            <EyeOutlined
              className={styles.eyeIcon}
              onClick={(e) => {
                e.stopPropagation();
                getFileInfo(el);
              }}
            />,
            <span
              style={{ width: "18px", cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenDeleteModal(el);
              }}
            >
              <DeleteIcon />
            </span>,
          ],
        };
      })
    );
  };

  return (
    <Table
      dataSource={renderData()}
      columns={columns}
      onRow={(record) => {
        return {
          onClick: () => {
            getFileInfo(record.data);
          },
        };
      }}
      pagination={false}
      className={styles.table}
    />
  );
};

export default FileTable;
