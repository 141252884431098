import DashboardIcon from "@assets/componentIcons/DashboardIcon";
import UserAdminIcon from "@assets/componentIcons/UserAdminIcon";
import BillingIcon from "@assets/componentIcons/BillingIcon";
import { constRoute } from "@utils/route";

export const navigationItems = [
  {
    name: "Dashboard",
    icon: DashboardIcon,
    navigation: constRoute.adminDashboard,
  },
  { name: "Users", icon: UserAdminIcon, navigation: constRoute.adminUsers },
  { name: "Billing", icon: BillingIcon, navigation: constRoute.adminBilling },
];

export const mockUserData = [
  {
    id: 1,
    name: "John Doe",
    email: "johndoe@gmail.com",
    entityName: "User",
    country: "Brazil",
    date: "01/02/2023",
  },
  {
    id: 2,
    name: "Chris Ramirez",
    email: "chrisramirez@gmail.com",
    entityName: "User",
    country: "Puerto Rico",
    date: "01/05/2023",
  },
];
