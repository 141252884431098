import classNames from "classnames";
import style from "./styles.module.scss";

const sub_sections = ["Project Effectiveness", "Potential Barriers"];
const word_replacements = {
  "Project Effectiveness": "1.1 Project Effectiveness",
  "Potential Barriers": "2.1 Potential Barriers",
  "Activity Alignment Checker": "Alignment Checker"
};

export const ContentToExport = ({ sections, sectionDataHandler }) => {
  return (
    <div>
      {sections?.map((item, index) => {
        const firstWord = item.split(" ")[0];
        const dotCount = (firstWord.match(/\./g) || []).length;
        return (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8
            }}
            className={classNames(style.sectionWrapper, {
              [style.firstSectionElement]: dotCount < 2 && index !== 0
            })}
          >
            <h3
              style={{
                display: "flex",
                wordWrap: "normal",
                fontSize: "16px",
                color: "#000",
                fontWeight:
                  dotCount === 2 ||
                  item[1] === "." ||
                  sub_sections.includes(item)
                    ? 400
                    : 600
              }}
            >
              {word_replacements[item] ?? item}
            </h3>
            {sectionDataHandler(item)}
          </div>
        );
      })}
    </div>
  );
};
