import React, { FC } from "react";
import styled from "styled-components";
import { Modal } from "antd";
import styles from "./confirmation-modal.module.scss";
import classNames from "classnames";
import { useStore } from "@stores/root-store";

interface Props {
  setIsOpen?: (value: boolean) => void;
  isOpen?: boolean;
  onDeleteRecord?: any;
  typeToUpdate?: any;
  userToUpdate?: any;
  currentUserType?: any;
  loading?: boolean;
  isUpgrade?: boolean;
  users?: any[];
  closeModal?: () => void;
}

const ConfirmationModal: FC<Props> = ({
  onDeleteRecord,
  isOpen,
  closeModal,
  users,
  loading,
  typeToUpdate,
  userToUpdate,
  currentUserType,
  isUpgrade,
}) => {
  const {
    user: {
      getAllUsers: allUsers,
      onUpdateUserPlan,
      setAllUsers,
      onDowngradeUserPlan,
    },
  } = useStore(null);

  const handleUpdatePlan = async () => {
    if (typeToUpdate && typeToUpdate) {
      const email = userToUpdate?.split("-")[1]?.trim();
      const updatedUsers = allUsers["account holders"].map((el) => {
        if (el.user_email === email) {
          return { ...el, plan: typeToUpdate };
        } else {
          return el;
        }
      });

      if (isUpgrade) {
        await onUpdateUserPlan({ email, new_plan: typeToUpdate }).then(() => {
          setAllUsers({ ...allUsers, ["account holders"]: updatedUsers });
        });
      } else {
        await onDowngradeUserPlan({ email, new_plan: typeToUpdate }).then(
          () => {
            setAllUsers({ ...allUsers, ["account holders"]: updatedUsers });
          },
        );
      }

      closeModal();
    }
  };

  return (
    <Wrapper>
      <Modal
        onCancel={closeModal}
        open={isOpen}
        className={"customKeywordModal"}
        width={625}
        closable={false}
        footer={null}
      >
        <div className={styles.modal}>
          <div
            style={{
              cursor: "pointer",
              width: "fit-content",
              marginLeft: "auto",
            }}
            onClick={() => closeModal()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.4546 0.252083C10.1405 -0.0729166 9.63307 -0.0729166 9.31894 0.252083L5.38027 4.31875L1.4416 0.24375C1.12747 -0.08125 0.620035 -0.08125 0.305908 0.24375C-0.00821932 0.56875 -0.00821932 1.09375 0.305908 1.41875L4.24458 5.49375L0.305908 9.56875C-0.00821932 9.89375 -0.00821932 10.4187 0.305908 10.7437C0.620035 11.0687 1.12747 11.0687 1.4416 10.7437L5.38027 6.66875L9.31894 10.7437C9.63307 11.0687 10.1405 11.0687 10.4546 10.7437C10.7688 10.4187 10.7688 9.89375 10.4546 9.56875L6.51596 5.49375L10.4546 1.41875C10.7607 1.10208 10.7607 0.56875 10.4546 0.252083Z"
                fill="#2E2E2E"
              />
            </svg>
          </div>
          <div className={styles.modalHeader}>
            <h3 className={styles.modalHeaderTitle}>
              {isUpgrade
                ? "Are you sure you want to upgrade "
                : "Are you sure you want to downgrade"}{" "}
              “{userToUpdate?.split("-")[0].trim()}” plan?
            </h3>
          </div>
          <div className={styles.modalButtonWrap}>
            <div
              className={classNames(
                styles.modalButtonDisabled,
                styles.modalButton,
              )}
              onClick={() => closeModal()}
            >
              Cancel
            </div>
            <div
              className={styles.modalButton}
              onClick={() => handleUpdatePlan()}
            >
              {isUpgrade ? "Yes, upgrade" : "Yes, downgrade"}
            </div>
          </div>
        </div>
      </Modal>
    </Wrapper>
  );
};

export default ConfirmationModal;

const Wrapper = styled.div`
  .customKeywordModal {
    position: relative;

    .rc-dialog-content {
      .rc-dialog-close {
        color: #fff;
        font-size: 40px;
        right: -50px;
        top: -14px;
      }
    }
  }
`;
