import StopIcon from "@assets/icons/StopIcon";
import Copied from "@assets/icons/copied.svg";
import Copy from "@assets/icons/copy.svg";
import { CommonInput } from "@commonComponents/input";
import FirstRationalStep from "@components/pages/project/rational-advisor-page/rational-form/first-step";
import { validateMessages } from "@utils/json-data";
import { Form } from "antd";
import classNames from "classnames";
import { FC, memo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useStore } from "../../../../../stores/root-store";
import style from "./style.module.scss";

interface RationalFormProps {
  Text: JSX.Element;
  disabled: boolean;
  maxLength: number;
  handleSubmit: (data: any) => void;
  form: any;
  initialState: any;
  placeholder?: string;
  isFormPage?: boolean;
  isSubmitting?: boolean;
  value?: string;
  isItalicFontStyle?: boolean;
  section?: string;
  isValid?: boolean;
  setIsValid?: (data: boolean) => void;
  setFirstStepAnswers?: (data: any) => void;
  firstStepAnswers?: any;
  projectName?: string;
  handleStopGenerate: () => void;
  isLoading: boolean;
}

const RationalForm: FC<RationalFormProps> = ({
  Text,
  disabled,
  maxLength,
  handleSubmit,
  form,
  initialState,
  placeholder,
  isFormPage,
  value,
  isSubmitting,
  isItalicFontStyle,
  section,
  isValid,
  setIsValid,
  firstStepAnswers,
  setFirstStepAnswers,
  projectName,
  handleStopGenerate,
  isLoading
}) => {
  const {
    user: { handleCheckInputTokensValid }
  } = useStore(null);
  const [copied, setCopied] = useState(false);

  const { pathname } = useLocation();

  const type = pathname.split("/")[3];
  const step = pathname.split("/")[4];

  const checkLengthOfInput = async (value: string) => {
    const data = {
      functionality: "climate rationale",
      section: section,
      project_name: projectName,
      questions:
        +step === 1
          ? firstStepAnswers
          : {
              [`q${+step + 1}`]: value
            }
    };

    await handleCheckInputTokensValid(data).then(res => {
      const result = JSON.parse(res.isValid);
      setIsValid(result);
    });
  };

  useEffect(() => {
    const value = Object.values(firstStepAnswers).filter(Boolean).join("");
    if (value.length > 0) {
      checkLengthOfInput(value);
    }
  }, [firstStepAnswers]);

  useEffect(() => {
    if (!copied) return;

    setTimeout(() => {
      setCopied(null);
    }, 2000);
  }, [copied]);

  return type === "form" && step === "1" ? (
    <FirstRationalStep
      isLoading={isLoading}
      setFirstStepAnswers={setFirstStepAnswers}
      initialAnswers={initialState}
    />
  ) : (
    <>
      <div className={style.formWrapper}>
        <Form
          className={style.form}
          form={form}
          autoComplete="false"
          onFinish={handleSubmit}
          validateMessages={validateMessages}
          layout="vertical"
          initialValues={{
            [`q${+step + 1}`]: initialState ? initialState[`q${+step + 1}`] : ""
          }}
        >
          <span
            className={style.label}
            style={{
              margin: isFormPage && isLoading ? "2px 0 0 2px" : ""
            }}
          >
            {Text}
          </span>
          <div className={style.textareaWrapper}>
            <Form.Item
              name={`q${+step + 1}`}
              rules={[
                { required: !disabled, message: "This field is required" }
              ]}
            >
              <CommonInput
                placeholder={
                  isSubmitting
                    ? "If you don’t see any output below within 7 seconds, you may have exceeded the permitted word limit in your input. Please reduce your input - thank you!"
                    : placeholder || ""
                }
                value={
                  isFormPage && initialState && !isSubmitting
                    ? initialState[`q${+step + 1}`]
                    : ""
                }
                defaultValue={!isFormPage && !isSubmitting ? value : ""}
                disabled={disabled}
                maxlength={maxLength * 10}
                size={3}
                onChange={e => {
                  checkLengthOfInput(e.target.value);
                }}
                inputType="textarea"
                style={{ cursor: isLoading ? "wait" : "", minHeight: "55vh" }}
                autoSizeCheck={{ minRows: isFormPage ? 19 : 2, maxRows: 1111 }}
                className={classNames(style.textarea)}
              />
            </Form.Item>
            {!isFormPage &&
              !isSubmitting &&
              (value || form.getFieldValue(`q${+step + 1}`)) &&
              (copied ? (
                <img src={Copied} alt="copy" />
              ) : (
                <img
                  src={Copy}
                  alt="copy"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      value ?? form.getFieldValue(`q${+step + 1}`)
                    );
                    setCopied(true);
                  }}
                />
              ))}
          </div>
        </Form>
        {!isValid && (
          <div style={{ color: "red", fontSize: "14px" }}>
            You have exceeded the maximum length for this field
          </div>
        )}
        {/*<div style={{ paddingTop: "5px" }}>*/}
        {/*  The max word limit for this page is 1000 words*/}
        {/*</div>*/}
        {!isFormPage && (
          <div
            style={{
              border: "1px solid #E0E0E0",
              position: "relative",
              top: "-16px",
              margin: "1px",
              padding: "10px 17px",
              display: "flex",
              justifyContent: "end",
              borderRadius: "0 0 5px 5px"
            }}
          >
            <div
              style={{
                height: "fit-content",
                cursor: !isSubmitting ? "not-allowed" : "pointer"
              }}
              onClick={() => {
                if (isSubmitting) {
                  handleStopGenerate();
                }
              }}
            >
              <StopIcon color={!isSubmitting ? "#dddddd" : ""} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(RationalForm);
