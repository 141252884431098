import styles from "./add-tokens-modal.module.scss";
import { FC } from "react";

interface Props {
  closeModal: () => void;
  tokens: number;
  price: number;
  user: any;
  setStep: (value: number) => void;
  handlePurchaseTokens: () => Promise<void>;
  upStep: number;
}

export const OrderSummary: FC<Props> = ({
  closeModal,
  tokens,
  price,
  setStep,
  handlePurchaseTokens,
  upStep,
  user,
}) => {
  return (
    <div className={styles.modal}>
      <div className={styles.modalHeader}>
        <h3 className={styles.modalHeaderTitle}>
          {upStep ? "Upgrade to Premium" : "Order Summary"}
        </h3>
        <div style={{ cursor: "pointer" }} onClick={closeModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.4546 0.252083C10.1405 -0.0729166 9.63307 -0.0729166 9.31894 0.252083L5.38027 4.31875L1.4416 0.24375C1.12747 -0.08125 0.620035 -0.08125 0.305908 0.24375C-0.00821932 0.56875 -0.00821932 1.09375 0.305908 1.41875L4.24458 5.49375L0.305908 9.56875C-0.00821932 9.89375 -0.00821932 10.4187 0.305908 10.7437C0.620035 11.0687 1.12747 11.0687 1.4416 10.7437L5.38027 6.66875L9.31894 10.7437C9.63307 11.0687 10.1405 11.0687 10.4546 10.7437C10.7688 10.4187 10.7688 9.89375 10.4546 9.56875L6.51596 5.49375L10.4546 1.41875C10.7607 1.10208 10.7607 0.56875 10.4546 0.252083Z"
              fill="#2E2E2E"
            />
          </svg>
        </div>
      </div>
      <div className={styles.modalBody}>
        <div
          className={styles.modalBodyRow}
          style={{
            gridTemplateColumns: upStep ? "1fr 1fr 1fr 1fr" : "",
          }}
        >
          <p className={styles.modalBodyRowText}>Customer</p>
          {upStep && <p className={styles.modalBodyRowText}>Monthly Cost</p>}
          <p
            className={styles.modalBodyRowText}
            style={{ textAlign: "center" }}
          >
            Description
          </p>
          <p className={styles.modalBodyRowText} style={{ textAlign: "end" }}>
            Amount
          </p>
        </div>
        <div
          className={styles.modalBodyRow}
          style={{
            gridTemplateColumns: upStep ? "1fr 1fr 1fr 1fr" : "",
          }}
        >
          <p className={styles.modalBodyRowText}>{user?.name}</p>
          {upStep && <p className={styles.modalBodyRowText}>$999.99 USD</p>}
          <p
            className={styles.modalBodyRowText}
            style={{ textAlign: "center" }}
          >
            $10 / {user.plan === "Basic" ? "50,000" : tokens} tokens
          </p>
          <p className={styles.modalBodyRowText} style={{ textAlign: "end" }}>
            ${price} USD
          </p>
        </div>
        <div className={styles.modalBodyTotal}>
          <p className={styles.modalBodyTotalText}>Total</p>
          <p className={styles.modalBodyTotalTextBold}>${price} USD</p>
        </div>
      </div>
      <div className={styles.modalButtonWrap}>
        <button
          className={styles.modalButton}
          onClick={() => {
            handlePurchaseTokens();
          }}
        >
          Upgrade
        </button>
      </div>
    </div>
  );
};
