import React, {
  createContext,
  useState,
  useContext,
  FC,
  ReactNode,
} from "react";

interface UpgradePlanModalContextType {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

const UpgradePlanModalContext = createContext<
  UpgradePlanModalContextType | undefined
>(undefined);

export const UpgradePlanModalProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <UpgradePlanModalContext.Provider
      value={{ isModalOpen, openModal, closeModal }}
    >
      {children}
    </UpgradePlanModalContext.Provider>
  );
};

export const useUpgradePlanModal = (): UpgradePlanModalContextType => {
  const context = useContext(UpgradePlanModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
