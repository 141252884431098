import { observer } from "mobx-react";
import style from "./style.module.scss";
import React, { memo, useEffect, useState } from "react";
import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import { CommonInput } from "@components/common-components/input";
import { validateMessages } from "@utils/json-data";
import { useNavigate } from "react-router-dom";
import { constRoute } from "@utils/route";
import { useStore } from "@stores/root-store";
import CommonImportantSideBar from "../importantSideBar/common-important-sidebar";
import CommonFooterButton from "../commonfooterbutton";
import { wordLimits } from "@components/pages/project/select-function/which-to-do/select-one/project-name/important-project-info/utils/wordLimits";
import { useTokensContext } from "@components/layout/main-layout/private-layout";

const gcfJustificationForm = observer(() => {
  const [form] = useForm();
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [initialQuestions, setInitialQuestions] = useState(null);

  const {
    user: {
      getProjectNameData,
      setConceptNoteLoading,
      getLoadingConceptNote,
      conceptNote,
      getSingleProjectData,
      loadingSingleProjectData,
      handleCheckInputTokensValid,
    },
  } = useStore(null);

  const { setTokens } = useTokensContext();
  const [projectName] = useState(JSON.parse(getProjectNameData)?.project_name);
  const [allInputValues, setAllInputValues] = useState({
    q13a: "",
    q13b: "",
    q13c: "",
  });
  const [isValid, setIsValid] = useState(true);
  const getProjectName = localStorage.getItem("projectName");

  const checkLengthOfInput = async (value: string) => {
    const data = {
      functionality: "concept note",
      section: "C_2_72",
      questions: { ...allInputValues },
      project_name: projectName || getProjectName,
    };

    await handleCheckInputTokensValid(data).then((res) => {
      const result = JSON.parse(res.isValid);
      setIsValid(result);
    });
  };

  useEffect(() => {
    const value = Object.values(allInputValues).join(" ");
    checkLengthOfInput(value);
  }, [allInputValues]);

  useEffect(() => {
    if (localStorage.getItem("AllAnswers") === null) {
      localStorage.setItem(
        "AllAnswers",
        JSON.stringify([{ q13a: "", q13b: "", q13c: "" }]),
      );
    }
  }, []);

  const onFormSubmit = async (values) => {
    localStorage.setItem("isSocketOn", "1");
    const getAnswers = JSON.parse(localStorage.getItem("AllAnswers"));
    const addMoreAnswers = getAnswers?.map((item) => {
      return {
        ...item,
        q13a: values?.q13a,
        q13b: values?.q13b,
        q13c: values?.q13b,
      };
    });
    const question = {
      q13a: values?.q13a || "",
      q13b: values?.q13b || "",
      q13c: values?.q13c || "",
    };
    localStorage.setItem("AllAnswers", JSON.stringify(addMoreAnswers));
    const getReultsfromls = JSON.parse(localStorage.getItem("totalResults"));
    const addResults =
      getReultsfromls &&
      getReultsfromls?.map((item) => {
        return {
          ...item,
          result10: "",
        };
      });
    localStorage.setItem("totalResults", JSON.stringify(addResults));
    // const payload = {
    //   section: `C_2_72`,
    //   questions: question,
    //   project_name: projectName|| getProjectName || ''
    // }
    // localStorage.setItem('conceptPayload', JSON.stringify(payload))
    // const response = await conceptNote(payload, navigate)
    // if(response?.response){
    navigate(
      constRoute?.gcfJustificationResults90,
      // ,  { state: { response: response?.response} }
    );
    // const getReultsfromls = JSON.parse(localStorage.getItem('allResults'));
    // const addResults =  getReultsfromls && getReultsfromls?.map((item) => {
    //   return {
    //     ...item,
    //     result10: response?.response || ""
    //   }
    // })
    // localStorage.setItem('allResults', JSON.stringify(addResults))
    // }
  };
  const handleSave = () => {
    setConceptNoteLoading(false);
    // notification.success("Save and Quit");
    navigate(constRoute?.home);
  };
  const handleback = () => {
    localStorage.setItem("isSocketOn", "0");
    setConceptNoteLoading(false);
    navigate(constRoute?.ndaAeResults72);
  };
  const getAnswers = JSON.parse(localStorage.getItem("AllAnswers"));

  useEffect(() => {
    const data = {
      section: "C_2_72",
      project_name: projectName ?? localStorage.getItem("projectName"),
      functionality: "concept note",
    };
    localStorage.setItem("data-for-tokens", JSON.stringify(data));
    getSingleProjectData(data).then((el) => {
      setTokens(el["tokens_remaining/tokens_purchased"]);
      setInitialQuestions(el);
      setIsLoading(false);
      form.setFieldsValue(el.questions);
    });
  }, []);

  return (
    <div className={style.mainContainer}>
      <div className={style.barContentContainer}>
        <CommonImportantSideBar
          title={"Quick Tips"}
          secondParagraph={`2. In your “rationale and level of concessionality” discussion, justify why this is the minimum required to make the investment viable and most efficient considering the incremental cost or risk premium of the Project/ Programme. `}
          fristPara={`1. In your discussion on “alternative funding options” Include an analysis of the barriers for the potential beneficiaries to access to finance and the constraints of public and private sources of  funding. `}
        />
        {/* {show && (
        <div className={style.layoutDiv}>
          <div className={style.siderStyle}>
            <div className={style.sideInnerDiv}>
              <div className={style.importantDiv}>
                <p className={style.pTagFour}>Quick Tips</p>
                <button className={style.btnClass} onClick={() => setShow(!show)}>
                  <img
                    src={CloseIcon}
                    className={style.closeIconImg}
                    alt="fd"
                  />
                </button>
              </div>
              <div className={style.pTageGroup}>
                <p className={style.pTagFive}>
                  ** The justification for grants and reimbursable grants is
                  mandatory. **
                </p>
                <p className={style.pTagSix}>
                  In your discussion on “alternative funding options” Include an
                  analysis  of the barriers for the potential beneficiaries to
                  access to finance and the constraints of public and private
                  sources of  funding. 
                </p>
                <p className={style.pTagSeven}>
                  In your “rationale and level of concessionality” discussion
                  justify why this is the minimum required to make the
                  investment viable and most efficient considering the
                  incremental cost or risk premium of the Project/ Programme. 
                </p>

                <p className={style.pTagSeven}>
                  Lastly, In the case of private sector proposal, concessional
                  terms should be minimized and justified as per the Guiding
                  principles applicable to the private sector operations.
                </p>
              </div>
              <Divider />
            </div>
          </div>
        </div>
      )} */}
        <div
          className={style.contentContainer}
          style={{ cursor: isLoading ? "wait" : "" }}
        >
          <div className={style.innerContentContainer}>
            <h1>Please Fill In the Blanks Below:</h1>

            <div className={style.dataContentBox}>
              <Form
                className={style.formData}
                form={form}
                autoComplete="false"
                onFinish={onFormSubmit}
                validateMessages={validateMessages}
                layout="vertical"
                initialValues={{
                  q13a:
                    initialQuestions?.questions?.q13a ||
                    (getAnswers && getAnswers[0]?.q13a) ||
                    "",
                  q13b:
                    initialQuestions?.questions?.q13b ||
                    (getAnswers && getAnswers[0]?.q13b) ||
                    "",
                  q13c:
                    initialQuestions?.questions?.q13c ||
                    (getAnswers && getAnswers[0]?.q13c) ||
                    "",
                }}
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridTemplateRows: "1fr 1fr",
                  gap: "15px",
                }}
              >
                <Form.Item
                  label={
                    <span>
                      {
                        "13a) Explain why the project requires GCF funding, i.e. explaining why this is not financed by the public and/ or private sector(s) of the country."
                      }
                      <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name={"q13a"}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <CommonInput
                    inputType="textarea"
                    onChange={(e) =>
                      setAllInputValues((prev) => {
                        return { ...prev, q13a: e.target.value };
                      })
                    }
                    disabled={isLoading}
                    autoSizeCheck={{ minRows: 7, maxRows: 7 }}
                    className={style.fontsizeInput}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span style={{ marginBottom: "20px" }}>
                      {
                        "13b) Describe alternative funding options for the same activities being proposed."
                      }
                      <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name={"q13b"}
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <CommonInput
                    inputType="textarea"
                    onChange={(e) =>
                      setAllInputValues((prev) => {
                        return { ...prev, q13b: e.target.value };
                      })
                    }
                    disabled={isLoading}
                    autoSizeCheck={{ minRows: 7, maxRows: 7 }}
                    // placeholder="Leave blank if you want Climate Finance Co-pilot to supply suggested narratives to give you a head start on completing this section.
                    className={style.fontsizeInput}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      {
                        "13c) Justify the rationale and level of concessionality of the GCF financial instrument(s) as well as how this will be passed on to the end-users and beneficiaries."
                      }
                      <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name={"q13c"}
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                  style={{ gridColumn: "1 / 3" }}
                >
                  <CommonInput
                    inputType="textarea"
                    onChange={(e) =>
                      setAllInputValues((prev) => {
                        return { ...prev, q13c: e.target.value };
                      })
                    }
                    disabled={isLoading}
                    autoSizeCheck={{ minRows: 7, maxRows: 7 }}
                    // placeholder="Leave blank if you want Climate Finance Co-pilot to supply suggested narratives to give you a head start on completing this section."
                    className={style.fontsizeInput}
                  />
                </Form.Item>
              </Form>
              {!isValid && (
                <div
                  style={{
                    color: "red",
                    fontSize: "14px",
                    position: "relative",
                    top: "45px",
                  }}
                >
                  You have exceeded the maximum length for this field
                </div>
              )}

              <div
                style={{
                  paddingTop: "5px",
                  position: "relative",
                  top: !isValid ? "40px" : "30px",
                }}
              >
                The max word limit for this page is {wordLimits[4]} words
              </div>
            </div>

            {/* <div className={style.footerButtonsDiv}>
              <Form form={form} onFinish={onFormSubmit}>
                <Button loading={getLoadingConceptNote} disabled={getLoadingConceptNote} htmlType="submit" className={style.nextButton}>
                  Submit
                </Button>
              </Form>
              <div className={style.btnDiv}>
                <div className={style.twoBtnDiv}>
                  <button
                    className={style.goBtn}
                    onClick={() => navigate(constRoute?.ndaAeResults72)}
                  >
                    <img src={LeftArrow} alt="left-arrow" /> Go Back
                  </button>
                  <button
                    onClick={() => {
                      notification.success("Save and Quit");
                      navigate(constRoute?.home);
                    }}
                    className={style.saveBtn}
                  >
                    Save & Quit
                  </button>
                </div>
              </div>
            </div> */}
          </div>
          <CommonFooterButton
            isLoadingSubmit={getLoadingConceptNote}
            handleSubmit={onFormSubmit}
            handlegoback={handleback}
            handleSaveAndQuit={handleSave}
            disableSubmit={!isValid || isLoading}
            // handleQuickNext={constRoute?.gcfJustificationResults90}
            form={form}
          />
        </div>
      </div>
    </div>
  );
});

export default memo(gcfJustificationForm);
