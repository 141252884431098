import { observer } from "mobx-react";
import style from "./style.module.scss";
import React, { memo, useEffect, useState } from "react";
import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import { CommonInput } from "@components/common-components/input";
import { validateMessages } from "@utils/json-data";
import { useNavigate } from "react-router-dom";
import { constRoute } from "@utils/route";
import { useStore } from "@stores/root-store";
import CommonImportantSideBar from "../importantSideBar/common-important-sidebar";
import CommonFooterButton from "../commonfooterbutton";
import useWindowSize from "@utils/hooks/useWindowSize";
import { wordLimits } from "@components/pages/project/select-function/which-to-do/select-one/project-name/important-project-info/utils/wordLimits";
import { useTokensContext } from "@components/layout/main-layout/private-layout";

const ProjectGCFForm = observer(() => {
  const [form] = useForm();
  const [show, setShow] = useState(true);
  const [initialQuestions, setInitialQuestions] = useState(null);

  const navigate = useNavigate();
  const {
    user: {
      getProjectNameData,
      setConceptNoteLoading,
      getLoadingConceptNote,
      conceptNote,
      getSingleProjectData,
      loadingSingleProjectData,
      handleCheckInputTokensValid,
    },
  } = useStore(null);
  const { setTokens } = useTokensContext();
  const screenWidth = useWindowSize().width;
  const [projectName] = useState(JSON.parse(getProjectNameData)?.project_name);
  const [isLoading, setIsLoading] = useState(true);
  const [allInputValues, setAllInputValues] = useState({
    q9: "",
  });
  const [isValid, setIsValid] = useState(true);
  const getProjectName = localStorage.getItem("projectName");
  const dummyArrayOfImportantSIdeBarList = [
    `Vulnerability of the country and populations (for adaptation projects);`,
    `Economic and social development level of the country and the affected population(s);`,
    `Absence of alternative sources of financing; Need for strengthening institutional and implementation capacity. `,
  ];

  const checkLengthOfInput = async () => {
    const data = {
      functionality: "concept note",
      section: "B_3_40",
      questions: { ...allInputValues },
      project_name: projectName || getProjectName,
    };

    await handleCheckInputTokensValid(data).then((res) => {
      const result = JSON.parse(res.isValid);
      setIsValid(result);
    });
  };

  useEffect(() => {
    checkLengthOfInput();
  }, [allInputValues]);

  useEffect(() => {
    if (localStorage.getItem("AllAnswers") === null) {
      localStorage.setItem("AllAnswers", JSON.stringify([{ q9: "" }]));
    }
  }, []);
  const onFormSubmit = async (values) => {
    localStorage.setItem("isSocketOn", "1");
    const getAnswers = JSON.parse(localStorage.getItem("AllAnswers"));
    const addMoreAnswers = getAnswers?.map((item) => {
      return {
        ...item,
        q9: values?.q9,
      };
    });
    const question = {
      q9: values?.q9 || "",
    };
    const getReultsfromls = JSON.parse(localStorage.getItem("totalResults"));
    const addResults =
      getReultsfromls &&
      getReultsfromls?.map((item) => {
        return {
          ...item,
          result6: "",
        };
      });
    localStorage.setItem("totalResults", JSON.stringify(addResults));
    localStorage.setItem("AllAnswers", JSON.stringify(addMoreAnswers));
    // const payload = {
    //   section: `B_3_40`,
    //   questions: question,
    //   project_name: projectName || getProjectName|| ''
    // }
    // localStorage.setItem('conceptPayload', JSON.stringify(payload))
    // const response = await conceptNote(payload, navigate)
    // if(response?.response){
    navigate(
      constRoute?.projectResultsGcfResults48,
      // ,  { state: { response: response?.response} }
    );
    //   const getReultsfromls = JSON.parse(localStorage.getItem('allResults'));
    //   const addResults =  getReultsfromls && getReultsfromls?.map((item) => {
    //     return {
    //       ...item,
    //       result6: response?.response || ""
    //     }
    //   })
    //   localStorage.setItem('allResults', JSON.stringify(addResults))
    // }
  };

  const handleSave = () => {
    setConceptNoteLoading(false);
    // notification.success("Save and Quit");
    navigate(constRoute?.home);
  };
  const handleback = () => {
    localStorage.setItem("isSocketOn", "0");
    setConceptNoteLoading(false);
    navigate(constRoute?.projectResultsGcfResults40);
  };
  const getAnswers = JSON.parse(localStorage.getItem("AllAnswers"));

  useEffect(() => {
    const data = {
      section: "B_3_40",
      project_name: projectName ?? localStorage.getItem("projectName"),
      functionality: "concept note",
    };
    localStorage.setItem("data-for-tokens", JSON.stringify(data));
    getSingleProjectData(data).then((el) => {
      setTokens(el["tokens_remaining/tokens_purchased"]);

      setInitialQuestions(el);
      setIsLoading(false);
      form.setFieldsValue(el.questions);
    });
  }, []);

  return (
    <div className={style.mainContainer}>
      <div className={style.barContentContainer}>
        <CommonImportantSideBar
          title={"Important:"}
          fristPara={` With this section, the GCF wants to capture the extent to which activities under the proposed project and GCF participation in such activities may lower barriers to investment. `}
          secondParagraph={`This is reflected along the following dimensions:  `}
          isList={true}
          listArray={dummyArrayOfImportantSIdeBarList}
        />
        {/* {show && (
        <div className={style.layoutDiv}>
          <div className={style.siderStyle}>
            <div className={style.sideInnerDiv}>
              <div className={style.importantDiv}>
                <p className={style.pTagFour}>Quick Tips</p>
                <button className={style.btnClass} onClick={() => setShow(!show)}>
                  <img
                    src={CloseIcon}
                    className={style.closeIconImg}
                    alt="fd"
                  />
                </button>
              </div>
              <div className={style.pTageGroup}>
                <p className={style.pTagFive}>
                  Discuss vulnerabilities and exposure of the target areas or
                  populations, highlight financing barriers and need for
                  institutional capacity building, and such information should
                  be backed by sound evidence.
                </p>
              </div>
              <Divider />
            </div>
          </div>
        </div>
      )} */}
        <div
          className={style.contentContainer}
          style={{ cursor: isLoading ? "wait" : "" }}
        >
          <div className={style.innerContentContainer}>
            <h1>Please Fill In the Blanks Below:</h1>

            <div className={style.dataContentBox}>
              <Form
                className={style.formData}
                form={form}
                autoComplete="false"
                onFinish={onFormSubmit}
                validateMessages={validateMessages}
                layout="vertical"
                initialValues={{
                  q9:
                    initialQuestions?.questions?.q9 ||
                    (getAnswers && getAnswers[0]?.q9) ||
                    "",
                }}
              >
                <Form.Item
                  label={
                    <span>
                      {
                        " 9. Briefly Describe The Needs of the Recipient of the Project (< 300 words).     "
                      }
                      <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name={"q9"}
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <CommonInput
                    inputType="textarea"
                    onChange={(e) =>
                      setAllInputValues((prev) => {
                        return { ...prev, q9: e.target.value };
                      })
                    }
                    disabled={isLoading}
                    autoSizeCheck={{ minRows: 18, maxRows: 20 }}
                    // placeholder="Leave blank if you want Climate Finance Co-pilot to supply suggested narratives to give you a head start on completing this section."
                    className={style.fontSizeInput}
                  />
                </Form.Item>
                {/* <Form.Item
                  label="5. What is the name of the Accredited Entity(ies) and describe the implementation arrangements with the executing entity(ies) and implementing partners."
                  name={"ies"}
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <CommonInput
                    inputType="textarea"
                    autoSizeCheck={{ minRows: 7, maxRows: 7 }}

                    className={style.emailInput}
                  />
                </Form.Item> */}
              </Form>

              {!isValid && (
                <div
                  style={{
                    color: "red",
                    fontSize: "14px",
                    position: "relative",
                    top: "45px",
                  }}
                >
                  You have exceeded the maximum length for this field
                </div>
              )}

              <div
                style={{
                  paddingTop: "5px",
                  position: "relative",
                  top: !isValid ? "40px" : "30px",
                }}
              >
                The max word limit for this page is {wordLimits[2]} words
              </div>
            </div>

            {/* <div className={style.footerButtonsDiv}>
              <Form form={form} onFinish={onFormSubmit}>
                <Button loading={getLoadingConceptNote} disabled={getLoadingConceptNote}  htmlType="submit" className={style.nextButton}>
                  Submit
                </Button>
              </Form>
              <div className={style.btnDiv}>
                <div className={style.twoBtnDiv}>
                  <button
                    className={style.goBtn}
                    onClick={() =>
                      navigate(constRoute?.projectResultsGcfResults40)
                    }
                  >
                    {" "}
                    <img src={LeftArrow} alt="left-arrow" /> Go Back
                  </button>
                  <button
                    onClick={() => {
                      notification.success("Save and Quit");
                      navigate(constRoute?.home);
                    }}
                    className={style.saveBtn}
                  >
                    Save & Quit
                  </button>
                </div>
              </div>
            </div> */}
          </div>

          <CommonFooterButton
            isLoadingSubmit={getLoadingConceptNote}
            handleSubmit={onFormSubmit}
            handlegoback={handleback}
            handleSaveAndQuit={handleSave}
            // handleQuickNext={constRoute?.projectResultsGcfResults48}
            form={form}
            disableSubmit={!isValid || isLoading}
            // customStyle={{position:(screenWidth > 770) ? 'absolute' : 'inherit'}}
          />
        </div>
      </div>
    </div>
  );
});

export default memo(ProjectGCFForm);
