import { FC, memo, useCallback, useEffect, useRef, useState } from "react";

import {
  useNarrativesContext,
  useTokensContext
} from "@components/layout/main-layout/private-layout";
import RationalForm from "@components/pages/project/rational-advisor-page/rational-form";
import CommonFooterButton from "@components/pages/project/select-function/which-to-do/select-one/project-name/important-project-info/commonfooterbutton";
import { useStore } from "@stores/root-store";
import { constRoute } from "@utils/route";
import { useForm } from "antd/es/form/Form";
import { useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import { DataType } from "types/data-type";

const formQuestions = [
  {
    q: <span>Please Fill In the Blanks Below:</span>,
    result: <span>Find below Co-pilot's Output:</span>,
    limit: 1000,
    placeholder: ""
  },
  {
    q: (
      <>
        3. Explain why the project is likely to be an effective response.{" "}
        <span style={{ color: "#de3b3b" }}>*</span>
      </>
    ),
    limit: 1000,
    result: <>Find below Co-pilot's Output:</>,
    placeholder:
      "Guidance​ Below: \n\n" +
      "Discuss the reason/s the project is likely to be an effective response​. \n\n" +
      "Link Risks to Climate Change:​ Clearly articulate the connection between climate change and the specific risks or impacts that your project aims to address. Use the best available information to support your claims.\n\n" +
      "Detail Effects on Sectors and Demographics:​ Provide a comprehensive analysis of how climate change affects various sectors (e.g., agriculture, water resources, health) and demographics (e.g., vulnerable populations, different age groups) in the target area. \n\n" +
      "Cause-and-Effect Logic: Explain the cause-and-effect relationships that demonstrate how climate change has led, or will lead, to the specific risks or impacts your project addresses. Use logical reasoning and evidence to support your narrative.\n\n" +
      "Anticipated Impacts:​ Discuss the anticipated impacts of climate change on the target area, and how your proposed activity is designed to mitigate these impacts effectively."
  },
  {
    q: (
      <>
        4. Discuss the exposure and/or vulnerability (of people, systems, or
        ecosystems) in the country/region.
        <span style={{ color: "#de3b3b" }}>*</span>
      </>
    ),
    result: <>Find below Co-pilot's Output:</>,
    limit: 1000,
    placeholder:
      "Guidance Below:\n\n" +
      "Barriers Discussion: When discussing barriers to the project's success, provide a comprehensive analysis of the challenges that need to be overcome. This should include:\n\n" +
      "Identification of Barriers: Clearly outline the specific obstacles that may hinder the project's progress.\nStrategies for Overcoming Barriers: Develop detailed strategies or interventions to address each identified barrier. Emphasize the importance of involving local communities, forming partnerships with governmental and non-governmental organizations, and leveraging innovative technologies as part of the strategy.\n\n" +
      "Why Project Will Reduce the Exposure and/or Vulnerability (Adaptation Projects Only): In this section, focus on the direct impact of the project on vulnerable populations and the measures taken to reduce their exposure to climate-related hazards.\n\n" +
      "Direct Impact on Vulnerable Populations**: Describe the specific interventions aimed at reducing the vulnerability of people to climate-related hazards. Consider the potential impacts on specific units of analysis, such as communities, ecosystems, or businesses.\n\n" +
      "Effectiveness and Suitability: Provide a rationale for selecting the proposed activity, supported by research or case studies illustrating its effectiveness in similar scenarios. Discuss the suitability of the activity for the local environmental, socio-economic, and cultural context."
  },
  {
    q: (
      <>
        5. Discuss all the potential barriers.
        <span style={{ color: "#de3b3b" }}>*</span>
      </>
    ),
    result: <>Find below Co-pilot's Output:</>,
    limit: 1000,
    placeholder:
      "Guidance below:\n\n" +
      "Identification of Barriers: Outline specific strategies or interventions planned to overcome each identified barrier. \n\n" +
      "Discuss the involvement of local communities, partnerships with governmental and non-governmental organizations, and the use of innovative technologies as part of the strategy."
  },
  {
    q: (
      <>
        6. Discuss how the proposed intervention aligns with national plans and
        climate strategies.<span style={{ color: "#de3b3b" }}>*</span>
      </>
    ),
    result: <>Find below Co-pilot's Output:</>,
    placeholder:
      "​Guidance Below:\n\n" +
      "Alignment with National Priorities:\n\n" +
      "When discussing the project's alignment with national priorities, make affirmative statements that the project's activities are consistent with the host country's priorities, particularly regarding its Nationally Determined Contribution (NDC) and other climate strategies aimed at limiting temperature rise to 1.5°C. Emphasize the project's consistency with the host country's NDCs, National Adaptation Programmes of Action (NAPAs), National Adaptation Plans (NAPs), or other climate strategies. Reference specific documents by name, if available, and cite specific policies.\n\n" +
      "Stakeholder Engagement​:\n\n" +
      "When detailing engagement plans with national and local stakeholders, include governmental bodies, the Accredited Entity, civil society, academia, and other relevant parties. The narrative should demonstrate how the proposed activity aligns with the host country's climate strategies and plans, such as NAPs, NAPAs, and others. It should also show how the project fosters stakeholder ownership and aligns with national policies, strategies, and frameworks.",
    limit: 1000
  },
  {
    q: <>7. Enter Project Monitoring and Evaluation Structure Below:</>,
    result: <>Find below Co-pilot's Output:</>,
    placeholder: "",
    limit: 1000
  }
];

const sections = ["i_24", "pe_48", "r_64", "pb_76", "a_84", "me_100"];

const RationalAdvisorPage: FC = () => {
  const nav = useNavigate();
  const socketRef = useRef(null);
  const { type, step } = useParams();
  const { setTokens } = useTokensContext();
  const { rationalAdvisor, setRationalAdvisor } = useNarrativesContext();
  const [form] = useForm();
  const {
    user: { getSingleProjectData, projectNameData },
    modelType: { getSelectedModelType }
  } = useStore(null);

  const projectName =
    (projectNameData && JSON.parse(projectNameData).project_name) ||
    localStorage.getItem("projectName");

  const [initialInput, setInitialInput] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [resultsData, setResultsData] = useState("");
  const [firstStepAnswers, setFirstStepAnswers] = useState({
    q1a: "",
    q1b: "",
    q2: "",
    q3: ""
  });
  console.log(resultsData);

  const results = isSubmitting
    ? ""
    : rationalAdvisor.find(el => el.section === sections[+step - 1])?.res;

  const email = localStorage.getItem("email");
  const isFormPage = type === "form";

  const handleSubmit = useCallback(
    values => {
      // if (!isValid) return;
      if (isFormPage) {
        setResultsData("");
        setIsSubmitting(true);

        const { q1a, q1b, q2 } = firstStepAnswers;

        const data: DataType =
          +step === 1
            ? {
                section: sections[+step - 1],
                questions: { q1a, q1b, q2 },
                project_name: projectName,
                email: email,
                model: getSelectedModelType ?? "Mentor"
              }
            : {
                section: sections[+step - 1],
                questions: { [`q${+step + 1}`]: values[`q${+step + 1}`] },
                project_name: projectName,
                email: email,
                model: getSelectedModelType ?? "Mentor"
              };

        console.log("payload body ------------->", data);

        const socket = io("https://janusprod.climatefinancecopilot.app", {});

        socketRef.current = socket;
        socketRef?.current?.emit("climateRationale", data);

        socketRef?.current?.on("answer", answer => {
          const isStopGeneration = localStorage.getItem("isStopGene ration");

          if (!isSubmitting && answer) {
            setIsSubmitting(true);
          }

          if (isStopGeneration || answer === "#####") {
            socket.disconnect();
            setIsSubmitting(false);
            localStorage.removeItem("isStopGeneration");
            return;
          }

          form.setFieldValue(
            `q${+step + 1}`,
            (form?.getFieldValue(`q${+step + 1}`) ?? "") + answer
          );
        });
        socket.on("disconnect", () => {
          localStorage.removeItem("isStopGeneration");
          setIsSubmitting(false);
        });
        nav(constRoute?.rationalAdvisorProject + "/results/" + step);
      } else {
        if (formQuestions.length < +step + 1) {
          nav(constRoute?.gcfCongratulation + "/rational-advisor");
          return;
        }
        setIsSubmitting(false);
        nav(constRoute?.rationalAdvisorProject + "/form/" + (+step + 1));
      }
    },
    [type, step, firstStepAnswers]
  );

  const handleGoBack = () => {
    socketRef?.current?.disconnect();
    setIsSubmitting(false);
    // if (isSubmitting) return;
    if (+step === 1 && type == "results") {
      nav(constRoute?.rationalAdvisorProject + "/form/1");

      return;
    }

    if (+step < 2) {
      nav(constRoute.projectName + "/rational-advisor");
      return;
    }

    if (type === "results") {
      nav(constRoute?.rationalAdvisorProject + "/form/" + +step);
    } else {
      nav(constRoute?.rationalAdvisorProject + "/results/" + (+step - 1));
    }
  };

  const handleSaveQuit = () => {
    nav(constRoute?.existingProject);
  };

  const getProjectData = async () => {
    const data = {
      section: sections[+step - 1].toLowerCase(),
      project_name: projectName,
      functionality: "climate rationale"
    };

    setIsLoading(true);
    localStorage.setItem("data-for-tokens", JSON.stringify(data));

    await getSingleProjectData(data, null)
      .then(state => {
        setInitialInput(state?.questions);
        state && setTokens(state["tokens_remaining/tokens_purchased"]);
        if (+step === 1) {
          if (!isSubmitting) {
            form.setFieldValue(
              [`q${+step + 1}`],
              state?.generated_narratives?.find(Boolean)
            );
          }
        } else {
          if (isFormPage) {
            form.setFieldValue(
              [`q${+step + 1}`],
              state?.questions[`q${+step + 1}`]
            );
          } else {
            if (!isSubmitting) {
              console.log(
                +step + 1,
                state?.narratives?.map(el => el).join("") || ""
              );
              form.setFieldValue(
                [`q${+step + 1}`],
                state?.generated_narratives?.find(Boolean)
              );
            }
          }
        }
        setRationalAdvisor(prev =>
          prev.map(el => {
            if (el.section === sections[+step - 1]) {
              return {
                ...el,
                res: state?.generated_narratives?.map(el => el).join("") || ""
              };
            } else {
              return el;
            }
          })
        );
      })
      .finally(() => setIsLoading(false));

    if (+step < 1 || formQuestions.length + 1 <= +step) {
      nav(constRoute?.gcfCongratulation + "/rational-advisor");
    }
  };

  const handleStopGenerate = () => {
    if (socketRef) {
      socketRef.current.disconnect();
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    localStorage.removeItem("isStopGeneration");
    getProjectData();
  }, [step, type]);

  useEffect(() => {
    form.resetFields();
  }, [type === "results"]);

  useEffect(() => {
    socketRef?.current?.disconnect();
    form.resetFields();
    setIsSubmitting(false);
    setResultsData(
      rationalAdvisor.find(el => el.section === sections[+step - 1])?.res || ""
    );
  }, [step]);

  useEffect(() => {
    const preventClick = event => {
      event.preventDefault();
      event.stopPropagation();
    };

    const elements = document.getElementsByClassName("navigation-element-js");

    for (let i = 0; i < elements.length; i++) {
      if (isLoading) {
        elements[i].addEventListener("click", preventClick);
        if (i !== +step - 1) {
          // @ts-ignore
          elements[i].style.cursor = "not-allowed";
        }
      } else {
        elements[i].removeEventListener("click", preventClick);
        // @ts-ignore
        elements[i].style.cursor = "";
      }
    }
    return () => {
      for (let i = 0; i < elements.length; i++) {
        elements[i].removeEventListener("click", preventClick);
      }
    };
  }, [isLoading]);

  useEffect(() => {
    return () => {
      socketRef?.current?.disconnect();
    };
  }, []);

  return (
    <div style={{ padding: "30px 52px 122px" }}>
      <RationalForm
        form={form}
        value={results}
        isSubmitting={isSubmitting}
        initialState={initialInput}
        handleSubmit={handleSubmit}
        isFormPage={isFormPage}
        Text={
          isFormPage
            ? formQuestions[+step - 1]?.q
            : formQuestions[+step - 1]?.result || results[`result${step}`] || ""
        }
        placeholder={formQuestions[+step - 1]?.placeholder}
        maxLength={formQuestions[+step - 1]?.limit}
        disabled={type === "results" || isLoading}
        section={sections[+step - 1]}
        setIsValid={setIsValid}
        isValid={isValid}
        firstStepAnswers={firstStepAnswers}
        projectName={projectName}
        setFirstStepAnswers={val => setFirstStepAnswers(val)}
        handleStopGenerate={handleStopGenerate}
        isLoading={isLoading}
      />
      <CommonFooterButton
        customStyle={{ left: 0 }}
        isLoadingSubmit={false}
        handleSubmit={handleSubmit}
        handlegoback={handleGoBack}
        isSubmitting={isSubmitting}
        handleSaveAndQuit={handleSaveQuit}
        isSubmit={type === "results"}
        // handleQuickNext={constRoute?.contextAndBaselineResults}
        form={form}
        setIsSubmitting={setIsSubmitting}
        disableSubmit={!isValid || isLoading}
      />
    </div>
  );
};

export default memo(RationalAdvisorPage);
