import { observer } from "mobx-react";
import style from "./style.module.scss";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { constRoute } from "@utils/route";
import LeftArrow from "@assets/icons/left-arrow.png";
import CardItemsFunc from "../common-components/card-item-func";
import { notification } from "@utils/notifications";
import GoBack from "@components/common-components/go-back";
import CardItemsFuncImg from "../common-components/card-item-func-with-img";
import funder1 from "@assets/images/funder1.png"
import funder2 from "@assets/images/funder2.webp"
import funder3 from "@assets/images/funder3.png"
import funder4 from "@assets/images/funder4.png"

const SelectFunder = observer(() => {
  const navigate = useNavigate();

  const btnCardsList = [
    {
      title: "Green Climate Fund",
      status: "",
      navigate: () => navigate(constRoute?.selectFunction),
      img:funder1,
      opacity:'100%'
    },
    {
      title: <>Global Environmental<br/> Facility</>,
      status: "Coming Soon",
      disable: true,
      img:funder2,
      opacity:'50%'
    },
    {
      title: "U.S Agency for International Development",
      status: "Coming Soon",
      disable: true,
      img:funder3,
      opacity:'50%'
    },
    {
      title:
        "U.S International Development Finance Corporation",
      status: "Coming Soon",
      disable: true,
      img:funder4,
      opacity:'50%'
    },
  ];

  return (
    <div>
      <div className={style.homePagePageContainer}>
        <Row>
          <div className={style.homePageContainerCol}>
            <div className={style.homePageContainer}>
              <h1>Select the funder you are targeting</h1>
              <Row justify="center" className={style.boxesContiner} gutter={20}>
                {btnCardsList?.map((item) => {
                  return <CardItemsFuncImg cardGrid={true} item={item} />; 
                })}
              </Row>
            </div>
          </div>
        </Row>
      </div>
      <div className={style.btnDiv}>
        <div className={style.twoBtnDiv}>
          <div className={style.goBackBtnDiv}>
            <GoBack onClick={constRoute?.home} className={style.goBtn} />
          </div>
        </div>
      </div>
    </div>
  );
});

export default SelectFunder;
