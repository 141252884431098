import { observer } from "mobx-react";
import style from "./style.module.scss";
import React, { memo, useEffect, useState } from "react";
import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import { CommonInput } from "@components/common-components/input";
import { validateMessages } from "@utils/json-data";
import { useNavigate } from "react-router-dom";
import { constRoute } from "@utils/route";
import { useStore } from "@stores/root-store";
import CommonImportantSideBar from "../importantSideBar/common-important-sidebar";
import CommonFooterButton from "../commonfooterbutton";
import useWindowSize from "@utils/hooks/useWindowSize";
import { wordLimits } from "@components/pages/project/select-function/which-to-do/select-one/project-name/important-project-info/utils/wordLimits";
import { useTokensContext } from "@components/layout/main-layout/private-layout";

const ProjectGCFForm = observer(() => {
  const [form] = useForm();
  const [show, setShow] = useState(true);
  const [initialQuestions, setInitialQuestions] = useState(null);
  const navigate = useNavigate();
  const {
    user: {
      getProjectNameData,
      setConceptNoteLoading,
      getLoadingConceptNote,
      conceptNote,
      getSingleProjectData,
      loadingSingleProjectData,
      handleCheckInputTokensValid,
    },
  } = useStore(null);
  const { setTokens } = useTokensContext();
  const [projectName] = useState(JSON.parse(getProjectNameData)?.project_name);
  const [allInputValues, setAllInputValues] = useState({
    q7: "",
  });
  const [isValid, setIsValid] = useState(true);
  const getProjectName = localStorage.getItem("projectName");
  const screenWidth = useWindowSize().width;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("AllAnswers") === null) {
      localStorage.setItem("AllAnswers", JSON.stringify([{ q7: "" }]));
    }
  }, []);

  const checkLengthOfInput = async () => {
    const data = {
      functionality: "concept note",
      section: "B_3_24",
      questions: { ...allInputValues },
      project_name: projectName || getProjectName,
    };

    await handleCheckInputTokensValid(data).then((res) => {
      const result = JSON.parse(res.isValid);
      setIsValid(result);
    });
  };

  useEffect(() => {
    checkLengthOfInput();
  }, [allInputValues]);

  const onFormSubmit = async (values) => {
    localStorage.setItem("isSocketOn", "1");
    const getAnswers = JSON.parse(localStorage.getItem("AllAnswers"));
    const addMoreAnswers = getAnswers?.map((item) => {
      return {
        ...item,
        q7: values?.q7,
      };
    });
    // const question ={
    //   q7: values?.q7||'',

    // }
    localStorage.setItem("AllAnswers", JSON.stringify(addMoreAnswers));
    const getReultsfromls = JSON.parse(localStorage.getItem("totalResults"));
    const addResults =
      getReultsfromls &&
      getReultsfromls?.map((item) => {
        return {
          ...item,
          result4: "",
        };
      });
    localStorage.setItem("totalResults", JSON.stringify(addResults));
    // const payload = {
    //   section: `B_3_24`,
    //   questions: question,
    //   project_name: projectName || getProjectName|| ''
    // }
    // localStorage.setItem('conceptPayload', JSON.stringify(payload))
    // const response = await conceptNote(payload, navigate)
    // if(response?.response){
    navigate(
      constRoute?.projectResultsGcfResults32,
      // ,  { state: { response: response?.response} }
    );

    //   const getReultsfromls = JSON.parse(localStorage.getItem('allResults'));
    //   const addResults =  getReultsfromls && getReultsfromls?.map((item) => {
    //     return {
    //       ...item,
    //       result4: response?.response || ""
    //     }
    //   })
    //   localStorage.setItem('allResults', JSON.stringify(addResults))

    // }
  };
  const handleSave = () => {
    setConceptNoteLoading(false);
    // notification.success("Save and Quit");
    navigate(constRoute?.home);
  };
  const handleback = () => {
    localStorage.setItem("isSocketOn", "0");
    setConceptNoteLoading(false);
    navigate(constRoute?.projectResultsGcfResults);
  };
  const getAnswers = JSON.parse(localStorage.getItem("AllAnswers"));

  useEffect(() => {
    const data = {
      section: "B_3_24",
      project_name: projectName ?? localStorage.getItem("projectName"),
      functionality: "concept note",
    };
    localStorage.setItem("data-for-tokens", JSON.stringify(data));
    getSingleProjectData(data).then((el) => {
      setTokens(el["tokens_remaining/tokens_purchased"]);
      setInitialQuestions(el);
      setIsLoading(false);
      form.setFieldsValue(el.questions);
    });
  }, []);

  return (
    <div className={style.mainContainer}>
      <div className={style.barContentContainer}>
        <CommonImportantSideBar
          title={"Important:"}
          fristPara={`1. Describe the potential for the project to catalyse action beyond the GCF funded project.`}
          secondParagraph={`2. Provide an estimate of the target market at scale (in size of investment or size of activity) and a timeframe (e.g. short, medium or long-term). `}
          thirdParagraph={`3. Provide a rationale for the significance of such scale in the context of reference (sector/investor). `}
          forthParagraph={`4. Provide an  estimates of both scale and its significance are backed by evidence (existing studies, data, literature).`}
        />
        {/* {show && (
        <div className={style.layoutDiv}>
          <div className={style.siderStyle}>
            <div className={style.sideInnerDiv}>
              <div className={style.importantDiv}>
                <p className={style.pTagFour}>Quick Tips</p>
                <button className={style.btnClass} onClick={() => setShow(!show)}>
                  <img
                    src={CloseIcon}
                    className={style.closeIconImg}
                    alt="fd"
                  />
                </button>
              </div>
              <div className={style.pTageGroup}>
                <p className={style.pTagFive}>
                  Describe the potential for project to catalyse action beyond
                  the GCF-funded project. 
                </p>
                <p className={style.pTagSix}>
                  Climate Finance Co-pilot (CFC) will generate suggestions on 
                  how the project or its activities can be scaled up or
                  replicated, as well as generate plans for knowledge sharing
                  and how the project contributes to national policies and
                  strategies or regulatory frameworks.
                </p>
               
              </div>
              <Divider />
            </div>
          </div>
        </div>
)} */}
        <div
          className={style.contentContainer}
          style={{ cursor: isLoading ? "wait" : "" }}
        >
          <div className={style.innerContentContainer}>
            <h1>Please Fill In the Blanks Below:</h1>

            <div className={style.dataContentBox}>
              <Form
                className={style.formData}
                form={form}
                autoComplete="false"
                onFinish={onFormSubmit}
                validateMessages={validateMessages}
                layout="vertical"
                initialValues={{
                  q7:
                    initialQuestions?.questions?.q7 ||
                    (getAnswers && getAnswers[0]?.q7) ||
                    "",
                }}
              >
                <Form.Item
                  label={
                    <span>
                      {
                        "   7. Briefly Describe The Paradigm Shift Potential of the Project.    "
                      }
                      <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name={"q7"}
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <CommonInput
                    inputType="textarea"
                    onChange={(e) =>
                      setAllInputValues((prev) => {
                        return { ...prev, q7: e.target.value };
                      })
                    }
                    disabled={isLoading}
                    autoSizeCheck={{ minRows: 18, maxRows: 20 }}
                    // placeholder="Leave blank if you want Climate Finance Co-pilot to supply suggested narratives to give you a head start on completing this section."
                    className={style.fontSizeInput}
                  />
                </Form.Item>
                {/* <Form.Item
                  label="5. What is the name of the Accredited Entity(ies) and describe the implementation arrangements with the executing entity(ies) and implementing partners."
                  name={"ies"}
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <CommonInput
                    inputType="textarea"
                    autoSizeCheck={{ minRows: 7, maxRows: 7 }}

                    className={style.emailInput}
                  />
                </Form.Item> */}
              </Form>
              {!isValid && (
                <div
                  style={{
                    color: "red",
                    fontSize: "14px",
                    position: "relative",
                    top: "45px",
                  }}
                >
                  You have exceeded the maximum length for this field
                </div>
              )}

              <div
                style={{
                  paddingTop: "5px",
                  position: "relative",
                  top: !isValid ? "40px" : "30px",
                }}
              >
                The max word limit for this page is {wordLimits[2]} words
              </div>
            </div>
            {/* <div className={style.footerButtonsDiv}>
              <Form form={form} onFinish={onFormSubmit}>
                <Button loading={getLoadingConceptNote} disabled={getLoadingConceptNote}  htmlType="submit" className={style.nextButton}>
                  Submit
                </Button>
              </Form>
              <div className={style.btnDiv}>
                <div className={style.twoBtnDiv}>
                  <button
                    className={style.goBtn}
                    onClick={() =>
                      navigate(constRoute?.projectResultsGcfResults)
                    } 
                  >
                    <img src={LeftArrow} alt="left-arrow" /> Go Back
                  </button>
                  <button
                    onClick={() => {
                      notification.success("Save and Quit");
                      navigate(constRoute?.home);
                    }}
                    className={style.saveBtn}
                  >
                    Save & Quit
                  </button>
                </div>
              </div>
            </div> */}
          </div>
          <CommonFooterButton
            isLoadingSubmit={getLoadingConceptNote}
            handleSubmit={onFormSubmit}
            handlegoback={handleback}
            handleSaveAndQuit={handleSave}
            form={form}
            disableSubmit={!isValid || isLoading}
            // handleQuickNext={constRoute?.projectResultsGcfResults32}
            // customStyle={{position:(screenWidth > 770) ? 'absolute' : 'inherit'}}
          />
        </div>
      </div>
    </div>
  );
});

export default memo(ProjectGCFForm);
