import React from "react";
import DashboardCard from "@components/layout/admin-layout/components/dashboardCard/DashboardCard";
import styles from "./users.module.scss";
import UserTable from "@components/layout/admin-layout/components/userTable/UserTable";
import InviteModal from "@components/layout/admin-layout/components/inviteModal/InviteModal";
import { useInviteModal } from "@components/ModalContext/InviteModalContext";

const Users = () => {
  const { isModalOpen } = useInviteModal();

  return (
    <div className={styles.dashboard}>
      {isModalOpen && <InviteModal />}
      <div className={styles.dashboardCards}>
        <DashboardCard type="Users" count={2} size="small" />
        <DashboardCard type="Limited access members" count={1} size="small" />
        <DashboardCard type="Pending invites" count={2} size="small" />
      </div>
      <UserTable />
    </div>
  );
};

export default Users;
