import React from "react";

const FolderIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 20V6C2 4.89543 2.89543 4 4 4H7.17157C7.70201 4 8.21071 4.21071 8.58579 4.58579L9.41421 5.41421C9.78929 5.78929 10.298 6 10.8284 6H18C19.1046 6 20 6.89543 20 8V10M2.09655 19.6782L4.57241 11.4253C4.8262 10.5793 5.60484 10 6.48806 10H19.4384C20.7396 10 21.6943 11.2228 21.3787 12.4851L19.8787 18.4851C19.6561 19.3754 18.8562 20 17.9384 20H2.33601C2.16854 20 2.04843 19.8386 2.09655 19.6782Z"
        stroke="#2E2E2E"
        strokeWidth="1.67"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default FolderIcon;
