import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useState,
} from "react";

interface InviteModalContextType {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

const InviteModalContext = createContext<InviteModalContextType | undefined>(
  undefined,
);

export const InviteModalProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <InviteModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>
      {children}
    </InviteModalContext.Provider>
  );
};

export const useInviteModal = (): InviteModalContextType => {
  const context = useContext(InviteModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
