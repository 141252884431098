import React, { FC, useState } from "react";
import { constImages } from "@utils/images";
import styles from "./style.module.scss";

type Props = {
  showAll: boolean;
  style: any;
};

const EmailReport: FC<Props> = ({ showAll, style }) => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div
      className={style.emailBannerWrap}
      onMouseEnter={() => setShowPopup(true)}
      onMouseLeave={() => setShowPopup(false)}
    >
      <div
        className={style.emailBannerIconWrap}
        style={{ cursor: !showAll ? "pointer" : "" }}
      >
        <img src={constImages.mailIcon} alt="mail" />
      </div>
      {showAll ? (
        <div>
          <p className={style.emailHeadingText}>Questions or Issues?</p>
          <div className={style.emailText}>
            Email us:
            <a
              style={{ textDecoration: "underline", color: "#000" }}
              href="mailto:admin@janusadvisoryinc.com"
            >
              admin@janusadvisoryinc.com
            </a>
          </div>
        </div>
      ) : (
        <div className={styles.popup}>
          <div style={{ display: !showPopup ? "none" : "block" }}>
            <p className={style.emailHeadingText}>Questions or Issues?</p>
            <div className={style.emailText}>
              Email us:
              <a
                style={{ textDecoration: "underline", color: "#000" }}
                href="mailto:admin@janusadvisoryinc.com"
              >
                admin@janusadvisoryinc.com
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailReport;
