import ContactSalesModal from "@components/common-components/contact-sales-modal/ContactSalesModal";
import { useState } from "react";
import { PlanCard } from "./components/planCard/PlanCard";
import styles from "./style.module.scss";

export const Pricing = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => {
    setModalVisible(true);
  };

  const plans = [
    {
      title: "Basic (Free)",
      subTitle:
        "With climate domain expertise, the AI model used in this plan can quickly and precisely evaluate narratives against funders’ criteria and provide expert guidance.",
      price: "$0",
      button: <button className={styles.greenButton}>Get Started</button>,
      listName: "What you get:",
      listItems: [
        "Access to our tools: Evaluate a Concept Note, Draft a Concept Note, Project Structuring and Theory of Change",
        "Access to our GPT-4 Model",
        "10,000 tokens (approx. 1 Concept Note)",
        "Tech Support: Level 3",
      ],
      mostPopular: false,
      listStyle: styles.firstList,
    },
    {
      title: "Premium",
      subTitle:
        "Capable of processing 4x as many words as the Basic model. This means it can review much longer narratives and is more precise in the guidance provided.",
      price: "Coming Soon",
      button: (
        <button className={styles.contactButton} onClick={openModal}>
          Contact Sales
        </button>
      ),
      listName: "All Basic features, plus:",
      listItems: [
        "Access to our tools: Playground",
        "Access to our GPT-4 Turbo Model",
        "50,000 tokens (approx. 5 CNs)",
        "Tech Support: Level 2",
      ],
      mostPopular: true,
      listStyle: styles.secondList,
    },
    {
      title: "Enterprise",
      subTitle:
        "For GCF Accredited Entities requiring the full range of tools needed to produce bankable funding packages. All powered by the most powerful commercial AI model in the world. ",
      price: "",
      button: (
        <button className={styles.contactButton} onClick={openModal}>
          Contact Sales
        </button>
      ),
      listName: "All Premium features, plus:",
      listItems: [
        "Access to our tools: Full Proposal Evaluator and Climate Rationale Advisor",
        "Access to our GPT-4 Turbo Model",
        "500,000 tokens (approx. 10 CNs and 5 FPs)",
        "Tech Support: Level 1",
      ],
      listStyle: styles.thirdList,
    },
  ];

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.mainLayout}>
          <div className={styles.header}>
            <p className={styles.headerTitle}>
              Choose the perfect plan just for you
            </p>
            <p>
              The world’s first climate finance software made for boosting your
              capacity to win much more climate funding!
            </p>
          </div>
          <div className={styles.cardsWrapper}>
            {plans.map((plan, index) => (
              <PlanCard
                key={index}
                title={plan.title}
                subTitle={plan.subTitle}
                price={plan.price}
                button={plan.button}
                listName={plan.listName}
                listItems={plan.listItems}
                listStyle={plan.listStyle}
              />
            ))}
          </div>
          <div className={styles.bottom}>
            <p className={styles.bottomText}>
              Generative AI models process tokens (one token = 3/4 of an average
              word) and each plan comes with a fixed quantity of tokens.
              Evaluating a 20-page Concept consumes approximately 10k tokens.
              Within the web application, there is a prominent token counter
              which provides a constant reading of your remaining tokens.
            </p>
            <p className={styles.bottomText}>
              The token quantity allocated for the Basic plan is fixed and
              cannot be refilled or supplemented as in higher tier plans.
            </p>
            <p className={styles.bottomText}>
              The Level 1 support is the highest level of support offered with
              fastest response time available, i.e. minutes or less. Level 2
              support provides intermediate level support with a response time
              of 2 hours or less. Level 3 support guarantees a response time of
              less than 6 hours.
            </p>
          </div>
        </div>
      </div>
      <ContactSalesModal isOpen={modalVisible} closeModal={setModalVisible} />
    </>
  );
};
