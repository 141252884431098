import React from "react";

const ArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M3.3335 8H12.0002M8.66683 4L12.1954 7.5286C12.4558 7.78894 12.4558 8.21106 12.1954 8.4714L8.66683 12"
        stroke="#5F6D7E"
        strokeWidth="1.67"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ArrowIcon;
