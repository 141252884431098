import React from "react";

const ThreeDotsIcon = () => {
  return (
    <svg
      id="propover"
      width="18"
      height="4"
      viewBox="0 0 18 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="propover"
        d="M8.62476 1.62494H9.37476M8.62476 2.37494H9.37476M1.62476 1.62494H2.37476M1.62476 2.37494H2.37476M15.6248 1.62494H16.3748M15.6248 2.37494H16.3748M10 2C10 2.55228 9.55228 3 9 3C8.44772 3 8 2.55228 8 2C8 1.44772 8.44772 1 9 1C9.55228 1 10 1.44772 10 2ZM3 2C3 2.55228 2.55229 3 2 3C1.44772 3 1 2.55228 1 2C1 1.44772 1.44772 1 2 1C2.55229 1 3 1.44772 3 2ZM17 2C17 2.55228 16.5523 3 16 3C15.4477 3 15 2.55228 15 2C15 1.44772 15.4477 1 16 1C16.5523 1 17 1.44772 17 2Z"
        stroke="#2E2E2E"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ThreeDotsIcon;
