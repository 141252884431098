export const constRoute = {
  hash: "#",
  draftProject: "/project/draft",
  gradeProject: "/project/evaluate",
  advisorProject: "/project/advisor",
  theoryOfChangeProject: "/project/theory-of-change",
  rationalAdvisorProject: "/project/rational-advisor",
  dashboard: "/",
  home: "/home",
  login: "/login",
  faqs: "/faqs",
  playground: "/playground",
  TermOfUse: "/term-of-use",
  projectResult: "/project-result",
  softwareAttribution: "software-attribution",
  admin: "/admin",
  adminDashboard: "/admin/dashboard",
  adminUsers: "/admin/users",
  adminBilling: "/admin/billing",
  masteradminDashboard: "/masteradmin/dashboard",
  masteradminUsers: "/masteradmin/users",
  masteradminRecource: "/masteradmin/resource-center",
  masteradminBilling: "/masteradmin/billing",
  masteradminLogin: "/masteradmin",
  theoryOfChangeWelcome: "/theory-of-change-welcome",
  rationalAdvisorWelcome: "/rational-advisor-welcome",
  projectStructureWelcome: "/project-structure-welcome",
  importantProjectInfo: "/important-project-info",
  contextAndBaselineForm: "/project/context-and-baseline-form",
  contextAndBaselineResults: "/project/context-and-baseline-results",
  projectDescriptionForm: "/project/project-description-form",
  projectDescriptionResults: "/project/project-description-results",
  projectResultsGcfForm: "/project/project-results-gcf-form",
  projectResultsGcfResults: "/project/project-result-gcf-results",
  projectResultsGcfForm24: "/project/project-results-gcf-form-24",
  projectResultsGcfResults32: "/project/project-result-gcf-results-32",
  projectResultsGcfForm32: "/project/project-results-gcf-form-32",
  projectResultsGcfResults40: "/project/project-result-gcf-results-40",
  projectResultsGcfForm40: "/project/project-results-gcf-form-40",
  projectResultsGcfResults48: "/project/project-result-gcf-results-48",
  projectResultsGcfForm48: "/project/project-results-gcf-form-48",
  projectResultsGcfResults56: "/project/project-result-gcf-results-56",
  projectResultsGcfForm56: "/project/project-results-gcf-form-56",
  projectResultsGcfResults64: "/project/project-result-gcf-results-64",
  ndaAe64Form: "/project/nda-ae-form-64",
  ndaAeResults72: "/project/nda-ae-results-72",
  gcfJustificationForm72: "/project/gcf-justification-form-72",
  gcfJustificationResults90: "/project/gcf-justification-results-90",
  sustainabilityReplicabilityForm90:
    "/project/sustainability-replicability-form-90",
  sustainabilityReplicabilityResults100:
    "/project/sustainability-replicability-results-100",
  gcfCongratulation: "/gcf-congratulation",
  signup: "/signup",
  forgetPassword: "/forget-password",
  checkEmail: "/check-email",
  setNewPassword: "/set-new-password",
  resetPasswordSuccessfully: "/reset-password-successfully",
  verifyEmail: "/verify-email",
  selectFunder: "/select-funder",
  selectFunction: "/select-function",
  selectOne: "/select-one",
  whichToDo: "/which-to-do",
  projectName: "/project-name",
  finalSheet: "/final-sheet",
  profile: "/profile",
  setting: "/setting",
  loadBalance: "/load-balance-form",
  users: "/users",
  userBetForm: "/user-bet-form",
  recharges: "/recharges",
  soccer: "/soccer",
  tennis: "/tennis",
  cricket: "/cricket",
  betLocker: "/bet-locker",
  horseRace: "/horse-race",
  greyhound: "/greyhound",
  royalStarCasino: "/royal-star-casino",
  starCasino: "/star-casino",
  royalCasino: "/royal-casino",
  betFairGames: "/bet-fair-games",
  currentPosition: "/current-positions",
  allSports: "/all-sports",
  results: "/results",
  marketRules: "/market-rules",
  termsAndConditions: "/terms-and-conditions",
  existingProject: "/myProjects",
  pricing: "/pricing"
};
