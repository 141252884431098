import ProjectCreateNav from "@components/layout/main-layout/private-layout/project-create-nav";
import CommonHeaderPercentCycle from "@components/pages/project/select-function/which-to-do/select-one/project-name/important-project-info/common-header-percent-cycle";
import {
  ADVISOR_CONTENT,
  EVALUATE_CONTENT,
  EVALUATE_PERCENTAGE,
  EVALUATE_SUBTABS,
  PROJECT_CONTENT,
  RATIONAL_ADVISOR_CONTENT,
  RATIONAL_ADVISOR_PERCENTAGE,
  RATIONAL_ADVISOR_SUBTABS,
  THEORY_OF_CHANGE_CONTENT,
  THEORY_OF_CHANGE_PERCENTAGE
} from "@utils/json-data";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const evaluateSteps = (step, isResult) => {
  const progress = ["0%", "17%", "34%", "51%", "68%", "85%", "100%"];
  if (isResult) {
    return progress[step];
  } else {
    return progress[step - 1];
  }
};

const ProjectHeader = observer(() => {
  const loc = useLocation();
  const isEvaluateProject = loc?.pathname?.includes("evaluate");
  const isResultPage = loc?.pathname?.includes("/results/");
  const isAdvisorPage = loc?.pathname?.includes("/advisor");
  const isTheoryOfChangePage = loc?.pathname.includes("/theory-of-change/");
  const isRationalAdvisor = loc?.pathname.includes("/rational-advisor/");
  const isDraft = loc?.pathname.includes("/draft");
  const isEvaluate = loc?.pathname.includes("/evaluate");

  const currentStep = loc.pathname.split("/").at(-1);
  const labels = useMemo(
    () =>
      Array.from(
        new Set(
          Object.keys(PROJECT_CONTENT).map(
            el => PROJECT_CONTENT[el].conceptNoteSection
          )
        )
      ),
    []
  );

  return (
    <>
      <CommonHeaderPercentCycle
        percent={
          isTheoryOfChangePage
            ? THEORY_OF_CHANGE_PERCENTAGE[+currentStep - 1]
            : isEvaluateProject || isDraft
              ? EVALUATE_PERCENTAGE[+currentStep - 1]
              : isAdvisorPage || loc.pathname.includes("/theory-of-change/")
                ? `${(+currentStep - 1) * 20}%`
                : isRationalAdvisor
                  ? RATIONAL_ADVISOR_PERCENTAGE[+currentStep - 1]
                  : PROJECT_CONTENT[currentStep]?.percent ?? "N/A"
        }
        conceptNoteSection={""}
      />
      {!loc?.pathname.includes("/playground/") && (
        <ProjectCreateNav
          labels={
            isAdvisorPage
              ? ADVISOR_CONTENT
              : isEvaluate || isDraft
                ? EVALUATE_CONTENT
                : isTheoryOfChangePage
                  ? THEORY_OF_CHANGE_CONTENT
                  : isRationalAdvisor
                    ? RATIONAL_ADVISOR_CONTENT
                    : labels
          }
          selected={
            isAdvisorPage
              ? ADVISOR_CONTENT[+currentStep - 1]
              : loc?.pathname.includes("/theory-of-change/")
                ? THEORY_OF_CHANGE_CONTENT[+currentStep - 1]
                : PROJECT_CONTENT[currentStep]?.conceptNoteSection ?? ""
          }
          subtabs={
            isEvaluate || isDraft
              ? EVALUATE_SUBTABS
              : isRationalAdvisor
                ? RATIONAL_ADVISOR_SUBTABS
                : null
          }
          subtabsCondition={isEvaluate || isRationalAdvisor || isDraft}
        />
      )}
    </>
  );
});

export default ProjectHeader;
